import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataLoader from "../../../Utils/DataLoader";
import { branch_all_data, branch_destroy } from "../../../apis/Branches";
import CustomTable from "../../pages/CustomTable";
import { editRestrict, getUserPermission } from "../../../Utils/Utils";
const CseBrances = () => {
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [checked, setChecked] = useState(true);
  const permissions = getUserPermission("Branches");
  const UserID = JSON.parse(localStorage.getItem("userData"))?.data?.id;
  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: true,
    },
    {
      name: "Branch Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Branch State",
      selector: "branch_state",
      sortable: true,
    },
    {
      name: "Branch Code",
      selector: "branch_code",
      sortable: true,
    },
    {
      name: "Branch Contact No",
      selector: "b_contact_no",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    permissions?.edit === 1 || permissions?.delete === 1
      ? {
          name: "Action",
          selector: "action",
        }
      : "",
  ];
  useEffect(() => {
    async function get_data() {
      const result = await branch_all_data(checked);
      const arrayData = result?.data?.map((elem, index) => {
        const {
          id,
          branch_name,
          branch_state,
          branch_code,
          Branch_contact_number,
          delete_status,
          status,
          user_id,
        } = elem;
        return {
          no: `#${index + 1}`,
          name: branch_name.toLowerCase(),
          branch_state: branch_state,
          branch_code: branch_code,
          b_contact_no: Branch_contact_number,
          status : status,
          status: (
            <select
              className="form-select"
              onChange={(e) => destroy("status", user_id, e.target.value)}
              value={status}
            >
              <option value={1}>Active</option>
              <option value={0}>Inactive</option>
            </select>
          ),
          action: (
            <div data-tag="allowRowEvents">
              {permissions?.edit === 1 ? (
                <Link
                  class="edit-btn"
                  to={`${`/admin/administrator-setting/edit/${id}` }`}
                >
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </Link>
              ) : (
                ""
              )}
            </div>
          ),
        };
      });
      setData(arrayData, setShowResults(true));
    }
    const destroy = async (type, id, status, name) => {
      const status_payload = { status: status };
      const delete_status_payload = {
        delete_status: status,
        title: name,
        deleted_by: UserID,
      };
      const result = window.confirm(
        `${
          status == 1
            ? `Are you sure you want to ${
                type == "status" ? "active" : "restore"
              } this branch ?`
            : `Are you sure you want to ${
                type == "status" ? "inactive" : "delete"
              } this branch ?`
        }`
      );
      if (result) {
        await branch_destroy(
          id,
          type == "status" ? status_payload : delete_status_payload
        );
      }
      get_data();
    };
    get_data();
  }, [checked]);

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };
  return (
    <div className="right-nav" id="dash-event">
      {showResults ? (
        <>
          <div className="row">
            <div className="col-md-4">
              <h4 className="modal-title">Branch List</h4>
            </div>
            <div className="col-md-8">
              {permissions?.add === 1 ? (
                <Link
                  className="ui button float-end"
                  to="/admin/administrator-setting/add"
                >
                  Add Branch
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
          <CustomTable
            columns={columns}
            data={data}
            handleChange={handleChange}
            checked={checked}
            type="branch"
          />
        </>
      ) : (
        <DataLoader />
      )}
    </div>
  );
};
export default CseBrances;
