import { toast } from "react-toastify";
import { HTTPURL } from "../../constants/Matcher";

export async function test(data) 
{
    try {
        const response = await fetch(HTTPURL + 'api/event-add', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// student current year reports data
export async function Current_year_reports_data(branch, course, state) {
    try {
        const response = await fetch(HTTPURL + `api/report-branch-student?branch=${branch}&course=${course}&state=${state}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// student year to year comparison data
export async function Student_year_to_year_comparison_data(branch, course, state) {
    try {
        const response = await fetch(HTTPURL + `api/report-branch-student-comparision?branch=${branch}&course=${course}&state=${state}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}




// student current year reports data
export async function Current_year_class_students_reports(branch, course, state) {
    try {
        const response = await fetch(HTTPURL + `api/report-branch-student-class-management?branch=${branch}&course=${course}&state=${state}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}



// student current year reports data
export async function student_report_detail(year,branch, course, state ,term) {
    try {
        const response = await fetch(HTTPURL + `api/branch-report-student-name?year=${year || ''}&branch=${branch || ''}&course=${course || ''}&state=${state || ''}&term=${term || ''}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// view student detail
export async function view_student_report_detail(user_id) {
    try {
        const response = await fetch(HTTPURL + `api/report-studet-detail?user_id=${user_id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}