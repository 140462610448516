import React, { useEffect, useState } from "react";
import { set, useForm } from "react-hook-form";
import { Button } from "semantic-ui-react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { handleNavigation } from "../../../Utils/Utils.js";
import { grade_all } from "../../../apis/Grade";
import { toast } from "react-toastify";
import {
  movie_type_by_subjectId,
  skill_by_subjectId,
  test_type_list,
  get_term_available_units,
} from "../../../apis/Common.jsx";
import { get_all_year_data } from "../../../apis/generalSetting/WebsiteEnv";
import { get_category } from "../../../apis/category";
import { get_terms_by_year } from "../../../apis/Common.jsx";
import AddHomeWork from "./HomeWork/Add.jsx";
import AddSelfStudy from "./SelfStudy/Add.jsx";
import { AddeTutoring } from "./E_Tutoring/Add.jsx";
import AddConsultings from "./Consulting/Add.jsx";
import AddEvaluation from "./Evaluation/Add.jsx";
import AddLectures from "./Subject/Add.jsx";
import { subject_by_coursetype } from "../../../apis/ClassManagement/Subjects.jsx";
import {
  course_add,
  course_consulting,
  course_etutoring,
  course_evaluation,
  course_evaluation1,
  course_homework,
  course_lecture,
  course_self_study,
  course_update,
} from "../../../apis/Course";
const AddCourse = ({ }) => {
  const location = useLocation();
  const updateTermData = location.state;
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors, isDirty },
    reset,
  } = useForm({ mode: "onChange" });
  const add_term_courseType_id = localStorage.getItem("selected_courseType_id");
  const [lecthureData, setLectureData] = useState([]);
  const [gradeID, setGradeID] = useState("");
  const [yearData, setYearData] = useState([]);
  const [termsList, setTermList] = useState([]);
  const [skillData, setSkillData] = useState([]);
  const [gradelist, setGradeList] = useState([]);
  const [courseType, setCourseType] = useState([]);
  const [selfStudy, setSelfStudy] = useState([]);
  const [homeWork, setHomeWork] = useState([]);
  const [eTutroing, setEtutoring] = useState([]);
  const [consulting, setConsulting] = useState([]);
  const [evaluation, setEvaluation] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [learnigStep, setLearningStep] = useState(1);
  const [movieTypeData, setMovieTypeData] = useState([]);
  const [testTypeData, setTestTypeData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [coursesubjects, setCoursesubjects] = useState([]);
  const [coursesubjectsId, setCoursesubjectsId] = useState([]);
  const [EditCourse, setEditCourse] = useState(false);
  const [updataCourseId, setUpdateCourseID] = useState("");
  const [forms, setForms] = useState([]);
  const [totalUnits, setTotalUnits] = useState("");
  const [CallEvaluationList, setCallEvaluationList] = useState(false);
  //state for check evaluation correct date
  const [CheckEvaluationValidation, setCheckEvaluationValidation] =
    useState(true);
  const [selectedSubjects, setSelectedSubject] = useState(
    coursesubjects[0] != undefined
      ? [coursesubjects[0]]
      : parseInt(add_term_courseType_id)
  );
  const term_id = localStorage.getItem("term_id");
  const year = localStorage.getItem("year");
  const grade_id = localStorage.getItem("grade_id");
  const id = localStorage.getItem("courseSubjectID");
  const course_id = localStorage.getItem("course_id");
  const today = new Date().toISOString().split("T")[0];
  const year_id = watch("year");
  const term = watch("term_id");
  console.log("updataCourseId", updataCourseId);

  useEffect(() => {
    const fetchData = async () => {
      const units = await get_term_available_units(term);
      const maxWeek = units?.data[0]?.max_week || 0;
      setTotalUnits(maxWeek);
      setValue("totalUnits", maxWeek, { shouldValidate: true });
    };
    if (term) {
      fetchData();
    }
  }, [term, setValue]);
  const handleInputChange = (inputValue) => {
    setTotalUnits(totalUnits);
    const value = Number(inputValue);
    if (isNaN(value)) {
      toast.error("Invalid input. Please enter a valid number.");
      return;
    }
    if (value > totalUnits) {
      setValue("totalUnits", totalUnits);
      toast.error(`The units cannot be greater than ${totalUnits}`);
    } else if (value === 0) {
      // setTotalUnits("")
      setValue("totalUnits", "");
      toast.error(`The units should in between 0 to ${totalUnits} `);
      return;
    }
  };
  const quantity = watch("totalUnits", 1);
  const navigate = useNavigate();
  useEffect(() => {
    if (updateTermData?.currentStep && updateTermData?.learningStep) {
      setCurrentStep(updateTermData.currentStep);
      setLearningStep(updateTermData.learningStep);
    }
    
  }, [updateTermData]);
  const onSubmit = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    const form = new FormData();
    form.append("grade_id", getValues("grade_id"));
    form.append("price", getValues("price"));
    const totalUnits = getValues("totalUnits");
    form.append("totalUnits", totalUnits);
    localStorage.setItem("totalUnits", totalUnits);
    form.append("term_id", getValues("term_id"));
    form.append("course_type_id", getValues("course_type_id"));
    form.append("name", getValues("name"));
    form.append("year", getValues("year"));
    form.append("status", getValues("status"));
    if (currentStep === 1) {
      let result = EditCourse
        ? await course_update(updataCourseId, form)
        : await course_add(form);
      if (result.status) {
        setUpdateCourseID(result.data?.id);
        setEditCourse(true);
        let course_id = result.data?.id;
        let term_id = result?.data?.term_id;
        let year = result.data?.year;
        let grade_id = result.data?.grade_id;
        if (!EditCourse) {
          localStorage.setItem("course_id", course_id);
          localStorage.setItem("term_id", term_id);
          localStorage.setItem("year", year);
          localStorage.setItem("grade_id", grade_id);
        }
        setCurrentStep(currentStep + 1);
        setLearningStep(1);
      }
    } else {
      setCurrentStep(currentStep + 1);
    }
    setIsSubmitting(false);
  };
  const preparePayload = () => {
    let payload = { data: [] };
    const selectedLectures = lecthureData[selectedSubjects];

    if (!Array.isArray(selectedLectures)) {
      console.error(
        "No valid lecture data for selected subject:",
        selectedSubjects
      );
      return payload;
    }
    const start_date = getValues("start_date");
    if (learnigStep === 1) {
      payload = {
        data: selectedLectures.map((unit) => ({
          unit_id: unit.unit_id || "",
          start_date,
          subject_id: selectedSubjects,
          units_json_data: unit.lectures.map((lecture) => ({
            movie_type: lecture.movie_type || "",
            title: lecture.title || "",
            file_path: lecture.file_path || "",
            jwp_media: lecture.jwp_media || "",
          })),
        })),
      };
    } else if (learnigStep === 2) {
      const selectedSelfStudy = selfStudy[selectedSubjects];
      if (Array.isArray(selectedSelfStudy)) {
        payload = {
          data: selectedSelfStudy.map((unit) => ({
            unit_id: unit.unit_id || "",
            start_date,
            subject_id: selectedSubjects,
            units_json_data: unit.selfDir.map((selfDir) => ({
              movie_type: selfDir.movie_type || "",
              title: selfDir.title || "",
              file_path: selfDir.file_path || "",
              jwp_media: selfDir.jwp_media || "",
            })),
          })),
        };
      }
    } else if (learnigStep === 3) {
      const selectedHomeWork = homeWork[selectedSubjects];

      if (Array.isArray(selectedHomeWork)) {
        payload = {
          data: selectedHomeWork.map((unit) => ({
            unit_id: unit.unit_id || "",
            start_date,
            subject_id: selectedSubjects,
            units_json_data:
              unit.homeWorkData?.map((homeWorkItem) => ({
                skill: homeWorkItem.skill || "",
                title: homeWorkItem.title || "",
                tstm_guid: homeWorkItem.tstm_guid || "",
              })) || [],
          })),
        };
      }
    } else if (learnigStep === 6) {
      payload = {
        data: evaluation,
      };
    }

    return payload;
  };
  const onSubmitSteps = async () => {
    setCallEvaluationList(false);
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const apiMappings = {
        1: course_lecture,
        2: course_self_study,
        3: course_homework,
        4: course_etutoring,
        5: course_consulting,
        6: course_evaluation1,
      };
      const apiFunction = apiMappings[learnigStep];
      if (!apiFunction) {
        console.error("No API function found for this step.");
        return;
      }
      // return
      const formData = preparePayload(currentStep);
      console.log('formData', formData);
      const result = await apiFunction(formData, course_id, term_id);
      if (result.status) {
        if (learnigStep === 6) {
          setCallEvaluationList(true);
        }
        console.log(`data submitted successfully!`);
      } else {
        console.error("Submission failed:", result);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setCallEvaluationList(false);
    } finally {
      setIsSubmitting(false);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      if (learnigStep === 3) {
        const skillData = await skill_by_subjectId(selectedSubjects);
        setSkillData(skillData?.data);
      } else if (learnigStep === 6) {
        const testTypeData = await test_type_list(year, grade_id, term_id);
        setTestTypeData(testTypeData?.data);
      } else {
        const movieTypeData = await movie_type_by_subjectId(selectedSubjects);
        setMovieTypeData(movieTypeData?.data);
      }
    };
    fetchData();
  }, [selectedSubjects, learnigStep]);
  useEffect(() => {
    const fetchData = async () => {
      const gradeList = await grade_all();
      const courseType = await get_category();
      const terms = await get_terms_by_year(year_id);
      const yearData = await get_all_year_data();
      setGradeList(gradeList?.data);
      setCourseType(courseType?.data);
      setTermList(terms?.data);
      setYearData(yearData?.data);
    };
    fetchData();
  }, [learnigStep, year_id]);
  const course_type = localStorage.getItem("course_type");
  useEffect(() => {
    if (coursesubjectsId?.length > 0) {
      const getData = async () => {
        const result2 = await subject_by_coursetype(coursesubjectsId);
        if (result2?.data.length > 0) {
          setSelectedSubject(result2?.data[0]?.value);
        }
        setCoursesubjects(result2?.data);
      };
      getData();
    } else if (course_type?.length > 0) {
      const getData = async () => {
        const result2 = await subject_by_coursetype(course_type);
        if (result2?.data.length > 0) {
          setSelectedSubject(result2?.data[0]?.value);
        }
        setCoursesubjects(result2?.data);
        localStorage.removeItem("course_type");
      };
      getData();
    }
  }, [coursesubjectsId]);
  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("lecthureData")) || {};
    setLectureData(savedData);

  }, []);

  const handleSubjectSelection = (subjectId) => {
    if (selectedSubjects) {
      // Save lecture, self-study, and homework data
      localStorage.setItem(
        "lecthureData",
        JSON.stringify({
          ...lecthureData,
          [selectedSubjects]: lecthureData[selectedSubjects],
        })
      );
      localStorage.setItem(
        "selfStudyData",
        JSON.stringify({
          ...selfStudy,
          [selectedSubjects]: selfStudy[selectedSubjects],
        })
      );
      localStorage.setItem(
        "homeWorkData",
        JSON.stringify({
          ...homeWork,
          [selectedSubjects]: homeWork[selectedSubjects],
        })
      );
    }
    setSelectedSubject(subjectId);
    const lectureDataForSubject = lecthureData[subjectId] || [];
    const selfStudyDataForSubject = selfStudy[subjectId] || [];
    const homeWorkDataForSubject = homeWork[subjectId] || [];
    setLectureData((prev) => ({ ...prev, [subjectId]: lectureDataForSubject }));
    setSelfStudy((prev) => ({ ...prev, [subjectId]: selfStudyDataForSubject }));
    setHomeWork((prev) => ({ ...prev, [subjectId]: homeWorkDataForSubject }));
  };

  // useEffect(() => {
  //   return () => {
  //     localStorage.setItem("lecthureData", JSON.stringify(lecthureData));
  //   };
  // }, [lecthureData]);
  useEffect(() => {
    const savedHomeWorkData =
      JSON.parse(localStorage.getItem("homeWorkData")) || {};
    setHomeWork(savedHomeWorkData);
  }, []);
  const SubmitAllCourseData = () => {
    // debugger
    localStorage.removeItem("homeWorkData");
    localStorage.removeItem("selfStudyData");
    localStorage.removeItem("lecthureData");
    localStorage.removeItem("course_id");
    localStorage.removeItem("term_id");
    setUpdateCourseID("");
    setEditCourse("");
    navigate("/admin/course-managment/course/all");
  };

  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <div className="modal-header">
          <div className="col-md-6">
            <h4 className="modal-title">Add Course</h4>
          </div>
          <div className="col-md-6 text-end">
            <div
              className="modify-right"
              onClick={() => localStorage.removeItem("courseSubjectID")}
            >
              <Link
                className="edit-btn"
                to={`/admin/course-managment/course/all`}
                onClick={(e) => {
                  handleNavigation(e, isDirty);
                }}
              >
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
        </div>
        <br />
        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              // onClick={() => setCurrentStep(1)}
              className={`nav-link ${currentStep === 1 ? "active " : ""}`}
              type="button"
              style={{ cursor: "auto" }}
            >
              Basic Info
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              // onClick={() => setCurrentStep(2)}
              className={`nav-link ${currentStep === 2 ? "active " : ""}`}
              type="button"
              style={{ cursor: "auto" }}
            >
              Steps of learning
            </button>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          {currentStep === 1 && (
            <form
              className="form-horizontal"
              onSubmit={handleSubmit(onSubmit)}
              encType="multipart/form-data"
            >
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>course Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Course Name"
                        {...register("name", {
                          required: true,
                          setValueAs: (value) => value.trim(),
                        })}
                      />
                      {errors.name && errors.name.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Year *</label>
                      <select
                        className="form-select form-control"
                        {...register("year", { required: true })}
                      >
                        <option value="">Choose Year</option>
                        {yearData?.length > 0 ? (
                          yearData.map((elem) => {
                            return (
                              <option value={elem?.id}>
                                {elem?.current_year}
                              </option>
                            );
                          })
                        ) : (
                          <option>No result</option>
                        )}
                      </select>
                      {errors.year && errors.year.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Grade *</label>
                      <select
                        className="form-select form-control"
                        {...register("grade_id", { required: true })}
                        onChange={(e) => setGradeID(e.target.value)}
                      >
                        <option value="">Choose Grade</option>
                        {gradelist?.length > 0 ? (
                          gradelist.map((elem) => {
                            return (
                              <option key={elem?.id} value={elem?.id}>
                                {elem.name === 0
                                  ? `Year ${elem.name} (kindy)`
                                  : `Year ${elem.name}`}
                              </option>
                            );
                          })
                        ) : (
                          <option>No result</option>
                        )}
                      </select>
                      {!gradeID &&
                        errors.grade_id &&
                        errors.grade_id.type === "required" && (
                          <span className="validation-error">
                            This is required
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Term *</label>
                      <select
                        className="form-select form-control"
                        name="term"
                        {...register("term_id", { required: true })}
                      >
                        <option value="">Choose Term </option>
                        {termsList &&
                          termsList?.map((elem) => {
                            return (
                              <option value={elem?.id}>{elem?.name}</option>
                            );
                          })}
                      </select>
                      {errors.term_id && errors.term_id.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Course Type *</label>
                      <select
                        className="form-select form-control"
                        {...register("course_type_id", { required: true })}
                        onChange={(e) => setCoursesubjectsId(e.target.value)}
                      >
                        <option value="">Choose Course Type</option>
                        {courseType &&
                          courseType?.map((elem) => {
                            return (
                              <option value={elem?.id}>
                                {elem?.category_name}
                              </option>
                            );
                          })}
                      </select>
                      {coursesubjectsId?.length > 0
                        ? ""
                        : errors.course_type_id &&
                        errors.course_type_id.type === "required" && (
                          <span className="validation-error">
                            This is required
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Total Units *</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter total units"
                        {...register("totalUnits", {
                          required: "This field is required",
                          validate: (value) =>
                            value > 0 || "Total units must be greater than 0",
                        })}
                        onKeyPress={(event) => {
                          if (!/[\d]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onInput={(e) => handleInputChange(e.target.value)}
                        disabled
                      />
                      {errors.totalUnits && (
                        <span className="validation-error">
                          {errors.totalUnits.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Unit Price *</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Course Price"
                        onKeyPress={(event) => {
                          if (!/[\d\.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        {...register("price", { required: true })}
                      />
                      {errors.price && errors.price.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>status</label>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          {...register("status", { required: true })}
                          id="inlineRadio1"
                          value="1"
                          defaultChecked
                        />
                        <label class="form-check-label" for="inlineRadio1">
                          Active
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          {...register("status", { required: true })}
                          id="inlineRadio2"
                          value="0"
                        />
                        <label class="form-check-label" for="inlineRadio2">
                          Inactive
                        </label>
                      </div>
                      {errors.status && errors.status.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {currentStep === 1 && (
                <div className="modal-footer">
                  <Button
                    type="submit"
                    className="btn button"
                    disabled={isSubmitting}
                  >
                    Save & Next
                  </Button>
                </div>
              )}
            </form>
          )}
          {currentStep === 2 && (
            <>
              <div
                className="tab-pane fade show active"
                id="pills-home1"
                role="tabpane"
                aria-labelledby="pills-home-tab1"
              >
                <div className="modal-body">
                  <ul
                    className="nav nav-pills mb-3"
                    id="pills-tab1"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      {/* <button onClick={() => setLearningStep(1)} className={`nav-link ${learnigStep === 1 ? 'active ' : ''}`} id="pills-assesment-test-tab" data-bs-toggle="pill" data-bs-target="#pills-assesment-test" type="button" role="tab" aria-controls="pills-assesment-test" aria-selected="true">Assessment Test</button> */}
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${learnigStep === 1 ? "active " : ""
                          }`}
                        type="button"
                        style={{ cursor: "auto" }}
                      >
                        Lectures
                      </button>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                                                <button onClick={() => setLearningStep(1)} className={`nav-link ${learnigStep === 1 ? 'active ' : ''}`} id="pills-lectures-tab" data-bs-toggle="pill" data-bs-target="#pills-lectures" type="button" role="tab" aria-controls="pills-lectures" aria-selected="false">Lectures</button>
                                            </li> */}
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${learnigStep === 2 ? "active " : ""
                          }`}
                        type="button"
                        style={{ cursor: "auto" }}
                      >
                        Self Directed Study
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${learnigStep === 3 ? "active " : ""
                          }`}
                        type="button"
                        style={{ cursor: "auto" }}
                      >
                        Homework / Topic Test
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${learnigStep === 4 ? "active " : ""
                          }`}
                        type="button"
                      >
                        E-Tutoring
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${learnigStep === 5 ? "active " : ""
                          }`}
                        type="button"
                        style={{ cursor: "auto" }}
                      >
                        Consulting
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${learnigStep === 6 ? "active " : ""
                          }`}
                        type="button"
                        style={{ cursor: "auto" }}
                      >
                        Evaluation
                      </button>
                    </li>
                    <li>
                      <div className="modal-footer">
                        {learnigStep >= 2 && (
                          <button
                            className="btn btn-secondary"
                            onClick={() =>
                              setLearningStep(
                                learnigStep === 6
                                  ? learnigStep - 3
                                  : learnigStep - 1
                              )
                            }
                          >
                            Previous
                          </button>
                        )}
                        {learnigStep >= 0 && learnigStep < 6 && (
                          <Button
                            className="btn button"
                            onClick={() =>
                              setLearningStep(
                                learnigStep === 3
                                  ? learnigStep + 3
                                  : learnigStep + 1
                              )
                            }
                          >
                            Next
                          </Button>
                        )}
                      </div>
                    </li>
                  </ul>
                  <div className="tab-content1" id="pills-tabContent1">
                    {/* {learnigStep === 1 && (
                                                <AddAssessment setInputList={setInputList} inputList={inputList} />
                                            )} */}
                    {learnigStep === 1 && (
                      <>
                        <div className="modal-header">
                          <h4 className="modal-title">Step 1 - Lectures</h4>
                        </div>
                        <form
                          className="form-horizontal"
                          onSubmit={handleSubmit(onSubmitSteps)}
                          encType="multipart/form-data"
                        >
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Subject</label>
                              <ul className="nav nav-tabs">
                                {coursesubjects &&
                                  coursesubjects.map((elem) => {
                                    return (
                                      <li
                                        key={elem.value}
                                        className={`nav-item ${selectedSubjects === elem.value
                                          ? "active"
                                          : ""
                                          }`}
                                      >
                                        <a
                                          className={`nav-link ${selectedSubjects === elem.value
                                            ? "active"
                                            : ""
                                            }`}
                                          value={elem?.label}
                                          id="pills-evaluation-tab"
                                          data-bs-toggle="pill"
                                          data-bs-target="#pills-evaluation"
                                          type="button"
                                          role="tab"
                                          aria-controls="pills-evaluation"
                                          aria-selected="false"
                                          onClick={() =>
                                            handleSubjectSelection(elem.value)
                                          }
                                        >
                                          {elem.label}
                                        </a>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Start Date *</label>
                              <input
                                type="date"
                                className="form-control"
                                name="start_date"
                                pattern="dd-mm-yyyy"
                                max="9999-12-31"
                                min={today}
                                {...register("start_date", {
                                  required: "Start date is required!",
                                  validate: (value) => {
                                    const selectedDate = new Date(value);
                                    const todayDate = new Date();
                                    todayDate.setHours(0, 0, 0, 0); // Reset time to ensure proper comparison

                                    return (
                                      selectedDate >= todayDate || "Please select today or a future date."
                                    );
                                  },
                                })}

                              />
                              {errors.start_date && (
                                <p className="validation-error">
                                  {errors.start_date.message}
                                </p>
                              )}
                              { }
                            </div>
                          </div>
                          <AddLectures
                            subjectId={selectedSubjects}
                            sub={lecthureData[selectedSubjects] || []}
                            setSub={(data) =>
                              setLectureData((prev) => ({
                                ...prev,
                                [selectedSubjects]: data,
                              }))
                            }
                            gradeID={gradeID}
                            movieTypes={movieTypeData}
                          />
                          <div className="modal-footer">
                            {learnigStep === 1 && (
                              <button
                                type="submit"
                                className="btn button"
                                disabled={isSubmitting}
                              >
                                Save
                              </button>
                            )}
                          </div>
                          <div className="modal-footer">
                            {updateTermData?.currentStep !== 2 &&
                              currentStep > 1 && (
                                <button
                                  className="btn btn-secondary"
                                  onClick={() =>
                                    setCurrentStep(currentStep - 1)
                                  }
                                >
                                  Previous
                                </button>
                              )}
                            {learnigStep === 1 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep + 1)}
                              >
                                Next
                              </button>
                            )}
                            <button
                              className="btn btn-secondary"
                              onClick={() => SubmitAllCourseData()}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </>
                    )}
                    {learnigStep === 2 && (
                      <>
                        <div className="modal-header">
                          <h4 className="modal-title">
                            Step 2 - Self Directed Study
                          </h4>
                        </div>
                        <form
                          className="form-horizontal"
                          onSubmit={handleSubmit(onSubmitSteps)}
                          encType="multipart/form-data"
                        >
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Subject</label>
                              <ul className="nav nav-tabs">
                                {coursesubjects &&
                                  coursesubjects.map((elem) => {
                                    return (
                                      <li
                                        key={elem.value}
                                        className={`nav-item ${selectedSubjects === elem.value
                                          ? "active"
                                          : ""
                                          }`}
                                      >
                                        <a
                                          className={`nav-link ${selectedSubjects === elem.value
                                            ? "active"
                                            : ""
                                            }`}
                                          value={elem?.label}
                                          id="pills-evaluation-tab"
                                          data-bs-toggle="pill"
                                          data-bs-target="#pills-evaluation"
                                          type="button"
                                          role="tab"
                                          aria-controls="pills-evaluation"
                                          aria-selected="false"
                                          onClick={(e) =>
                                            setSelectedSubject(elem?.value)
                                          }
                                        >
                                          {elem.label}
                                        </a>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Start Date *</label>
                              <input
                                type="date"
                                className="form-control"
                                name="start_date"
                                pattern="dd-mm-yyyy"
                                max="9999-12-31"
                                min={today}
                                {...register("start_date", {
                                  required: "Start date is required!",
                                  validate: (value) => {
                                    const selectedDate = new Date(value);
                                    const todayDate = new Date();
                                    todayDate.setHours(0, 0, 0, 0); // Reset time to ensure proper comparison

                                    return (
                                      selectedDate >= todayDate || "Please select today or a future date."
                                    );
                                  },
                                })}
                              />
                              {errors.SelfStudy_start_date && (
                                <p className="validation-error">
                                  {errors.SelfStudy_start_date.message}
                                </p>
                              )}
                              { }
                            </div>
                          </div>
                          <AddSelfStudy
                            setSelfStudy={(data) =>
                              setSelfStudy((prev) => ({
                                ...prev,
                                [selectedSubjects]: data,
                              }))
                            }
                            selfStudy={selfStudy[selectedSubjects] || []}
                            gradeID={gradeID}
                            movieTypes={movieTypeData}
                          />
                          <div className="modal-footer">
                            {learnigStep === 2 && (
                              <button
                                type="submit"
                                className="btn button"
                                disabled={isSubmitting}
                              >
                                Save
                              </button>
                            )}
                          </div>
                          <div className="modal-footer">
                            {learnigStep === 2 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep - 1)}
                              >
                                Previous
                              </button>
                            )}
                            {learnigStep === 2 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep + 1)}
                              >
                                Next
                              </button>
                            )}
                            <button
                              className="btn btn-secondary"
                              onClick={() => SubmitAllCourseData()}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </>
                    )}
                    {learnigStep === 3 && (
                      <>
                        <div className="modal-header">
                          <h4 className="modal-title">Step 3 - homework</h4>
                        </div>
                        <form
                          className="form-horizontal"
                          onSubmit={handleSubmit(onSubmitSteps)}
                          encType="multipart/form-data"
                        >
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Subject</label>
                              <ul className="nav nav-tabs">
                                {coursesubjects &&
                                  coursesubjects.map((elem) => {
                                    return (
                                      <li
                                        key={elem.value}
                                        className={`nav-item ${selectedSubjects === elem.value
                                          ? "active"
                                          : ""
                                          }`}
                                      >
                                        <a
                                          className={`nav-link ${selectedSubjects === elem.value
                                            ? "active"
                                            : ""
                                            }`}
                                          value={elem?.label}
                                          id="pills-evaluation-tab"
                                          data-bs-toggle="pill"
                                          data-bs-target="#pills-evaluation"
                                          type="button"
                                          role="tab"
                                          aria-controls="pills-evaluation"
                                          aria-selected="false"
                                          onClick={(e) =>
                                            setSelectedSubject(elem?.value)
                                          }
                                        >
                                          {elem.label}
                                        </a>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Start Date *</label>
                              <input
                                type="date"
                                className="form-control"
                                name="start_date"
                                pattern="dd-mm-yyyy"
                                max="9999-12-31"
                                min={today}
                                {...register("start_date", {
                                  required: "Start date is required!",
                                  validate: (value) => {
                                    const selectedDate = new Date(value);
                                    const todayDate = new Date();
                                    todayDate.setHours(0, 0, 0, 0); // Reset time to ensure proper comparison

                                    return (
                                      selectedDate >= todayDate || "Please select today or a future date."
                                    );
                                  },
                                })}
                              />
                              {errors.HomeWork_start_date && (
                                <p className="validation-error">
                                  {errors.HomeWork_start_date.message}
                                </p>
                              )}
                              { }
                            </div>
                          </div>
                          <AddHomeWork
                            subjectId={selectedSubjects}
                            setHomeWorkData={(data) =>
                              setHomeWork((prev) => ({
                                ...prev,
                                [selectedSubjects]: data,
                              }))
                            }
                            homeWorkData={homeWork[selectedSubjects] || []}
                            gradeID={gradeID}
                            skillData={skillData}
                          />
                          <div className="modal-footer">
                            {learnigStep === 3 && (
                              <button
                                type="submit"
                                className="btn button"
                                disabled={isSubmitting}
                              >
                                Save
                              </button>
                            )}
                          </div>
                          <div className="modal-footer">
                            {learnigStep === 3 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep - 1)}
                              >
                                Previous
                              </button>
                            )}
                            {learnigStep === 3 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep + 3)}
                              >
                                Next
                              </button>
                            )}
                            <button
                              className="btn btn-secondary"
                              onClick={() => SubmitAllCourseData()}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </>
                    )}
                    {learnigStep === 4 && (
                      <>
                        <div className="modal-header">
                          <h4 className="modal-title">Step 4 - E-Tutoring</h4>
                        </div>
                        <form
                          className="form-horizontal"
                          onSubmit={handleSubmit(onSubmitSteps)}
                          encType="multipart/form-data"
                        >
                          <AddeTutoring />
                          <div className="modal-footer">
                            {learnigStep === 4 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep - 1)}
                              >
                                Previous
                              </button>
                            )}
                            {learnigStep === 4 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep + 1)}
                              >
                                Next
                              </button>
                            )}
                            <button
                              className="btn btn-secondary"
                              onClick={() => SubmitAllCourseData()}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </>
                    )}
                    {learnigStep === 5 && (
                      <>
                        <div className="modal-header">
                          <h4 className="modal-title">Step 5 - Consulting</h4>
                        </div>
                        <form
                          className="form-horizontal"
                          onSubmit={handleSubmit(onSubmitSteps)}
                          encType="multipart/form-data"
                        >
                          <AddConsultings />
                          <div className="modal-footer">
                            {learnigStep === 5 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep - 1)}
                              >
                                Previous
                              </button>
                            )}
                            {learnigStep === 5 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep + 1)}
                              >
                                Next
                              </button>
                            )}
                            <button
                              className="btn btn-secondary"
                              onClick={() => SubmitAllCourseData()}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </>
                    )}
                    {learnigStep === 6 && (
                      <>
                        <div className="modal-header">
                          <h4 className="modal-title">Step 6 - Evaluation</h4>
                        </div>
                        <form
                          className="form-horizontal"
                          onSubmit={handleSubmit(onSubmitSteps)}
                          encType="multipart/form-data"
                        >
                          {/* <div className="col-md-6">
                            <div className="form-group">
                              <label>Subject</label>
                              <ul className="nav nav-tabs">
                                {coursesubjects &&
                                  coursesubjects.map((elem) => {
                                    return (
                                      <li
                                        key={elem.value}
                                        className={`nav-item ${
                                          selectedSubjects === elem.value
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        <a
                                          className={`nav-link ${
                                            selectedSubjects === elem.value
                                              ? "active"
                                              : ""
                                          }`}
                                          value={elem?.label}
                                          id="pills-evaluation-tab"
                                          data-bs-toggle="pill"
                                          data-bs-target="#pills-evaluation"
                                          type="button"
                                          role="tab"
                                          aria-controls="pills-evaluation"
                                          aria-selected="false"
                                          onClick={(e) =>
                                            setSelectedSubject(elem?.value)
                                          }
                                        >
                                          {elem.label}
                                        </a>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </div> */}
                          <AddEvaluation
                            setEvaluation={setEvaluation}
                            evaluation={evaluation}
                            gradeID={gradeID}
                            testTypeData={testTypeData}
                            setForms={setForms}
                            forms={forms}
                            CallEvaluationList={CallEvaluationList}
                            setCheckEvaluationValidation={setCheckEvaluationValidation}
                          />
                          <div className="modal-footer">
                            {learnigStep === 6 && CheckEvaluationValidation && (
                              <button
                                type="submit"
                                className="btn button"
                                disabled={isSubmitting}
                              >
                                Save
                              </button>
                            )}
                          </div>
                          <div className="modal-footer">
                            {learnigStep === 6 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep - 3)}
                              >
                                Previous
                              </button>
                            )}
                            {learnigStep === 6 && (
                              <>
                                <button
                                  className="btn btn-secondary"
                                  onClick={() => SubmitAllCourseData()}
                                >
                                  Submit
                                </button>
                              </>
                            )}
                          </div>
                        </form>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default AddCourse;
