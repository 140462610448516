import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataLoader from "../../../../Utils/DataLoader";
import CustomTable from "../../CustomTable";
import { getUserPermission } from "../../../../Utils/Utils";
import {
  all_TestType_Data,
  test_Type_destroy,
} from "../../../../apis/generalSetting/testType";
const AllTestTypeData = () => {
  const [grade, setGrade] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const permission = getUserPermission("TestType");
  const UserID = JSON.parse(localStorage.getItem("userData"))?.data?.id;
  const navigate = useNavigate();
  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: false,
    },
    {
      name: "Test title",
      selector: "test_title",
      sortable: false,
    },
    {
      name: "Type name",
      selector: "type_name",
      sortable: false,
    },
    {
      name: "Test period",
      selector: "test_period",
      sortable: false,
    },
    {
      name: "Course Type",
      selector: "course_type_name",
      sortable: false,
    },
    {
      name: "Term",
      selector: "term_name",
      sortable: false,
    },
    {
      name: "Grade",
      selector: "grade",
      sortable: false,
    },
    {
      name: "Year",
      selector: "year",
      sortable: false,
    },
    {
      name: "Status",
      selector: "status",
      sortable: false,
    },
    permission?.edit === 1 || permission?.delete === 1 || permission?.view === 1
      ? {
        name: "Action",
        selector: "action",
      }
      : "",
  ];
  useEffect(() => {
    async function get_data() {
      const result = await all_TestType_Data();
      const arrayData = result?.data?.map((elem, index) => {
        const {
          id,
          test_title,
          test_period,
          year,
          course_type_name,
          type_name,
          term_name,
          grade,
          delete_status,
          status,
        } = elem;
        return {
          no: `#${index + 1}`,
          test_title: test_title,
          test_period: test_period,
          type_name: type_name,
          term_name: term_name,
          test_title: test_title,
          grade: grade,
          year: year,
          course_type_name: course_type_name,
          //name:name,
          status_data : delete_status,
          status: (
            <select
              className="form-select"
              onChange={(e) => destroy(id, e.target.value)}
              value={delete_status}
            >
              <option value={1}>Active</option>
              <option value={0}>Inactive</option>
            </select>
          ),
          action: (
            <div data-tag="allowRowEvents">
              {permission?.edit === 1 ? (
                status === 0 ? (
                  <Link
                    className="edit-btn"
                    to={`/admin/test-management/test-type/edit/${id}`}
                  >
                    {" "}
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </Link>
                ) : (
                  <button
                    className="dlt-btn"
                    onClick={() => editTestType(status, id)}
                  >
                    {" "}
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                )
              ) : (
                ""
              )}
            </div>
          ),
        };
      });
      setGrade(arrayData, setShowResults(true));
    }
    const destroy = async (id, status) => {
      const data = { status: status };
      const result = window.confirm(
        `${status == 1
          ? "Are you sure you want to active this test ?"
          : "Are you sure you want to inactive this test ? "
        }`
      );
      if (result) {
        await test_Type_destroy(id, data);
      }
      get_data();
    };
    const editTestType = async (status, id) => {
      const result = window.confirm(
        `${"This test type is already enrolled in the evaluation list. Are you sure you want to proceed with editing it??"}`
      );
      if (result) {
        navigate(`/admin/test-management/test-type/edit/${id}`);
      }
      get_data();
    };
    get_data();
  }, []);
  return (
    <>
      <div className="modal-content">
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="right-nav" id="dash-event">
              {showResults ? (
                <>
                  <div className="row">
                    <div className="col-md-4">
                      <h4 className="modal-title">Test List</h4>
                    </div>
                    <div className="col-md-8">
                      {permission?.add === 1 ? (
                        <Link
                          className="ui button float-end"
                          to="/admin/test-management/test-type/add"
                        >
                          Add test
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <CustomTable columns={columns} data={grade} />
                </>
              ) : (
                <DataLoader />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AllTestTypeData;
