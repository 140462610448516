import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link, useParams } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { handleNavigation } from "../../../../Utils/Utils";
import { subject_all } from "../../../../apis/ClassManagement/Subjects";
import { get_terms_by_year, } from "../../../../apis/Common";
import { grade_all } from "../../../../apis/Grade";
import { get_all_year_data } from "../../../../apis/generalSetting/WebsiteEnv";
import { test_Type_update, test_Type_edit, all_type_test, } from "../../../../apis/generalSetting/testType";
import DataLoader from "../../../../Utils/DataLoader";
import { course_type_all } from "../../../../apis/Branches";
const EditTest_type = () => {
  const [showResults, setShowResults] = useState(false);
  const [yeardata, setYearData] = useState([]);
  const [termdata, setTermData] = useState([]);
  const [gradeData, setGradeData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [courseData, setCourseData] = useState([]);
  const [subject, setSubject] = useState([]);
  const [testTypeList, setTestTypeList] = useState([]);
  const [showGuid, setShowGuid] = useState(false);
  const navigate = useNavigate();
  const id = useParams();

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm({ mode: "onChange" });
  const year_id = watch("year");
  const type_id = watch("type_id");
  console.log('testTypeList', testTypeList);

  useEffect(() => {
    const fetchData = async () => {
      const year = await get_all_year_data();
      const grade = await grade_all();
      const courseList = await course_type_all();
      const subject = await subject_all();
      const test_type = await all_type_test();
      setYearData(year?.data);
      setGradeData(grade?.data);
      setCourseData(courseList?.data);
      setSubject(subject?.data);
      setTestTypeList(test_type?.data);
      const data = await test_Type_edit(id);
      const term = await get_terms_by_year(data?.data[0]?.year);
      setTermData(term?.data);
      setValue("type_id", data?.data[0]?.type_id);
      setShowGuid(data?.data[0]?.type_id === 1 ? true : false)
      setValue("course_type_id", data?.data[0]?.course_type_id);
      setValue("year", data?.data[0]?.year);
      setValue("term", data?.data[0]?.term);
      setValue("grade", data?.data[0]?.grade);
      setValue("test_title", data?.data[0]?.test_title);
      setValue("test_period", data?.data[0]?.test_period);
      setValue("subject_id", data?.data[0]?.subject_id);
      setValue("tstm_guid", data?.data[0]?.tstm_guid);
      setShowResults(true);
      if (data?.data[0]?.type_id === "1") {
        setValue("tstm_guid_required", true);
      }
    };
    fetchData();
  }, [id]);
  const isTstmGuidRequired = watch("type_id") === "1";
  console.log(isTstmGuidRequired, 'isTstmGuidRequired');

  useEffect(() => {
    const fetchData = async () => {
      const term = await get_terms_by_year(year_id);
      setTermData(term?.data);
      // setValue("term", "");
    };
    fetchData();
  }, [year_id]);
  const onSubmit = async (data) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    let result = await test_Type_update(id, data);
    if (result.status === true) {
      return navigate("/admin/test-management/test-type/all");
    }
    setIsSubmitting(false);
  };
  return (
    <div className="right-nav" id="dash-event">
      {showResults ? (
        <div className="modal-content">
          <div className="modal-header">
            <div className="col-md-6">
              <h4 className="modal-title">Edit test type</h4>
            </div>
            <div className="col-md-6 text-end">
              <div className="modify-right">
                <Link
                  className="edit-btn"
                  to={`/admin/test-management/test-type/all`}
                  onClick={(e) => {
                    handleNavigation(e, isDirty);
                  }}
                >
                  <i class="fa fa-arrow-left" aria-hidden="true"></i>
                </Link>
              </div>
            </div>
          </div>
          <br />
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Test type *</label>
                    <select
                      className="form-select form-control"
                      name="type"
                      {...register("type_id", { required: true })}
                    >
                      <option value="">Choose test type</option>
                      {testTypeList &&
                        testTypeList?.map((elem) => (
                          <option value={elem?.id}>{elem?.name}</option>
                        ))}
                    </select>
                    {errors.type_id && errors.type_id.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Course Type *</label>
                    <select
                      className="form-select form-control"
                      name="course_id"
                      {...register("course_type_id", { required: true })}
                    >
                      <option value="">Choose course type</option>
                      {courseData &&
                        courseData?.map((elem) => (
                          <option value={elem?.id}>
                            {elem?.category_name}
                          </option>
                        ))}
                    </select>
                    {errors.course_type_id &&
                      errors.course_type_id.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Year *</label>
                    <select
                      className="form-select form-control"
                      name="year"
                      {...register("year", {
                        required: true,
                        onChange: () => { setValue('term', '') }
                      })}
                    >
                      <option value="">Choose year</option>
                      {yeardata &&
                        yeardata?.map((elem) => (
                          <option value={elem?.id}>{elem?.current_year}</option>
                        ))}
                    </select>
                    {errors.year && errors.year.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Term</label>
                    <select
                      className="form-select form-control"
                      name="term"
                      {...register("term")}
                    >
                      <option value="">Choose term</option>
                      {termdata &&
                        termdata?.map((elem) => (
                          <option value={elem?.id}>{elem?.name}</option>
                        ))}
                    </select>
                    {/* {errors.term && errors.term.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )} */}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Grade *</label>
                    <select
                      className="form-select form-control"
                      name="grade"
                      {...register("grade", { required: true })}
                    >
                      <option value="">Choose grade</option>
                      {gradeData &&
                        gradeData?.map((elem) => (
                          <option value={elem?.id}>Year {elem?.name}</option>
                        ))}
                    </select>
                    {errors.grade && errors.grade.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Test title *</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter test title"
                      {...register("test_title", { required: true })}
                    />
                    {errors.test_title &&
                      errors.test_title.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Subject *</label>
                    <select
                      className="form-select form-control"
                      name="subject"
                      {...register("subject_id", { required: true })}
                    >
                      <option value="">Choose subject</option>
                      {subject &&
                        subject?.map((elem) => (
                          <option value={elem?.id}>{elem?.subject}</option>
                        ))}
                    </select>
                    {errors.subject_id &&
                      errors.subject_id.type === "required" && (
                        <span className="validation-error">This is required</span>
                      )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Test period (in days) *</label>
                    <div className="days">
                      <input
                        type="text"
                        className="form-control enter_text_text"
                        placeholder="Enter test period"
                        {...register("test_period", { required: true })}
                      />
                      <div className="input-group-prepend">
                        <div className="input-group-text day_btn_text">
                          Days
                        </div>
                      </div>
                    </div>
                    {errors.test_period &&
                      errors.test_period.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                  </div>
                </div>
                {/* {Number(type_id) === 1 && ( */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>TSTM GUID *</label>
                      <input
                        type="text"
                        {...register("tstm_guid", { required: true })}
                        className={`form-control ${errors.tstm_guid ? "is-invalid" : ""}`}
                      />
                      {errors.tstm_guid && <span className="invalid-feedback">TSTM GUID is required</span>}
                    </div>
                  </div>
                 {/* )} */}
              </div>
              <div className="modal-footer">
                <Button
                  type="submit"
                  className="btn button"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <DataLoader />
      )}
    </div>
  );
};
export default EditTest_type;
