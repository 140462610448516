import React from "react";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { formatDate, handleNavigation } from "../../../../Utils/Utils";
import { view_student_report_detail } from "../../../../apis/reports/Reports";
import DataLoader from "../../../../Utils/DataLoader";
import StudentAttendanceCalendar from "./studentcelender";
const Student_report_view = () => {
  const [studentDetail, setStudentDetail] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const id = useParams();
  const {
    setValue,
    formState: { errors, isDirty },
  } = useForm();
  async function get_data() {
    const data = await view_student_report_detail(id?.id);
    setStudentDetail(data?.data);
    if (data?.data) {
      setShowResults(true);
    }
  }
  useEffect(() => {
    get_data();
  }, []);

  return (
    <div>
      {showResults ? (
        <div className="right-nav" id="dash-event">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Student Detail</h4>
              {/* <Link className='edit-btn' to={`/admin/students/member-edit/${id?.id}`}>Modify</Link> */}
              <Link
                className="edit-btn"
                to={`/admin/reports/student-detail`}
                onClick={(e) => {
                  handleNavigation(e, isDirty);
                }}
              >
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
              </Link>
            </div>
            <div className="modal-body">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="form-horizontal">
                    <div className="row">   
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Name</label>
                          <p>{studentDetail?.full_name || "-"}</p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Branch</label>
                          <p>{studentDetail?.branch_name || "-"}</p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Grade</label>
                          <p>Year {studentDetail?.grade_name || "-"}</p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>State</label>
                          <p>{studentDetail?.state_name || "-"}</p>
                        </div>
                      </div>
                      <StudentAttendanceCalendar attendance={studentDetail?.attendance}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <DataLoader />
      )}
    </div>
  );
};

export default Student_report_view;
