import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { grade_add } from "../../../apis/Grade";
import { Button } from "semantic-ui-react";
import { handleNavigation } from "../../../Utils/Utils";
const AddGrades = () => {
  const [checkValidation, setCheckValidation] = useState(true);
  const {
    register,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (data) => {
    if (isSubmitting) return;
      setIsSubmitting(true);
      if (!checkValidation) {
        console.log("not valid");
      } else {
        let result = await grade_add(data);
        if (result.status === true) {
         
          return navigate("/admin/general-setting/grades/all");
        }
        setIsSubmitting(false);
    }
  };

  const regexPattern = /^(year|Year)\s?(1[0-2]|[0-12])$/i;
  const checkregex = (name) => {
    if (regexPattern.test(name)) {
      console.log("Valid string");
      setCheckValidation(true);
    } else {
      setCheckValidation(false);
    }
  };

  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <div className="modal-header">
          <div className="col-md-6">
            <h4 className="modal-title">Add Grade</h4>
          </div>
          <div className="col-md-6 text-end">
            <div className="modify-right">
              <Link
                className="edit-btn"
                to={`/admin/general-setting/grades/all`}
                onClick={(e) => {
                    handleNavigation(e,isDirty)
                }}
              >
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
        </div>
        <br />
        <div
          className="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Grade Name *</label>
                  <select
                    className="form-select form-control"
                    name="name"
                    // onChange={(e) => {
                    //   handleInputChange(e);
                    //   // checkregex(e.target.value);
                    // }}
                    {...register('name', { required: true })}
                  >
                    <option value="">Choose Grade</option>
                    <option value="0">Year 0(kindy)</option>
                    <option value="1">Year 1</option>
                    <option value="2">Year 2</option>
                    <option value="3">Year 3</option>
                    <option value="4">Year 4</option>
                    <option value="5">Year 5</option>
                    <option value="6">Year 6</option>
                    <option value="7">Year 7</option>
                    <option value="8">Year 8</option>
                    <option value="9">Year 9</option>
                    <option value="10">Year 10</option>
                    <option value="11">Year 11</option>
                    <option value="12">Year 12</option>
                  </select>
                  {/* <input type="text" className="form-control"
                                        placeholder='Enter Grade Name'
                                        name="name"  {...register('name', { required: true })}
                                        onChange={(e) => checkregex(e.target.value)}
                                    /> */}
                  {errors.name && errors.name.type === "required" && (
                    <span className="validation-error">This is required</span>
                  )}
                  {/* {checkValidation ? (
                    ""
                  ) : (
                    <span className="validation-error">
                      Grade name should be year and number between 0 to 12
                    </span>
                  )} */}
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="form-group">
                  <label>Status</label>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      {...register("status", { required: true })}
                      id="inlineRadio1"
                      value="1"
                      defaultChecked
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      Active
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      {...register("status", { required: true })}
                      id="inlineRadio2"
                      value="0"
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      Inactive
                    </label>
                  </div>
                </div>
                {errors.status && errors.status.type === "required" && (
                  <span className="validation-error">This is required</span>
                )}
              </div> */}
            </div>
            <div className="modal-footer">
              <Button
                type="submit"
                className="btn button"
                disabled={isSubmitting}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export { AddGrades };
