import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
function checkToken() {
  var now = new Date().getTime();
  var setupTime = JSON.parse(localStorage.getItem('setupTime'));
  if (setupTime != null) {
    console.log("check user is logged in or not...")
    if (now - setupTime > 2 * 24 * 60 * 60 * 1000) {
      localStorage.clear();
      localStorage.removeItem("userData");
    }
  }
}


function RequireAuthAdmin() {
  checkToken();
  let userData = JSON.parse(localStorage.getItem("userData"));
  let location = useLocation();
  if (!userData) {
    return <Navigate replace to='/admin' state={{ from: location }} />
  }
  // Assuming userData has a 'roles' property to represent user roles
  if (userData && userData?.data?.roles === 'admin' || userData && userData?.data?.roles === 'Admin') {
    return <Outlet />;
  }
  return <p>You are not authorized access this view</p>
}

function AdminLogout() {
  localStorage.clear();
  localStorage.removeItem("userData");
  localStorage.removeItem("setupTime");
  return <Navigate replace to='/admin' />
}

function RequireAuthTeacher() {
  checkToken();
  let userData = JSON.parse(localStorage.getItem("userData"));
  let location = useLocation();
  if (!userData) {
    return <Navigate replace to='/teacher' state={{ from: location }} />
  }

  // Assuming userData has a 'roles' property to represent user roles
  if (userData && userData?.data?.roles === 'Teacher' || userData && userData?.data?.roles === 'teacher') {
    return <Outlet />;
  }
  return <p>You are not authorized access this view</p>
}

function TeacherLogout() {
  localStorage.clear();
  localStorage.removeItem("userData");
  localStorage.removeItem("setupTime");
  return <Navigate replace to='/teacher' />
}

function RequireAuthBranch() {
  checkToken();
  let userData = JSON.parse(localStorage.getItem("userData"));
  let location = useLocation();
  if (!userData) {
    return <Navigate replace to='/branch' state={{ from: location }} />
  }
  // Assuming userData has a 'roles' property to represent user roles
  if (userData && userData?.data?.roles === 'branch' || userData && userData?.data?.roles === 'Branch') {
    return <Outlet />;
  }
  return <p>You are not authorized access this view</p>
}

function BranchLogout() {
  localStorage.clear();
  localStorage.removeItem("userData");
  localStorage.removeItem("setupTime");
  return <Navigate replace to='/branch' />
}


const ProtectedRoute = ({ children }) => {
  var token = window.localStorage.getItem("userData");
  token = JSON.parse(token);
  const data = token?.data?.roles?.toLowerCase();
  if (!token) {
    if (data == 'admin') {
      return <Navigate to="/login" />;
    } else if (data == 'teacher') {
      return <Navigate to="/teacher" />
    }
    else if (data == 'branch') {
      return <Navigate to="/branch" />
    }

  }
  else {
    return children;
  }
};


const AfterLogin = ({ children }) => {
  var admin = window.localStorage.getItem("userData");
  admin = JSON.parse(admin);
  const data = admin?.data?.roles
  if (admin) {
    if (data == 'admin') {
      return <Navigate to="/admin/dashboard" />;
    } else if (data == 'teacher') {
      return <Navigate to="/teacher/dashboard" />;
    } else if (data == 'branch') {
      return <Navigate to="/branch/dashboard" />;
    }

  }
  else {
    return children;
  }
};

export function random_generate_string(length) {
  // declare all characters
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  let result = ' ';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function data_format(data) {
  const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  const date = new Date(data);
  const day = String(date.getDate()).padStart(2, '0');
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${day} ${month} ${year} ${hours}:${minutes}`;
}
export function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
}
// check form is change or not 
export const handleNavigation = (e, isDirty) => {
  if (isDirty && !window.confirm("Are you sure you want to leave this page?")) {
    e.preventDefault();
  }
};

export function getCurrentWeekISO() {
  const now = new Date();
  const startOfYear = new Date(now.getFullYear(), 0, 1);
  const days = Math.floor((now - startOfYear) / (1000 * 60 * 60 * 24));
  const weekNumber = Math.ceil((days + 1) / 7);
  const currentYear = now.getFullYear();

  return `${currentYear}-W${weekNumber.toString().padStart(2, '0')}`;
};
export function getUserPermission(section) {
  const data = JSON.parse(localStorage.getItem("userData"));
  return data?.data?.permission.find(item => item[section])?.[section];
}
const capitalizeFirstLetter = (text) => {
  if (typeof text === 'string' && text.length > 0) {
    return text
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
  return text;
};
export const ExportToExcel = async (data, fileName) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet1');
  const headers = ['S.No', ...Object.keys(data[0]).map(header => capitalizeFirstLetter(header))];
  worksheet.addRow(headers).eachCell((cell) => {
    cell.alignment = { horizontal: 'left', vertical: 'middle' };
    cell.font = { bold: true };
  });
  data.forEach((row, index) => {
    const rowData = [index + 1, ...Object.values(row).map(value => capitalizeFirstLetter(value.toString()))];
    const newRow = worksheet.addRow(rowData);
    newRow.eachCell((cell) => {
      cell.alignment = { horizontal: 'left', vertical: 'middle' };
    });
  });
  worksheet.columns.forEach((column) => {
    column.width = 20;
  });
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), `${fileName}.xlsx`);
};
export const ExportToExcelCustome = async (data, fileName, isRawArray = false) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet1');

  if (isRawArray) {
    data.forEach((row) => {
      const newRow = worksheet.addRow(row.map(cell => capitalizeFirstLetter(cell)));
      newRow.eachCell((cell) => {
        cell.alignment = { horizontal: 'left', vertical: 'middle' };
      });
    });
    worksheet.columns = [
      { width: 8 },
      { width: 15 },
      { width: 8 },
      ...Array(8).fill({ width: 12 }),
    ];
  } else {
    const headers = Object.keys(data[0]).map(header => capitalizeFirstLetter(header));
    worksheet.addRow(headers).eachCell((cell) => {
      cell.font = { bold: true };
      cell.alignment = { horizontal: 'left', vertical: 'middle' };
    });
    data.forEach((row) => {
      const newRow = worksheet.addRow(Object.values(row).map(value => capitalizeFirstLetter(value.toString())));
      newRow.eachCell((cell) => {
        cell.alignment = { horizontal: 'left', vertical: 'middle' };
      });
    });
  }
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), `${fileName}.xlsx`);
};


// custome date validation 
 export const validateDate = (dateString) => {
  if (dateString.length === 10) {
    const selectedDate = new Date(dateString);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (isNaN(selectedDate.getTime())) {
      toast.error("Invalid date");
      return false;
    } else if (selectedDate < today) {
      toast.error(" Please add a future date");
      return false;
    } else {
      toast.success("Valid date");
      return true;
    }
  }
  return true;
};
 export const formatCourseNames = (courses) => {
    if (!courses) return "-";
    const courseArray = courses.split(", ");
    return courseArray.length > 1
      ? `${courseArray.slice(0, 1).join(", ")} ...`
      : courses;
  };
// function if status is inactive
export const editRestrict =(title)=>{
return toast.error(`Restore this ${title} first before you can edit it.`)
} 
export { RequireAuthAdmin, AdminLogout, RequireAuthTeacher, TeacherLogout, RequireAuthBranch, BranchLogout, ProtectedRoute, AfterLogin }


