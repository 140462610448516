import React, { useEffect, useState } from "react";
import { validateDate } from "../../../../Utils/Utils";
const AddEvaluation = ({ setEvaluation, testTypeData,CallEvaluationList,setCheckEvaluationValidation }) => {
  const [forms, setForms] = useState([]);
  const today = new Date().toISOString().split("T")[0];
  useEffect(() => {
    const initializeEvaluations = () => {
      const initialEvaluations = [
        {
          test_name: "",
          test_type: "",
          test_kind: "",
          test_no: "",
          test_total_ques: "",
          test_total_time: "",
          tstm_guid: "",
          jwp_media_id: "",
          expiration_date: "",
          status: "",
        },
      ];
      setForms(initialEvaluations);
      setEvaluation(initialEvaluations);
    };

    initializeEvaluations();
  }, [testTypeData, setEvaluation,CallEvaluationList]);

  const handleEvaluationInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedForms = [...forms];
    updatedForms[index] = {
      ...updatedForms[index],
      [name]: value,
    };
    setForms(updatedForms);
    setEvaluation(updatedForms);
    if (name === "expiration_date") {
      const isValidDate = validateDate(value);
      setCheckEvaluationValidation(isValidDate)
    }
  };

  const addMoreEvaluation = () => {
    const newEvaluation = {
      test_name: "",
      test_type: "",
      test_kind: "",
      test_no: "",
      test_total_ques: "",
      test_total_time: "",
      tstm_guid: "",
      jwp_media_id: "",
      expiration_date: "",
      status: "",
    };
    setForms([...forms, newEvaluation]);
    setEvaluation([...forms, newEvaluation]);
  };

  return (
    <div className="accordion" id="accordionExample">
      <div className="modal-body">
        <div className="row table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="text-center">S.NO</th>
                <th>Test Name/Title</th>
                <th>Test Type</th>
                <th>Test Kind</th>
                <th>Test No</th>
                <th>Test Total Questions</th>
                <th>Test Total Time</th>
                <th>TSTM GUID</th>
                <th>JWP Media ID</th>
                <th>Expiration date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {forms.map((evaluation, movieIndex) => (
                <tr>
                  <td>{movieIndex + 1}</td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      name="test_name"
                      value={evaluation.test_name}
                      onChange={(e) =>
                        handleEvaluationInputChange(e, movieIndex)
                      }
                    />
                  </td>
                  <td>
                    <select
                      className="form-select"
                      name="test_type"
                      value={evaluation.test_type}
                      onChange={(e) =>
                        handleEvaluationInputChange(e, movieIndex)
                      }
                    >
                      <option value="">Select Test Type</option>
                      {testTypeData?.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type.test_title}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      className="form-select"
                      name="test_kind"
                      value={evaluation.test_kind}
                      onChange={(e) =>
                        handleEvaluationInputChange(e, movieIndex)
                      }
                    >
                      <option value="">Select test kind</option>
                      <option value="objective">Objective</option>
                      <option value="selective">Subjective</option>
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      name="test_no"
                      value={evaluation.test_no}
                      onChange={(e) =>
                        handleEvaluationInputChange(e, movieIndex)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      name="test_total_ques"
                      value={evaluation.test_total_ques}
                      onChange={(e) =>
                        handleEvaluationInputChange(e, movieIndex)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      name="test_total_time"
                      value={evaluation.test_total_time}
                      onChange={(e) =>
                        handleEvaluationInputChange(e, movieIndex)
                      }
                    />
                  </td>

                  <td>
                    <input
                      type="text"
                      className="form-control"
                      name="tstm_guid"
                      value={evaluation.tstm_guid}
                      onChange={(e) =>
                        handleEvaluationInputChange(e, movieIndex)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      name="jwp_media_id"
                      value={evaluation.jwp_media_id}
                      onChange={(e) =>
                        handleEvaluationInputChange(e, movieIndex)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="date"
                      className="form-control"
                      name="expiration_date"
                      value={evaluation.expiration_date}
                      onChange={(e) =>
                        handleEvaluationInputChange(e, movieIndex)
                      }
                      max="9999-12-31"
                      min={today}
                    />
                  </td>
                  <td>
                    <select
                      className="form-select"
                      name="status"
                      value={evaluation.status}
                      // defaultValue={0}
                      onChange={(e) =>
                        handleEvaluationInputChange(e, movieIndex)
                      }
                    >
                      <option value={0}>Inactive</option>
                      <option value={1}>Active</option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button
          type="button"
          className="evaluation_btn_footer mt-3"
          onClick={() => addMoreEvaluation()}
        >
          Add more
        </button>
      </div>
      {/* </div>
        </div>
      ))} */}
    </div>
  );
};

export default AddEvaluation;
