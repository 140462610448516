import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataLoader from "../../../../Utils/DataLoader.jsx";
import { members_all } from "../../../../apis/studentManagement/StudentManagement";
// import CustomTable from "../../pages/CustomTable";
import { member_destroy } from "../../../../apis/studentManagement/StudentManagement";
import { formatCourseNames, formatDate, getUserPermission } from "../../../../Utils/Utils.js";
import CustomTable from "../../../../componrnt/pages/CustomTable.jsx";
const Student_enroll_list = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const permission = getUserPermission("Students");
  const branchId = localStorage.getItem("b_id");
  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: true,
    },
    {
      name: "Name",
      selector: "first_name",
      sortable: true,
    },
    {
      name: "Grade",
      selector: "grade",
      sortable: true,
    },
    {
      name: "Branch",
      selector: "branch",
      sortable: true,
    },
    {
      name: "Sign In Date",
      selector: "date",
      sortable: true,
    },
    {
      name: "Student type",
      selector: "student_type",
      sortable: true,
    },
    {
      name: "Course",
      selector: "course",
      sortable: true,
    },
    {
      name: "Status",
      selector: "delete_status",
      sortable: true,
    },
    permission?.edit === 1 || permission?.delete === 1 || permission?.view === 1
      ? {
          name: "Action",
          selector: "action",
        }
      : "",
  ];
  useEffect(() => {
    const branch_id = localStorage.getItem("b_id");
    async function get_data() {
      const result = await members_all(branch_id);
      const arrayData = result?.data?.map((elem, index) => {
        const {
          id,
          first_name,
          last_name,
          subject,
          branch_name,
          grade_name,
          user_id,
          status,
          created_at,
          student_type,
          class_id,
          course_name,
          s_id,
        } = elem;
        return {
          no: `#${index + 1}`,
          first_name:
            first_name?.toLowerCase() + " " + last_name?.toLowerCase(),
          grade: `Year ${grade_name}`,
          subject: subject ? subject : "-",
          branch: branch_name,
          student_type: student_type === "1" ? "Enroll" : "Free",
          date: formatDate(created_at),
          status_data: status,
          delete_status: (
            <select
              className="form-select"
              onChange={(e) => destroy(id, e.target.value)}
              value={status}
            >
              <option value={1}>Active</option>
              <option value={0}>Inactive</option>
            </select>
          ),
          class_id: class_id == null ? 0 : 1,
          // course: course_name ? course_name : "-",
          course: (
            <div className="course-container">
              <span className="course-text">
                {formatCourseNames(course_name)}
              </span>
              {course_name.split(", ").length > 1 && (
                <div className="tooltip-content">{course_name}</div>
              )}
            </div>
          ),
          action: (
            <>
              {" "}
              <div data-tag="allowRowEvents">
                {permission?.view === 1 ? (
                  <Link
                    className="edit-btn"
                    to={`/branch/enroll/student_enroll-view/${s_id}`}
                  >
                    <i class="fa fa-eye" aria-hidden="true"></i>
                  </Link>
                ) : (
                  ""
                )}
                {permission?.edit === 1 ? (
                  class_id == 0 ? (
                    <Link
                      className="edit-btn"
                      to={`/branch/enroll/member-edit/${user_id}`}
                    >
                      <i class="fa fa-pencil" aria-hidden="true"></i>
                    </Link>
                  ) : (
                    <button
                      className="dlt-btn"
                      onClick={() => editStudent(class_id, user_id)}
                    >
                      {" "}
                      <i class="fa fa-pencil" aria-hidden="true"></i>
                    </button>
                  )
                ) : (
                  ""
                )}
              </div>
            </>
          ),
        };
      });
      setData(arrayData, setShowResults(true));
    }
    const destroy = async (id, status, name) => {
      const dataa = { status: status };
      const result = window.confirm(
        `${
          status == 1
            ? "Are you sure you want to active this student ?"
            : "Are you sure you want to inactive this student ? "
        }`
      );
      if (result) {
        const res = await member_destroy(id, dataa);
        console.log("res", res);
        if (res?.data) {
          get_data();
        }
      }
      get_data();
    };
    const editStudent = async (class_id, user_id) => {
      if (class_id !== null) {
        const result = window.confirm(
          "This student is already enrolled in the class. Are you sure you want to proceed with editing it?"
        );
        if (!result) {
          return;
        }
      }
      navigate(`/branch/enroll/member-edit/${user_id}`);
    };
    get_data();
  }, []);

  return (
    <div className="right-nav" id="dash-event">
      {showResults ? (
        <>
          <div className="row">
            <div className="col-md-4">
              <h4 className="modal-title">Student List</h4>
            </div>
            <div className="col-md-8">
              {permission?.add === 1 ? (
                <Link
                  className="ui button float-end"
                  to="/branch/free/member-add"
                >
                  Add Student
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* <CustomTable columns={columns} data={data} /> */}
          <CustomTable columns={columns} data={data} search={true} />
        </>
      ) : (
        <DataLoader />
      )}
    </div>
  );
};

export default Student_enroll_list;
