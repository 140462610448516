import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataLoader from "../../../Utils/DataLoader";
import CustomTable from "../CustomTable";
import {
  member_destroy,
  members_all,
} from "../../../apis/studentManagement/StudentManagement";
import { branch_all } from "../../../apis/Branches";
import { formatCourseNames, getUserPermission } from "../../../Utils/Utils";

const Members = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [branchlist, setBranchList] = useState([]);
  const [id, setId] = useState("");
  const permissions = getUserPermission("Students");
  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: true,
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Grade",
      selector: "grade",
      sortable: true,
    },
    {
      name: "Branch",
      selector: "branch",
      sortable: true,
    },
    {
      name: "Sign In Date",
      selector: "created_at",
      sortable: true,
    },
    {
      name: "Student type",
      selector: "student_type",
      sortable: true,
    },
    {
      name: "Course",
      selector: "course",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    permissions?.edit === 1 ||
    permissions?.delete === 1 ||
    permissions?.view === 1
      ? {
          name: "Action",
          selector: "action",
        }
      : "",
  ];
  useEffect(() => {
    async function get_data() {
      const result = await members_all(id);
      const branchList = await branch_all();
      const arrayData = result?.data?.map((elem, index) => {
        const {
          id,
          s_id,
          first_name,
          last_name,
          created_at,
          branch_name,
          grade_name,
          student_number,
          school,
          student_type,
          class_name,
          class_id,
          course_name,
          status,
        } = elem;
        const date = new Date(created_at);
        const month = (date?.getMonth() + 1).toString().padStart(2, "0");
        const day = date?.getDate().toString().padStart(2, "0");
        const year = date?.getFullYear();
        const formattedDate = `${day}-${month}-${year}`;
        const lname = last_name || last_name === "Undefined" ? last_name : "";
        return {
          no: `#${index + 1}`,
          id: `CSPRO00${id}`,
          name: first_name?.toLowerCase() + " " + lname?.toLowerCase(),
          grade:
            grade_name === 0
              ? `Year ${grade_name} (kindy)`
              : `Year ${grade_name}`,
          branch: branch_name ? branch_name : "-",
          school: school,
          contact: student_number || "-",
          created_at: formattedDate?.slice(0, 10),
          student_type: student_type === "1" ? "Enroll" : "Free",
          class_name: class_name,
          class_id: class_id == null ? 0 : 1,
          course: (
            <div className="course-container">
              <span className="course-text">
                {formatCourseNames(course_name)}
              </span>
              {course_name.split(", ").length > 1 && (
                <div className="tooltip-content">{course_name}</div>
              )}
            </div>
          ),
          status_data: status,
          status: (
            <select
              className="form-select"
              onChange={(e) => destroy(id, e.target.value)}
              value={status}
            >
              <option value={1}>Active</option>
              <option value={0}>Inactive</option>
            </select>
          ),
          action: (
            <div data-tag="allowRowEvents">
              {permissions?.view === 1 ? (
                <Link
                  className="edit-btn"
                  to={`/admin/students/student_enroll_view/${s_id}`}
                >
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </Link>
              ) : (
                ""
              )}
              {/* {student_type == '1' ? <Link className='edit-btn' to={`/admin/students/student_enroll_view/${s_id}`}>View</Link> : ''} */}
              {permissions?.edit === 1 ? (
                class_id == 0 ? (
                  <Link
                    className="edit-btn"
                    to={`/admin/students/member-edit/${id}`}
                  >
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </Link>
                ) : (
                  <button
                    className="dlt-btn"
                    onClick={() => editStudent(class_id, id)}
                  >
                    {" "}
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                )
              ) : (
                ""
              )}
            </div>
          ),
        };
      });
      setData(arrayData, setShowResults(true));
      setBranchList(branchList?.data);
    }
    const destroy = async (id, status, first_name) => {
      const dataa = {
        status: status,
      };
      const result = window.confirm(
        `${
          status === 1
            ? "Are you sure you want to active this user ?"
            : "Are you sure you want to  inactive this user ? "
        }`
      );
      if (result) {
        const res = await member_destroy(id, dataa);
        if (res?.data) {
          get_data();
        }
      }
      get_data();
    };
    const editStudent = async (class_id, id) => {
      if (class_id !== null) {
        const result = window.confirm(
          "This student is already enrolled in the class. Are you sure you want to proceed with editing it?"
        );
        if (!result) {
          return;
        }
      }
      navigate(`/admin/students/member-edit/${id}`);
    };
    get_data();
  }, [id]);
  return (
    <div className="right-nav" id="dash-event">
      {showResults ? (
        <>
          <div className="row">
            <div className="col-md-4">
              <h4 className="modal-title">Student List</h4>
            </div>
            <div className="col-md-8">
              {permissions?.add === 1 ? (
                <Link
                  className="ui button float-end"
                  to="/admin/students/add-member"
                >
                  Add Student
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label>Branch</label>
              <select
                className="form-select form-control "
                name="branch"
                onChange={(e) => setId(e.target.value)}
              >
                <option value="">CHOOSE BRANCH</option>
                {branchlist?.length > 0 ? (
                  branchlist &&
                  branchlist?.map((elem) => {
                    return (
                      <option value={elem?.id}>{elem?.branch_name}</option>
                    );
                  })
                ) : (
                  <option>No result</option>
                )}
              </select>
            </div>
          </div>
          <CustomTable columns={columns} data={data} />
        </>
      ) : (
        <DataLoader />
      )}
    </div>
  );
};
export default Members;
