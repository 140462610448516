import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { day_update, days_edit } from '../../../apis/Common';
import { Button } from 'semantic-ui-react';
const EditDays = ({ isModalOpen, setIsModalOpen, duplicateItemId, setStatus }) => {
    // const [checkValidation, setCheckValidation] = useState(true);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset
    } = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();
    const onSubmit = async (data) => {
        if (isSubmitting) return; 
        setIsSubmitting(true);
        // if (!checkValidation) {
        //     console.log('invalid');
        // }
        // else {
        let result = await day_update(duplicateItemId, data);
        if (result?.status) {
            setIsModalOpen(false); setStatus("")
            window.location.reload();
            return navigate('/admin/day/all');
        }
        setIsSubmitting(false);
    }

    // }
    useEffect(() => {
        async function get_data() {
            const result = await days_edit(duplicateItemId);
            if (result?.data?.length > 0) {
                const { name, status } = result?.data[0];
                console.log('s',status,typeof status);
                
                setValue('name', name);
                setValue('status', status.toString());
            }
        }
        if (isModalOpen) {
            get_data();
        }
    }, [isModalOpen, duplicateItemId])

    useEffect(() => {
        return (
            setIsModalOpen(false)
        )
    }, []);
    // const regexPattern = /^term\s?(1[0-2]|[1-9])$/;
    // const checkregex = (name) => {
    //     if (regexPattern.test(name)) {
    //         console.log("Valid string");
    //         setCheckValidation(true);
    //     } else {
    //         setCheckValidation(false);
    //     }
    // }
    return (
        <>
            <div className={`modal fade ${isModalOpen ? 'show' : ''}`} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden={isModalOpen}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="mx-auto modal-title mt-2" id="staticBackdropLabel">Edit Day</h5>
                            <button onClick={() => { setIsModalOpen(false); setStatus("") }} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form className="form-horizontal"
                                onSubmit={handleSubmit(onSubmit)} >
                                <div className='row'>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Day Name *</label>
                                            <input type="text" className="form-control"
                                                placeholder='Enter Day Name'
                                                {...register('name', { required: true })}
                                            />
                                        </div>
                                        {errors.name && errors.name.type === "required" && <span className="validation-error">This is required</span>}
                                        {/* {checkValidation ? '' : <span className="validation-error">Term name should be term and number between 0 to 12</span>} */}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>status</label>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" {...register('status')} id="inlineRadio1" value="1" />
                                                <label class="form-check-label" for="inlineRadio1">Active</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" {...register('status')} id="inlineRadio2" value="0" />
                                                <label class="form-check-label" for="inlineRadio2">Inactive</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <Button type='submit' className="btn button" disabled={isSubmitting}>Save</Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
export default EditDays;

