import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataLoader from "../../../../Utils/DataLoader";
import { all_States, destroy_state } from "../../../../apis/States";
import CustomTable from "../../CustomTable";
import { editRestrict, getUserPermission } from "../../../../Utils/Utils";

const AllStates = () => {
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const permissions = getUserPermission("State");
  const UserID = JSON.parse(localStorage.getItem("userData"))?.data?.id;
  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: true,
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    permissions?.edit === 1 || permissions?.delete === 1
      ? {
          name: "Action",
          selector: "action",
        }
      : "",
  ];
  useEffect(() => {
    async function get_data() {
      const result = await all_States();
      const arrayData = result?.data?.map((elem, index) => {
        const { id, name, delete_status, status } = elem;
        return {
          no: `#${index + 1}`,
          name: name,
          status_data : status,
          status: (
            <select
              className="form-select"
              onChange={(e) => destroy("status", id, e.target.value)}
              value={status}
            >
              <option value={1}>Active</option>
              <option value={0}>Inactive</option>
            </select>
          ),
          action: (
            <div data-tag="allowRowEvents">
              {permissions?.edit === 1 ? (
                <Link
                  class="edit-btn"
                  to={`${`/admin/administrator-setting/state/edit/${id}`
                  }`}
                >
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </Link>
              ) : (
                ""
              )}
            </div>
          ),
        };
      });
      setData(arrayData, setShowResults(true));
    }
    const destroy = async (type, id, status, name) => {
      const status_payload = { status: status };
      const delete_status_payload = {
        delete_status: status,
        title: name,
        deleted_by: UserID,
      };
      const result = window.confirm(
        `${
          status == 1
            ? `Are you sure you want to ${
                type == "status" ? "active" : "delete"
              } this state ?`
            : `Are you sure you want to ${
                type == "status" ? "inactive" : "restore"
              } this state ?`
        }`
      );
      if (result) {
        await destroy_state(
          id,
          type == "status" ? status_payload : delete_status_payload
        );
      }
      get_data();
    };
    get_data();
  }, []);
  return (
    <div className="right-nav" id="dash-event">
      {showResults ? (
        <>
          <div className="row">
            <div className="col-md-4">
              <h4 className="modal-title">State List</h4>
            </div>
            <div className="col-md-8">
              {permissions?.add === 1 ? (
                <Link
                  className="ui button float-end"
                  to="/admin/administrator-setting/state/add"
                >
                  Add State
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
          <CustomTable columns={columns} data={data} />
        </>
      ) : (
        <DataLoader />
      )}
    </div>
  );
};
export default AllStates;
