import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Type_test_edit,
  Type_test_update,
} from "../../../../apis/generalSetting/testType";
import { Button } from "semantic-ui-react";
import { handleNavigation } from "../../../../Utils/Utils";
const EditType_test = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const id = useParams();
  const onSubmit = async (data) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    let result = await Type_test_update(id,data);
    if (result.status === true) {
      return navigate("/admin/general-setting/add-test-type/all");
    }
    setIsSubmitting(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      const data = await Type_test_edit(id);
      setValue('name',data?.data?.[0]?.name)
      setValue('status',data?.data?.[0]?.status.toString())
    };
    fetchData();
  }, []);
  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <div className="modal-header">
          <div className="col-md-6">
            <h4 className="modal-title">Edit Test Type</h4>
          </div>
          <div className="col-md-2 text-end">
            <div className="modify-right">
              <Link
                className="edit-btn"
                to={`/admin/general-setting/add-test-type/all`}
                onClick={(e) => {
                  handleNavigation(e, isDirty);
                }}
              >
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
        </div>
        <br />
        <div
          className="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Test type *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter test type"
                    {...register("name", {
                      required: "This is required",
                      validate: (value) =>
                        value.trim() !== "" ||
                        "This should not contain blank spaces", 
                    })}
                  />
                  {errors.name && (
                    <span className="validation-error">
                      {errors.name.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Status</label>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      {...register("status", { required: true })}
                      id="inlineRadio1"
                      value="1"
                      defaultChecked
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      Active
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      {...register("status", { required: true })}
                      id="inlineRadio2"
                      value="0"
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      Inactive
                    </label>
                  </div>
                {errors.status && errors.status.type === "required" && (
                  <span className="validation-error">This is required</span>
                )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                type="submit"
                className="btn button"
                disabled={isSubmitting}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export { EditType_test };
