import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import DataLoader from "../../../Utils/DataLoader";
import CustomTable from "../CustomTable";
import { teacher_by_branch, teacher_destroy } from "../../../apis/Teachers";
import { editRestrict, getUserPermission } from "../../../Utils/Utils";

const AllTeachers = () => {
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const id = useParams();
  const permissions = getUserPermission("Teachers");
  const UserID = JSON.parse(localStorage.getItem("userData"))?.data?.id;
  const columns = [
    {
      name: "No.",
      selector: "no",
      style: {
        width: "200px",
      },
    },
    {
      name: "Branch",
      selector: "branch",
      sortable: true,
    },
    {
      name: "TeacherId",
      selector: "teacher_id",
      sortable: true,
    },
    {
      name: "Teacher Name",
      selector: "teacher_name",
      sortable: true,
    },
    {
      name: "Mobile",
      selector: "mobile",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    permissions?.edit === 1 || permissions?.delete === 1
      ? {
          name: "Action",
          selector: "action",
        }
      : "",
  ];
  useEffect(() => {
    async function get_data() {
      const result = await teacher_by_branch();
      const arrayData = result?.data?.map((elem, index) => {
        const {
          id,
          branch_name,
          teacher_id,
          teacher_name,
          mobile,
          delete_status,
          user_id,
          status,
        } = elem;
        return {
          no: `#${index + 1}`,
          branch: branch_name?.toLowerCase(),
          teacher_id: teacher_id,
          teacher_name: teacher_name.toLowerCase(),
          mobile: mobile,
          status_data : status,
          status: (
            <select
              className="form-select"
              onChange={(e) => destroy("status", id, e.target.value)}
              value={status}
            >
              <option value={1}>Active</option>
              <option value={0}>Inactive</option>
            </select>
          ),
          action: (
            <div data-tag="allowRowEvents">
              {permissions?.edit === 1 ? (
                <Link
                  className="edit-btn"
                  to={`${
                    delete_status === 1
                      ? `/admin/teachers-management/edit/${id}`
                      : "#"
                  }`}
                  onClick={() =>
                    delete_status == 0 ? editRestrict("teacher") : ""
                  }
                >
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </Link>
              ) : (
                ""
              )}
            </div>
          ),
        };
      });
      setData(arrayData, setShowResults(true));
    }
    const destroy = async (type,id, status, name) => {
      const status_payload = { status: status };
      const delete_status_payload = {
        delete_status: status,
        title: name,
        deleted_by: UserID,
      };
      const result = window.confirm(
        `${
          status ==  1
            ? `Are you sure you want to ${
                type == "status" ? "active" : "restore"
              } this Teacher ?`
            : `Are you sure you want to ${
                type == "status" ? "inactive" : "delete"
              } this Teacher ?`
        }`
      );
      if (result) {
        await teacher_destroy(id,type == "status" ? status_payload : delete_status_payload);
      }
      get_data();
    };
    get_data();
  }, []);
  return (
    <div className="right-nav" id="dash-event">
      {showResults ? (
        <>
          <div className="row">
            <div className="col-md-4">
              <h4 className="modal-title">Teacher List</h4>
            </div>
            <div className="col-md-8">
              {permissions?.add === 1 ? (
                <Link
                  className="ui button float-end"
                  to="/admin/teachers-management/add/"
                >
                  Add Teacher
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
          <CustomTable columns={columns} data={data} />
        </>
      ) : (
        <DataLoader />
      )}
    </div>
  );
};
export default AllTeachers;
