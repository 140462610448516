import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  branch_edit,
  branch_update,
  checkForEnv,
} from "../../../apis/Branches";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { random_generate_string } from "../../../Utils/Utils";
import { Button } from "semantic-ui-react";
import { handleNavigation } from "../../../Utils/Utils";
import { get_states } from "../../../apis/Common";
const EditBrance = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [address, setAddress] = useState(null);
  const [randomText, setRandomText] = useState("");
  const [conPassEye, setConPassEye] = useState(false);
  const [statelist, setStateList] = useState([]);
  const [isVisible, setVisible] = useState(false);
  const [ApiAddress, setApiAddress] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [changesPassword, setChangePassword] = useState(false);
  const [state, setState] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const navigate = useNavigate();
  const toggle = () => {
    setVisible(!isVisible);
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm();
  const text_relaod = (e) => {
    setRandomText(random_generate_string(8));
  };
  const handleAddressChange = (selectedAddress) => {
    setAddress(selectedAddress);
  };
  const id = useParams();
  const fetchData = async () => {
    let res = await branch_edit(id);
    setApiAddress(res?.data?.branch_addresss);
    setValue("branch_code", res?.data?.branch_code);
    setValue("branch_name", res?.data?.branch_name);
    setValue("branch_addresss", res?.data?.branch_addresss);
    setValue("first_name", res?.data?.first_name);
    setValue("last_name", res?.data?.last_name);
    setState(res?.data?.branch_state);
    setAddress({
      label: res?.data?.branch_addresss,
      value: res?.data?.branch_addresss,
    });
    setValue("Branch_contact_number", res?.data?.Branch_contact_number);
    setValue("api_key", res?.data?.api_key);
    setValue("secret_key", res?.data?.secret_key);
    setTimeout(()=>{
      setValue("status", res?.data?.status);
    },1000)
    setValue("email", res?.data?.email);
    setValue("password", res?.data?.password);
    setValue("branch_address_2", "");

    setTimeout(() => {
      setValue("branch_state", res?.data?.branch_state);
    }, 700); // Fixed timeout
  };

  useEffect(() => {
    fetchData();
  }, [id]); // Ensure it runs when 'id' changes

  useEffect(() => {
    const fetch = async () => {
      const stateData = await checkForEnv(state);
      setShowButton(stateData?.status);
    };
    if (state && state !== "false") {
      fetch();
    }
  }, [state]);
  const onSubmit = async (data) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    if (currentStep === 2) {
      data.roles = "Branches";
      data.branch_addresss = address ? address.label : ApiAddress;
      if (!changesPassword) {
        data.password = "";
      }
      // return
      let result = await branch_update(id, data);
      if (result.status) {
        return navigate("/admin/administrator-setting/cse-brances");
      }
    } else {
      // Move to the next step
      setCurrentStep(currentStep + 1);
    }
    setIsSubmitting(false);
  };
  if (address?.value?.place_id) {
    geocodeByPlaceId(address.value.place_id)
      .then((results) => {
        if (results.length === 0) return;
        const zipCode = results[0]?.address_components?.find((component) =>
          component.types.includes("postal_code")
        );
        setValue("branch_addresss", `${address.label}, ${zipCode?.long_name || ""}`);
      })
      .catch((error) => console.error("Geocoding Error:", error));
  }
  
  useEffect(() => {
    // Load Google Maps API script
    const loadGoogleMapsScript = () => {
      if (!window.google) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDrSVPFOzjxmlv2WLSnfJ0ARrmYP9QDajs&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = () => setScriptLoaded(true);
        script.onerror = () => console.error('Google Maps script failed to load');
        document.head.appendChild(script);
      } else {
        setScriptLoaded(true);
      }
    };

    loadGoogleMapsScript();

    return () => {
      // Cleanup if needed
    };
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const stateList = await get_states();
      setStateList(stateList?.data);
    };
    fetchData();
  }, []);
  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <div className="modal-header">
          <div className="col-md-6">
            <h4 className="modal-title">Edit Branch</h4>
          </div>
          <div className="col-md-6 text-end">
            <div className="modify-right">
              <Link
                className="edit-btn"
                to={`/admin/administrator-setting/cse-brances`}
                onClick={(e) => {
                  handleNavigation(e, isDirty);
                }}
              >
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="modal-body">
          <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${currentStep === 1 ? "active " : ""}`}
                  type="button"
                >
                  Branch Info
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${currentStep === 2 ? "active " : ""}`}
                  type="button"
                >
                  Branch Admin Info
                </button>
              </li>
              {/* <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${currentStep === 3 ? "active " : ""}`}
                  type="button"
                >
                  Paypal Payment Information
                </button>
              </li> */}
            </ul>
            <div className="tab-content" id="pills-tabContent">
              {currentStep === 1 && (
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Branch Code *</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Branch Code"
                          {...register("branch_code", { required: true })}
                          disabled
                        />
                        {errors.branch_code &&
                          errors.branch_code.type === "required" && (
                            <span className="validation-error">
                              This is required
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <labell>Branch Name *</labell>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Branch Name"
                          {...register("branch_name", { required: true })}
                        />
                        {errors.branch_name &&
                          errors.branch_name.type === "required" && (
                            <span className="validation-error">
                              This is required
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>State *</label>
                        <select
                          className="form-select form-control"
                          {...register("branch_state", { required: true })}
                          onChange={(e) => setState(e.target.value)}
                        >
                          {statelist?.length > 0
                            ? [
                                <option value="">Choose State</option>,
                                statelist?.map((elem) => {
                                  return (
                                    <option value={elem?.id}>
                                      {elem?.name}
                                    </option>
                                  );
                                }),
                              ]
                            : [<option value="">State not available</option>]}
                        </select>
                        {errors.branch_state &&
                          errors.branch_state.type === "required" && (
                            <span className="validation-error">
                              This is required
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Branch Addresss *</label>
                        { scriptLoaded ? (
                          <>
                            <GooglePlacesAutocomplete
                              className="form-control p-2 h-100"
                              name="address"
                              apiKey="AIzaSyDrSVPFOzjxmlv2WLSnfJ0ARrmYP9QDajs"
                              options={{
                                types: ["geocode", "establishment"],
                              }}
                              selectProps={{
                                value: address,
                                onChange: (selected) => {
                                  setAddress(selected);
                                  setValue("branch_addresss", selected.label);
                                  clearErrors("branch_addresss");
                                },
                                placeholder: "Enter you branch address",
                              }}
                            />
                            <input
                              type="hidden"
                              {...register("branch_addresss", {
                                required: true,
                              })}
                            />

                            {errors.branch_addresss && (
                              <span className="validation-error">
                                This is required
                              </span>
                            )}
                          </>
                           ) : (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Loading address autocomplete..."
                              disabled
                            />
                           )
                        }
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Branch Contact Number *</label>
                        <div className="input-group branch-prepend">
                          <div className="input-group-prepend">
                            <div className="input-group-text">+61</div>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="04XX XXX XXX"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            {...register("Branch_contact_number", {
                              required: "Phone number is required",
                              pattern: {
                                value: /^0[2-8]\d{8}$|^04\d{2}\s\d{3}\s\d{3}$/,
                                message:
                                  "Enter a valid Australian phone number (e.g., 04XX XXX XXX or 0X XXXX XXXX)",
                              },
                              validate: {
                                isNumeric: (value) =>
                                  value === "" ||
                                  /^\d+$/.test(value.replace(/\s+/g, "")) ||
                                  "Phone number must contain only numbers",
                              },
                            })}
                            maxLength={10}
                          />
                        </div>
                        {errors.Branch_contact_number && (
                          <span className="validation-error">
                            {errors.Branch_contact_number.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Status</label>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            {...register("status", { required: true })}
                            id="inlineRadio1"
                            value='1'
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio1"
                          >
                            Active
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            {...register("status", { required: true })}
                            id="inlineRadio2"
                            value='0'
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio2"
                          >
                            Inactive
                          </label>
                        </div>
                        {errors.status && errors.status.type === "required" && (
                          <span className="validation-error">
                            This is required
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {currentStep === 2 && (
                <div
                  className="tab-pane fade show active"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>First Name *</label>
                        <input
                          type="text"
                          placeholder="Enter First Name"
                          className="form-control"
                          {...register("first_name", { required: true })}
                        />
                        {errors.first_name &&
                          errors.first_name.type === "required" && (
                            <span className="validation-error">
                              This is required
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Last Name *</label>
                        <input
                          type="text"
                          placeholder="Enter Last Name"
                          className="form-control"
                          {...register("last_name", { required: true })}
                        />
                        {errors.last_name &&
                          errors.last_name.type === "required" && (
                            <span className="validation-error">
                              This is required
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Email *</label>
                        <input
                          type="text"
                          placeholder="Enter Email"
                          className="form-control"
                          {...register("email", {
                            required: "This is required",
                            pattern: {
                              value:
                                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                              message: "Please enter a valid email address",
                            },
                          })}
                        />
                        {errors.email && (
                          <span className="validation-error">
                            {errors.email.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="relod_cont">
                        <label>Password *</label>
                        <div className="password-sec">
                          {changesPassword ? (
                            <>
                              <input
                                type={!conPassEye ? "password" : "text"}
                                className="form-control"
                                placeholder="Enter Password"
                                // autoComplete='off'
                                {...register("password", { required: true })}
                                // value={randomText}
                              />
                              <svg
                                onClick={() => setConPassEye(!conPassEye)}
                                className="eyes"
                                width="18"
                                height="14"
                                viewBox="0 0 18 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.99922 1.5C6.96172 1.5 5.28672 2.425 4.00234 3.61562C2.79922 4.73438 1.96797 6.0625 1.54297 7C1.96797 7.9375 2.79922 9.26562 3.99922 10.3844C5.28672 11.575 6.96172 12.5 8.99922 12.5C11.0367 12.5 12.7117 11.575 13.9961 10.3844C15.1992 9.26562 16.0305 7.9375 16.4555 7C16.0305 6.0625 15.1992 4.73438 13.9992 3.61562C12.7117 2.425 11.0367 1.5 8.99922 1.5ZM2.98047 2.51875C4.45234 1.15 6.47422 0 8.99922 0C11.5242 0 13.5461 1.15 15.018 2.51875C16.4805 3.87812 17.4586 5.5 17.9242 6.61562C18.0273 6.8625 18.0273 7.1375 17.9242 7.38437C17.4586 8.5 16.4805 10.125 15.018 11.4812C13.5461 12.85 11.5242 14 8.99922 14C6.47422 14 4.45234 12.85 2.98047 11.4812C1.51797 10.125 0.539844 8.5 0.0773437 7.38437C-0.0257813 7.1375 -0.0257813 6.8625 0.0773437 6.61562C0.539844 5.5 1.51797 3.875 2.98047 2.51875ZM8.99922 9.5C10.3805 9.5 11.4992 8.38125 11.4992 7C11.4992 5.61875 10.3805 4.5 8.99922 4.5C8.97734 4.5 8.95859 4.5 8.93672 4.5C8.97734 4.65938 8.99922 4.82812 8.99922 5C8.99922 6.10313 8.10234 7 6.99922 7C6.82734 7 6.65859 6.97813 6.49922 6.9375C6.49922 6.95937 6.49922 6.97813 6.49922 7C6.49922 8.38125 7.61797 9.5 8.99922 9.5ZM8.99922 3C10.0601 3 11.0775 3.42143 11.8276 4.17157C12.5778 4.92172 12.9992 5.93913 12.9992 7C12.9992 8.06087 12.5778 9.07828 11.8276 9.82843C11.0775 10.5786 10.0601 11 8.99922 11C7.93835 11 6.92094 10.5786 6.17079 9.82843C5.42065 9.07828 4.99922 8.06087 4.99922 7C4.99922 5.93913 5.42065 4.92172 6.17079 4.17157C6.92094 3.42143 7.93835 3 8.99922 3Z"
                                  fill="#888888"
                                />
                              </svg>
                              {/* <div onClick={() => text_relaod()} className="btn btn-primary generate-pasword">generate password</div> */}
                              {/* <span className="show-hide" onClick={toggle}>
                                                {isVisible ? <span  >hide</span> : <span>show</span>}
                                            </span> */}
                              {errors.password &&
                                errors.password.type === "required" && (
                                  <span className="validation-error">
                                    This is required
                                  </span>
                                )}
                            </>
                          ) : (
                            <input
                              className="form-control"
                              placeholder="Enter Password"
                              value="***********"
                              disabled
                            />
                          )}
                          <p
                            onClick={() => setChangePassword(!changesPassword)}
                          >
                            Change Password
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* {currentStep === 3 && (
                <div
                  className="tab-pane fade show active"
                  id="pills-contact"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <labell>Api key</labell>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter API KEY"
                          {...register("api_key")}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <labell>Secret key</labell>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter SECRET KEY"
                          {...register("secret_key")}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
            </div>
            <div className="modal-footer">
              {currentStep > 1 && (
                <Button
                  type="button"
                  className="btn button"
                  onClick={() => setCurrentStep(currentStep - 1)}
                >
                  Previous
                </Button>
              )}
              {currentStep < 2 && showButton && (
                <Button type="submit" className="btn button">
                  Next
                </Button>
              )}
              {currentStep === 2 && (
                <Button
                  type="submit"
                  className="btn button"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export { EditBrance };
