import React, { useCallback, useState } from "react";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import Switch from "react-switch";
const CustomTable = ({
  columns,
  data,
  search,
  type,
  isAllActiveFilterNotReq 
}) => {
  const [searchData, setSeacrhData] = useState("");
  const [checked , setChecked] = useState(0) ;
  // const onSearch = (value, sortByKey="asc" , allData , isAlldata) => {
  // //  setSeacrhData(value);
  //   debugger
  
  //   const searchRes = allData.filter((o) =>
  //     !isAlldata? o.status_data ==1 &&
  //     Object.keys(o).some((k) =>
  //       String(o[k]).toLowerCase().includes(value.toLowerCase())
  //     ) :Object.keys(o).some((k) =>
  //       String(o[k]).toLowerCase().includes(value.toLowerCase()))
  //   );
  //   // setFilterData(searchRes);
  //   const sortedData = searchRes.sort((a, b) => {
  //     const aValue = a[sortByKey]?.toLowerCase();
  //     const bValue = b[sortByKey]?.toLowerCase();

  //     if (aValue < bValue) return -1;
  //     if (aValue > bValue) return 1;
  //     return 0;
  //   });
  //   //setFilterData(sortedData);
  //   return sortedData
  // };
  
  const isDataInRow = (rowData)=>{
      //if (!value) return allData; // If no value, return all data immediately
      const lowerCaseValue = searchData.toLowerCase();
    return Object.keys(rowData).some((k) =>
      String(rowData[k]).toLowerCase().includes(lowerCaseValue)
    )
  }

  const onSearch = useCallback((value, sortByKey = "asc", allData, isActiveDataOnly) => {
    if(!isActiveDataOnly && !searchData ){
      return allData
    }
    let BothFilters =  isActiveDataOnly && searchData 
    const searchRes = allData.filter((o) =>
      BothFilters ? o?.status_data == 1 &&isDataInRow(o) 
           :isActiveDataOnly ?  o.status_data == 1: isDataInRow(o) 
    );
    const sortedData = searchRes.sort((a, b) => {
      const aValue = String(a[sortByKey] || "").toLowerCase();
      const bValue = String(b[sortByKey] || "").toLowerCase();
  
      return aValue.localeCompare(bValue);
    });
  
    return sortedData;
  }, [data, searchData , checked]);

  const customStyles = {
    table: {
      style: {
        tableLayout: "fixed", // Ensure the table layout is fixed
      },
    },
    headRow: {
      style: {
        backgroundColor: "#f1f1f1",
        color: "#333",
        fontSize: "16px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        paddingTop: "10px",
        paddingBottom: "10px",
        fontWeight: "bold",
      },
    },
    rows: {
      style: {
        minHeight: "40px",
        paddingTop: "0px",
        paddingBottom: "0px",
        backgroundColor: "#fff",
        "&:nth-child(odd)": {
          backgroundColor: "#f9f9f9",
        },
      },
    },
    cells: {
      style: {
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },
    tableBody: {
      style: {
        maxHeight: "400px",
        overflowY: "auto",
        display: "block",
      },
    },
  };

  return (
    <>
     
        <div className="searc-bar">
          <div className="row">
          {search === false? "": <div className={ "col-md-6"}>
              <input
                type="search"
                className="form-control  float-end"
                placeholder="Search here..."
                value={searchData}
                onChange={(e) => setSeacrhData(e.target.value)}
              />
            </div>
            }
            {/* {searchData &&
        <div onClick={()=>setSeacrhData('')}><i class="fa-solid fa-xmark"></i></div>
       } */}
            
            { isAllActiveFilterNotReq !=true ? <div className={search === false?"col-md-12 text-end":"col-md-6 text-end"}>
            <div className="filter_active">
                <p>filter</p>
                <Switch
                  checked={checked}
                  onChange={()=>{setChecked(prev =>prev ==0?1:0)}}
                  onColor="#EE3124"
                  offColor="#EE3124"
                  onHandleColor="#ffffff"
                  offHandleColor="#ffffff"
                  handleDiameter={36}
                  uncheckedIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 15,
                        color: "white",
                        paddingLeft: 1,
                        marginRight:12
                      }}
                    >
                      All
                    </div>
                  }
                  checkedIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 15,
                        color: "white",
                        paddingLeft:15,
                        paddingRight:15
                      }}
                    >
                      Active
                    </div>
                  }
                  uncheckedHandleIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 20,
                        // marginLeft:10
                      }}
                    >
                      ✔
                    </div>
                  }
                  checkedHandleIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        color: "#EE3124",
                        fontSize: 18,
                      }}
                    >
                      ✔
                    </div>
                  }
                  height={40}
                  width={90}
                  className="react-switch"
                  id="material-switch"
                />
                </div>
              </div> : ""}
            
          </div>
        </div>
      
      <DataTable
        noHeader
        columns={columns}
        data={data?.length > 0  ?onSearch(searchData,"asc" , data , checked):data}
        defaultSortField="id"
        defaultSortAsc={true}
        pagination
        paginationPerPage={10}
        sortMode="multiple"
        highlightOnHover
        customStyles={customStyles}
      />
    </>
  );
};

export default CustomTable;
