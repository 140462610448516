import React, { useEffect, useState } from "react";
const AddSelfStudy = ({ setSelfStudy, movieTypes, selfStudy }) => {
  const [forms, setForms] = useState(selfStudy || []);
  useEffect(() => {
    setForms(selfStudy);
  }, [selfStudy]);
  // useEffect(() => {
  //   if (forms.length === 0) {
  //     const totalUnits = parseInt(
  //       localStorage.getItem("totalUnits") || "1",
  //       10
  //     );
  //     const initialForms = Array.from({ length: totalUnits }, (_, index) => ({
  //       unit_id: index + 1,
  //       selfDir: movieTypes.map((movieType) => ({
  //         movie_type: "",
  //         title: "",
  //         file_path: "",
  //         jwp_media: "",
  //       })),
  //     }));
  //     setForms(initialForms);
  //     setSelfStudy(initialForms);
  //   }
  // }, [movieTypes]);

  useEffect(() => {
    const totalUnits = parseInt(localStorage.getItem("totalUnits") || "1", 10);
  
    if (forms.length === 0) {
      // Initialize when no previous data exists
      const initialForms = Array.from({ length: totalUnits }, (_, index) => ({
        unit_id: index + 1,
        selfDir: movieTypes?.map(() => ({
          movie_type: "",
          title: "",
          file_path: "",
          jwp_media: "",
        })) || [],
      }));
  
      setForms(initialForms);
      setSelfStudy(initialForms);
    } else {
      // Update forms to match new movieTypes while preserving previous inputs
      const updatedForms = forms.map((unit) => ({
        ...unit,
        selfDir: movieTypes?.map((_, i) => ({
          movie_type: unit.selfDir[i]?.movie_type || "",
          title: unit.selfDir[i]?.title || "",
          file_path: unit.selfDir[i]?.file_path || "",
          jwp_media: unit.selfDir[i]?.jwp_media || "",
        })) || [],
      }));
  
      setForms(updatedForms);
      setSelfStudy(updatedForms);
    }
  }, [movieTypes, setSelfStudy]);
  
  const handleSelfStudyInputChange = (e, unitIndex, selfDirIndex) => {
    const { name, value } = e.target;
    const updatedForms = [...forms];
    updatedForms[unitIndex].selfDir[selfDirIndex][name] = value;
    setForms(updatedForms);
    setSelfStudy(updatedForms);
  };
  return (
    <div className="accordion" id="accordionExample">
      {forms.map((form, i) => (
        <div key={i} className="accordion-item">
          <h2 className="accordion-header" id={`heading-${i}`}>
            <button
              className={`accordion-button ${i === 0 ? "" : "collapsed"}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse-${i}`}
              aria-expanded={i === 0}
              aria-controls={`collapse-${i}`}
            >
              Unit {i + 1}
            </button>
          </h2>
          <div
            id={`collapse-${i}`}
            className={`accordion-collapse collapse ${i === 0 ? "show" : ""}`}
            aria-labelledby={`heading-${i}`}
            data-bs-parent="#accordionExample"
          >
            <div className="modal-body">
              <div className="row">
                <input type="hidden" name="unit_id" value={form.unit_id} />
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Movie Type</th>
                      <th>Title</th>
                      <th>File Path</th>
                      <th>JWP Media</th>
                    </tr>
                  </thead>
                  <tbody>
                    {form.selfDir.map((selfDir, movieIndex) => (
                      <tr key={`${form.unit_id}-${movieIndex}`}>
                        <td className="text-center">{movieIndex + 1}</td>
                        <td>
                          <select
                            className="form-select"
                            name="movie_type"
                            value={selfDir.movie_type}
                            onChange={(e) =>
                              handleSelfStudyInputChange(e, i, movieIndex)
                            }
                          >
                            <option value="">Select Type</option>
                            {movieTypes.map((type) => (
                              <option key={type.id} value={type.id}>
                                {type.file_type}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="title"
                            value={selfDir.title}
                            onChange={(e) =>
                              handleSelfStudyInputChange(e, i, movieIndex)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="file_path"
                            value={selfDir.file_path}
                            onChange={(e) =>
                              handleSelfStudyInputChange(e, i, movieIndex)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="jwp_media"
                            value={selfDir.jwp_media}
                            onChange={(e) =>
                              handleSelfStudyInputChange(e, i, movieIndex)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AddSelfStudy;
