import React, { useEffect, useRef, useState } from "react";
import { Form, Button } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  member_edit,
  member_update,
} from "../../../apis/studentManagement/StudentManagement";
import {
  branch_all,
  course_type_all,
  state_branch_all,
} from "../../../apis/Branches";
import { grade_all } from "../../../apis/Grade";
import {
  get_course_terms,
  get_days,
  get_states,
  get_terms_by_state,
} from "../../../apis/Common";
import DataLoader from "../../../Utils/DataLoader";
import { handleNavigation } from "../../../Utils/Utils.js";
const EditMember = () => {
  const [gradelist, setGradeList] = useState([]);
  const [branchlist, setBranchList] = useState([]);
  const [statelist, setStateList] = useState([]);
  const [coursetypeList, setCoursetypeList] = useState([]);
  const [days, setDays] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [termData, setTermData] = useState([]);
  const [gradeID, setGradeId] = useState("");
  const [stateID, setStateId] = useState("");
  const [courseID, setCourseID] = useState("");
  //mulitle course
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [show, setShow] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isDirty },
    watch,
  } = useForm({ mode: "onChange" });

  const navigate = useNavigate();
  const password = useRef({});
  const classroom_gmail = useRef({});
  const p_email = useRef({});
  const id = useParams();
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");

  useEffect(() => {
    const today = new Date();
    const maxDateCalc = new Date(
      today.getFullYear() - 0,
      today.getMonth(),
      today.getDate()
    );
    const minDateCalc = new Date(today.getFullYear() - 20, 0, 2);
    setMaxDate(maxDateCalc.toISOString().split("T")[0]);
    setMinDate(minDateCalc.toISOString().split("T")[0]);
  }, []);
  password.current = watch("password", "");
  classroom_gmail.current = watch("classroom_gmail", "");
  p_email.current = watch("p_email", "");

  const onSubmit = async (data) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    data.course = selectedCourse;
    data.pc_email = "";
    let result = await member_update(id, data);
    if (result.status) {
      return navigate("/admin/students/members");
    }
    setIsSubmitting(false);
  };
  useEffect(() => {
    const fetchEditMember = async () => {
      const res = await member_edit(id);
      return res?.data;
    };

    const fetchDropdownData = async () => {
      const courseTypeList = await course_type_all();
      const gradeList = await grade_all();
      const days = await get_days();
      // const terms = await get_terms_by_state(stateID);
      return {
        courseTypes: courseTypeList?.data,
        grades: gradeList?.data,
        days: days?.data,
        // terms: terms?.data,
      };
    };

    const fetchData = async () => {
      const memberData = await fetchEditMember();
      const dropdownData = await fetchDropdownData();
      if (memberData) {
        setShowResults(true);
        setValue("first_name", memberData.first_name);
        setValue("last_name", memberData.last_name);
        setValue("gender", memberData.gender);
        const dateComponents = memberData.date_of_birth?.split("-");
        const year = parseInt(dateComponents[0]);
        const month = parseInt(dateComponents[1]) - 1;
        const day = parseInt(dateComponents[2]);
        const formattedDate =
          year ? new Date(Date.UTC(year, month, day)).toISOString().substring(0, 10) : "";
        const terms = await get_terms_by_state(memberData?.state);
        setTermData(terms?.data);
        setValue("date_of_birth", formattedDate);
        setValue("school", memberData?.school);
        setValue("address", memberData?.address);
        setValue("studentID", `CSPRO00${memberData?.id}`);
        setValue("classroom_gmail", memberData?.classroom_gmail);
        setValue("cs_education", memberData?.cs_education);
        setTimeout(() => {
          setValue("subject_id", memberData?.subject_id);
          if (termData) {
            setValue("term_id", parseInt(memberData?.term_id));
          }
        }, 700);
        setSelectedCourse(memberData?.course);
        setShow(memberData?.cs_education === "1");
        setGradeId(memberData?.grade);
        setStateId(memberData?.state);
        const fieldsToSet = [
          "grade",
          "branch",
          "day",
          "type",
          "state",
          "sibling_grade",
          "course",
          "student_number",
          "p_first_name",
          "p_last_name",
          "p_mobile_phone",
          "mother_mobilenumber",
          "father_mobilenumber",
          "address",
          "city",
          "p_email",
          "pc_email",
          "sibling",
          "email",
          "password",
          "confirmPassword",
          "course",
          "student_type",
        ];
        fieldsToSet.forEach((field) => {
          setValue(field, memberData[field]);
        });
        if (dropdownData?.grades) {
          setTimeout(() => {
            setValue("grade", parseInt(memberData.grade));
            // setValue("subject_id", parseInt(memberData.subject_id));
            setValue("class_id", memberData.class_id);
            // setValue("term_id",parseInt(memberData.term_id));
            setValue("day", memberData.day);
          }, 700);
          setValue("course", memberData.course);
        }
      }
      setCoursetypeList(dropdownData.courseTypes);
      setGradeList(dropdownData.grades);
      setDays(dropdownData.days);
      setTermData(dropdownData?.terms);
    };

    fetchData();
  }, [id]);
  useEffect(() => {
    const fetchStateBranch = async () => {
      if (stateID) {
        const resp = await state_branch_all(stateID);
        const terms = await get_terms_by_state(stateID);
        const dataa = resp?.data;
        setBranchList(dataa);
        setTermData(terms?.data);
      } else {
        const branchList = await branch_all();
        setBranchList(branchList?.data);
      }
    };

    fetchStateBranch();
  }, [stateID]);

  useEffect(() => {
    const fetchData = async () => {
      const terms = await get_course_terms(courseID);
      const stateList = await get_states();
      setTermData(terms?.data);
      setStateList(stateList?.data);
    };
    fetchData();
  }, [courseID]);
  return (
    <>
      <div className="right-nav" id="dash-event">
        {showResults ? (
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Edit Account</h4>
              <Link
                className="edit-btn"
                to={`/admin/students/members`}
                onClick={(e) => {
                  handleNavigation(e, isDirty);
                }}
              >
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
              </Link>
            </div>
            <div className="modal-body">
              <Form
                className="form-horizontal"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      <b>Step1. Student information</b>
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>First Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        name="first_name"
                        placeholder="Enter First Name"
                        {...register("first_name", {
                          required: "This is required",
                          validate: (value) =>
                            /^[A-Za-z\s]+$/.test(value) ||
                            "Only letters are allowed",
                        })}
                      />
                      {errors.first_name && errors.first_name && (
                        <span className="validation-error">
                          {errors.first_name.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Last Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        name="last_name"
                        placeholder="Enter Last Name"
                        {...register("last_name", {
                          required: "This is required",
                          validate: (value) =>
                            /^[A-Za-z\s]+$/.test(value) ||
                            "Only letters are allowed",
                        })}
                      />
                      {errors.last_name && (
                        <span className="validation-error">
                          {errors.last_name.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Gender *</label>
                      <select
                        className="form-select form-control"
                        name="grade"
                        {...register("gender", { required: true })}
                      >
                        <option value="">Choose Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                      {errors.gender && errors.gender.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Date of Birth </label>
                      <input
                        type="date"
                        className="form-control"
                        name="date_ob"
                        max={maxDate}
                        min={minDate}
                        {...register("date_of_birth", {
                          validate: (value) => {
                            if (!value) return true;
                            const today = new Date();
                            const birthDate = new Date(value);
                            if (isNaN(birthDate.getTime()))
                              return "Invalid date";
                            const age =
                              today.getFullYear() -
                              birthDate.getFullYear() -
                              (today <
                                new Date(
                                  today.getFullYear(),
                                  birthDate.getMonth(),
                                  birthDate.getDate()
                                )
                                ? 1
                                : 0);

                            return age <= 20 || "Age must be below 20 years";
                          },
                        })}
                      />

                      {errors.date_of_birth && (
                        <span className="validation-error">
                          {errors.date_of_birth.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>School Name </label>
                      <input
                        type="text"
                        className="form-control"
                        name="schoole_name"
                        placeholder="Enter School Name"
                        {...register("school")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Address</label>
                      <input
                        type="text"
                        className="form-control"
                        name="address"
                        placeholder="Enter Address"
                        {...register("address")}
                      />
                      {errors.address && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>State *</label>
                      <select
                        className="form-select form-control"
                        {...register("state", { required:"This is required",
                          onChange: (e) => {
                            setStateId(e.target.value);
                            setValue("term_id", "");
                            setValue("branch", "");
                          },
                        })}
                      >
                        {statelist?.length > 0
                          ? [
                            <option value="">Choose State</option>,
                            statelist?.map((elem) => {
                              return (
                                <option value={elem?.id}>{elem?.name}</option>
                              );
                            }),
                          ]
                          : [<option value="">State not available</option>]}
                      </select>
                      {errors.state && errors.state && (
                      <span className="validation-error">
                        {errors.state.message}
                      </span>
                    )}
                    </div>
                  </div>
                  <div className={show ? `col-md-6` : `col-md-6`}>
                    <div className="form-group">
                      <label>Branch </label>
                      <select
                        key={branchlist.length}
                        className="form-select form-control"
                        name="grade"
                        {...register("branch")}
                      >
                        {branchlist?.length > 0
                          ? [
                            <option value="">Choose Branch</option>,
                            ...branchlist?.map((elem) => {
                              return (
                                <option value={elem?.id}>
                                  {elem?.branch_name}
                                </option>
                              );
                            }),
                          ]
                          : [
                            // <option value="">Choose Branch</option>
                            //   ,
                            <option value="">Branch not available</option>,
                          ]}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Grade *</label>
                      <select
                        className="form-select form-control"
                        name="grade"
                        {...register("grade", {
                          validate: (value) =>
                            value !== "" || "This is required",
                        })}
                        onChange={(e) => setGradeId(e.target.value)}
                      >
                        <option value="">Choose Grade</option>
                        {gradelist?.length > 0 ? (
                          gradelist.map((elem) => (
                            <option key={elem?.id} value={elem?.id}>
                              {elem.name === 0
                                ? `Year ${elem.name} (kindy)`
                                : `Year ${elem.name}`}
                            </option>
                          ))
                        ) : (
                          <option>No result</option>
                        )}
                        <option value="other">Other</option>
                      </select>
                      {gradeID
                        ? ""
                        : errors.grade && (
                          <span className="validation-error">
                            {errors.grade.message}
                          </span>
                        )}
                    </div>
                  </div>
                  <div className={show ? `col-md-3` : `col-md-6`}>
                    <div className="form-group">
                      <label>Course Type</label>
                      <select
                        className="form-select form-control"
                        name="course"
                        {...register("subject_id")}
                      >
                        <option value="">Choose course Type</option>
                        {coursetypeList &&
                          coursetypeList?.map((elem) => {
                            return (
                              <option value={elem?.id}>
                                {elem?.category_name}
                              </option>
                            );
                          })
                        }
                      </select>
                    </div>
                  </div>
                  <div className={`col-md-4`}>
                    <div className="form-group">
                      <label>Term </label>
                      <select
                        className="form-select form-control"
                        {...register("term_id")}
                      >
                        <option value="">Choose Term </option>
                        {termData &&
                          termData?.map((elem) => {
                            return (
                              <option value={elem?.id}>{elem?.name}</option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className={`col-md-4`}>
                    <div className="form-group">
                      <label>Day </label>
                      <select
                        className="form-select form-control"
                        {...register("day")}
                      >
                        <option value="">Choose Day</option>
                        {days &&
                          days?.map((elem) => {
                            return (
                              <option value={elem?.id}>{elem?.name}</option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className={`col-md-4`}>
                    <div className="form-group">
                      <label>Student Type</label>
                      <select
                        className="form-select form-control"
                        {...register("student_type")}
                      >
                        <option value="">Choose student type</option>
                        <option value="0">Free</option>
                        <option value="1">Enroll</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <hr className="mt-3" />
                    <div className="form-group">
                      <label>
                        <b>Step 2. Parent information</b>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Parent's First Name </label>
                      <input
                        type="text"
                        className="form-control"
                        name="venue_name"
                        placeholder="Enter Parent First Name"
                        {...register("p_first_name", {
                          onChange: (e) => e.target.value,
                          validate: (value) =>
                            value === "" ||
                            /^[A-Za-z\s]+$/.test(value) ||
                            "Only letters are allowed",
                        })}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Parent's Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="venue_name"
                        placeholder="Enter Parent Last  Name"
                        {...register("p_last_name", {
                          onChange: (e) => e.target.value,
                          validate: (value) =>
                            value === "" ||
                            /^[A-Za-z\s]+$/.test(value) ||
                            "Only letters are allowed",
                        })}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Primary Contact *</label>
                      <div className="input-group branch-prepend">
                        <div className="input-group-prepend">
                          <div className="input-group-text">+61</div>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="04XX XXX XXX"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          name="offine_seats"
                          {...register("mother_mobilenumber", {
                            required: "Mobile number  is required",
                            pattern: {
                              value: /^0[2-8]\d{8}$|^04\d{2}\s\d{3}\s\d{3}$/,
                              message:
                                "Enter a valid Australian phone number (e.g., 04XX XXX XXX or 0X XXXX XXXX)",
                            },
                            validate: {
                              isNumeric: (value) =>
                                value === "" ||
                                /^\d+$/.test(value.replace(/\s+/g, "")) ||
                                "Phone number must contain only numbers",
                            },
                          })}
                          maxLength={10}
                        />
                      </div>
                      {errors.mother_mobilenumber && (
                        <span className="validation-error">
                          {errors.mother_mobilenumber.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Alternate Contact no</label>
                      <div className="input-group branch-prepend">
                        <div className="input-group-prepend">
                          <div className="input-group-text">+61</div>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="000 000 (0000)"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          {...register("father_mobilenumber", {
                            pattern: {
                              value: /^0[2-8]\d{8}$|^04\d{2}\s\d{3}\s\d{3}$/,
                              message:
                                "Enter a valid Australian phone number (e.g., 04XX XXX XXX or 0X XXXX XXXX)",
                            },
                            validate: {
                              isNumeric: (value) =>
                                value === "" ||
                                /^\d+$/.test(value.replace(/\s+/g, "")) ||
                                "Phone number must contain only numbers",
                            },
                          })}
                          maxLength={10}
                        />
                      </div>
                      {errors.father_mobilenumber && (
                        <span className="validation-error">
                          {errors.father_mobilenumber.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>City </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter City"
                        name="description"
                        {...register("city")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Parent's Email *</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Parent Email"
                        name="p_email"
                        {...register("p_email", {
                          required: true,
                          pattern: {
                            value:
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: "Please enter a valid email address",
                          },
                        })}
                      />
                      {errors.p_email && errors.p_email.type === "required" && (
                        <span className="validation-error">
                          Parent's email is required
                        </span>
                      )}
                      {errors.p_email && (
                        <span className="validation-error">
                          {errors.p_email.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <hr className="mt-3" />
                  <div className="form-group">
                    <label>
                      <b>Step 3. Sibling information</b>
                    </label>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Sibling</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Sibling"
                        name="description"
                        {...register("sibling", {
                          maxLength: {
                            value: 15,
                            message: "Sibling name cannot exceed 15 characters",
                          },
                        })}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Sibling Grade</label>
                      <select
                        className="form-control form-select"
                        name="grade"
                        {...register("sibling_grade")}
                      >
                        <option value="">Choose Grade</option>
                        {gradelist?.length > 0 ? (
                          gradelist.map((elem) => (
                            <option key={elem?.id} value={elem?.id}>
                              {elem.name === 0
                                ? `Year ${elem.name} (kindy)`
                                : `Year ${elem.name}`}
                            </option>
                          ))
                        ) : (
                          <option>No result</option>
                        )}
                      </select>
                    </div>
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <Button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        ) : (
          <DataLoader />
        )}
      </div>
    </>
  );
};
export default EditMember;
