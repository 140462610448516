import React, { useEffect, useState } from "react";
import AllEvalutation from "./All";
import { validateDate } from "../../../../Utils/Utils";
const EditEvaluation = ({
  setEvaluation,
  singleTypeFormdata,
  testTypeData,
  term_id,
  course_id,
  CallEvaluationList,
  year,
  grade_id,
  setCheckEvaluationValidation
}) => {
  const [forms, setForms] = useState([]);
  const today = new Date().toISOString().split("T")[0];
  console.log("setEvaluation", setEvaluation);

  useEffect(() => {
    const initializeEvaluations = () => {
      const initialEvaluations = [
        {
          test_name: "",
          test_type: "",
          test_kind: "",
          test_no: "",
          test_total_ques: "",
          test_total_time: "",
          tstm_guid: "",
          jwp_media_id: "",
          expiration_date: "",
          status: "",
        },
      ];
      setForms(initialEvaluations);
      setEvaluation(initialEvaluations);
    };

    initializeEvaluations();
  }, [testTypeData, setEvaluation, CallEvaluationList]);
  const handleEvaluationInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedForms = [...forms];
    updatedForms[index] = {
      ...updatedForms[index],
      [name]: value,
    };
    setForms(updatedForms);
    setEvaluation(updatedForms);
    if (name === "expiration_date") {
      const isValidDate = validateDate(value);
      setCheckEvaluationValidation(isValidDate)
    }
  };

  const addMoreEvaluation = () => {
    const newEvaluation = {
      test_name: "",
      test_type: "",
      test_kind: "",
      test_no: "",
      test_total_ques: "",
      test_total_time: "",
      tstm_guid: "",
      jwp_media_id: "",
      expiration_date: "",
      status: 0,
    };
    setForms([...forms, newEvaluation]);
    setEvaluation([...forms, newEvaluation]);
  };
  return (
    <div className="accordion" id="accordionExample">
      <div className="modal-body">
        <AllEvalutation
          term_id={term_id}
          course_id={course_id}
          CallEvaluationList={CallEvaluationList}
          year={year}
          grade_id={grade_id}
        />
        <div className="row table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>S.NO</th>
                <th>Test Name/Title</th>
                <th>Test Type</th>
                <th>Test Kind</th>
                <th>Test No</th>
                <th>Test Total Questions</th>
                <th>Test Total Time</th>
                <th>TSTM GUID</th>
                <th>JWP Media ID</th>
                <th>Expiration date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {forms?.map((form, index) => (
                <tr key={`evaluation-${index}`}>
                  <td>{index + 1}</td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      name="test_name"
                      value={form.test_name || ""}
                      onChange={(e) => handleEvaluationInputChange(e, index)}
                    />
                  </td>
                  <td>
                    <select
                      className="form-select"
                      name="test_type"
                      value={form.test_type || ""}
                      onChange={(e) => handleEvaluationInputChange(e, index)}
                    >
                      <option value="">Select Test Type</option>
                      {testTypeData?.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type?.test_title}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      className="form-select"
                      name="test_kind"
                      value={form.test_kind || ""}
                      onChange={(e) => handleEvaluationInputChange(e, index)}
                    >
                      <option value="">Select test kind</option>
                      <option value="objective">Objective</option>
                      <option value="selective">Subjective</option>
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      name="test_no"
                      value={form.test_no || ""}
                      onChange={(e) => handleEvaluationInputChange(e, index)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      name="test_total_ques"
                      value={form.test_total_ques || ""}
                      onChange={(e) => handleEvaluationInputChange(e, index)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      name="test_total_time"
                      value={form.test_total_time || ""}
                      onChange={(e) => handleEvaluationInputChange(e, index)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      name="tstm_guid"
                      value={form.tstm_guid || ""}
                      onChange={(e) => handleEvaluationInputChange(e, index)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      name="jwp_media_id"
                      value={form.jwp_media_id || ""}
                      onChange={(e) => handleEvaluationInputChange(e, index)}
                    />
                  </td>
                  <td>
                    <div key={index} className="space-y-2">
                      <input
                        id={`expiration_date_${index}`}
                        type="date"
                        className={`form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none`}
                        name="expiration_date"
                        max="9999-12-31"
                        min={today}
                        value={form.expiration_date}
                        onChange={(e) => handleEvaluationInputChange(e, index)}
                      />
                    </div>
                  </td>
                  <td>
                    <select
                      className="form-select"
                      name="status"
                      value={form.status}
                      onChange={(e) => handleEvaluationInputChange(e, index)}
                    >
                      <option value={0} selected>
                        Inactive
                      </option>
                      <option value={1}>Active</option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button
          type="button"
          className="evaluation_btn_footer mt-3"
          onClick={() => addMoreEvaluation()}
        >
          Add more
        </button>
      </div>
    </div>
  );
};

export default EditEvaluation;
