import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { events_all } from "../../../apis/Events";

const CelenderView = () => {
  const [events, setEvents] = useState([]);
  const localizer = momentLocalizer(moment);

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await events_all();
        const formattedEvents = result?.data?.map((event) => ({
          id: event.id,
          title: event.title,
          start: new Date(`${event.date}T${event.time_start}`),
          end: new Date(`${event.date}T${event.time_end}`),
          description: event.description,
          venue: event.venue_name,
          bookingType: event.offline_online_event,
        }));
        setEvents(formattedEvents);
      } catch (error) {
        console.error("Error fetching events data:", error);
      }
    }
    fetchData();
  }, []);

  const eventStyleGetter = () => {
    const style = {
      backgroundColor: "#ed1c24",
      color: "white",
      borderRadius: "5px",
      border: "none",
      padding: "5px",
    };
    return { style };
  };

  const CustomEvent = ({ event }) => {
    return (
      <div className="custom-event">
        <p className="event-booking-type">{event.bookingType}</p>
        <p className="event-title">{event.title}</p>
        <p className="event-time">
          {moment(event.start).format("MMM DD, YYYY h:mm A")} - {moment(event.end).format("h:mm A")}
        </p>
      </div>
    );
  };

  return (
    <div className="right-nav" id="dash-event">
      <div className="calendar-view">
        <h2>Events Calendar</h2>
        <Calendar
          localizer={localizer}
          events={events}
          components={{
            event: CustomEvent,
          }}
          startAccessor="start"
          endAccessor="end"
          style={{ height: "75vh", margin: "20px" }}
          eventPropGetter={eventStyleGetter}
          tooltipAccessor="description"
        />
      </div>
    </div>
  );
};

export default CelenderView;
