import { Link } from "react-router-dom";
import { Chart } from "react-google-charts";
import { useState, useEffect } from "react";
import { get_branch_dashboard } from '../../api/Dashboard'
const Dashboard = () => {
    const [branchStudent, setBranchStudent] = useState({});
    const fullname = localStorage.getItem('b_name');
    useEffect(() => {
        async function get_data() {
            const DashboardData = await get_branch_dashboard();
            setBranchStudent(DashboardData.data);
        }
        get_data();
    }, []);


    const emailName = localStorage.getItem("email");

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = today.toLocaleString('default', { month: 'short' });
    let yyyy = today.getFullYear();
    today = dd + '-' + mm + '-' + yyyy;

    const data = [
        ["2013", "Hours per Day"],
        ["2014", 11],
        ["2015", 2],
        ["2016", 2],
        ["Watch TV", 2],
        ["Sleep", 7], // CSS-style declaration
    ];
    const options = {
        title: "Yearly Performance",
        pieHole: 0.4,
        is3D: false,
        height: 400,
        legend: {
            alignment: 'center',
            position: 'bottom'
        }
    };
    const data1 = [
        ["Year", "Sales", "Expenses", "Profit"],
        ["2014", 1000, 400, 200],
        ["2015", 1170, 460, 250],
        ["2016", 660, 1120, 300],
        ["2017", 1030, 540, 350],
    ];
    const options1 = {
        chart: {
            title: "Company Performance",
            subtitle: "Sales, Expenses, and Profit: 2014-2017",
        }
    };


    return (
        <div className="right-nav" id="dash-dashboard">
            <link rel="stylesheet" href="https://themewagon.github.io/skydash/vendors/feather/feather.css" />
            <link rel="stylesheet" href="https://themewagon.github.io/skydash/vendors/ti-icons/css/themify-icons.css" />
            <link rel="stylesheet" href="https://themewagon.github.io/skydash/vendors/css/vendor.bundle.base.css" />
            <link rel="stylesheet" href="https://themewagon.github.io/skydash/vendors/datatables.net-bs4/dataTables.bootstrap4.css" />
            <link rel="stylesheet" href="https://themewagon.github.io/skydash/vendors/ti-icons/css/themify-icons.css" />
            <link rel="stylesheet" type="text/css" href="https://themewagon.github.io/skydash/js/select.dataTables.min.css" />
            <link rel="stylesheet" href="https://themewagon.github.io/skydash/css/vertical-layout-light/style.css" />

            <div className="container-fluid">
                <div className="container-scroller">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-md-12 grid-margin">
                                <div className="row">
                                    <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                                        <h3 className="font-weight-bold">Welcome {JSON.parse(fullname)}</h3>
                                        {/* <h6 className="font-weight-normal mb-0">Email: {emailName}</h6> */}
                                    </div>
                                    <div className="col-12 col-xl-4">
                                        <div className="justify-content-end d-flex">
                                            <div className="dropdown flex-md-grow-1 flex-xl-grow-0">
                                                <button className="btn btn-sm btn-light bg-white fw-bold " type="button" id="dropdownMenuDate2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                    <i className="mdi mdi-calendar"></i>  Today {`(${today})`}
                                                </button>
                                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuDate2">
                                                    <a className="dropdown-item" href="#">January - March</a>
                                                    <a className="dropdown-item" href="#">March - June</a>
                                                    <a className="dropdown-item" href="#">June - August</a>
                                                    <a className="dropdown-item" href="#">August - November</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 grid-margin transparent">
                                <div className="row">
                                    <div className="col-md-4 mb-4 stretch-card transparent">
                                        <div className="card " style={{ background: "#4285f4" }}>
                                            <div className="card-body">
                                                <p className="mb-4 text-white">Total Courses</p>
                                                <p className="fs-30 mb-2 text-white">{branchStudent?.course}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-4 stretch-card transparent">
                                        <div className="card" style={{ background: "#db4437" }}>
                                            <div className="card-body">
                                                <p className="mb-4 text-white">Total Teachers</p>
                                                <p className="fs-30 mb-2 text-white">{branchStudent?.teacher}</p>
                                                {/* <p>10.00% (30 days)</p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-4 stretch-card transparent">
                                        <div className="card" style={{ background: "#f4b400" }}>
                                            <div className="card-body">
                                                <p className="mb-4 text-white">Total Enroll Student</p>
                                                <p className="fs-30 mb-2 text-white">{branchStudent?.enrolledSt}</p>
                                                {/* <p>10.00% (30 days)</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 mb-4 mb-lg-0 stretch-card transparent" style={{ marginTop: '16px' }}>
                                        <div className="card" style={{ background: "#990099" }}>
                                            <div className="card-body">
                                                <p className="mb-4 text-white">Total Free Student</p>
                                                <p className="fs-30 mb-2 text-white">{branchStudent?.freeSt}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-4 mb-lg-0 stretch-card transparent" style={{ marginTop: '16px' }}>
                                        <div className="card" style={{ background: "#109618" }}>
                                            <div className="card-body">
                                                <p className="mb-4 text-white">Total Questionnaire</p>
                                                <p className="fs-30 mb-2 text-white">{branchStudent?.question}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-4 mb-lg-0 stretch-card transparent" style={{ marginTop: '16px' }}>
                                        <div className="card" style={{ background: "#5b769f" }}>
                                            <div className="card-body">
                                                <p className="mb-4 text-white">Total Student Attendance</p>
                                                <p className="fs-30 mb-2 text-white">{branchStudent?.attendance}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   <script src="https://themewagon.github.io/skydash/vendors/js/vendor.bundle.base.js"></script>
            <script src="https://themewagon.github.io/skydash/vendors/chart.js/Chart.min.js"></script>
            <script src="https://themewagon.github.io/skydash/vendors/datatables.net/jquery.dataTables.js"></script>
            <script src="https://themewagon.github.io/skydash/vendors/datatables.net-bs4/dataTables.bootstrap4.js"></script>
            <script src="https://themewagon.github.io/skydash/js/dataTables.select.min.js"></script>
            <script src="https://themewagon.github.io/skydash/js/off-canvas.js"></script>
            <script src="https://themewagon.github.io/skydash/js/hoverable-collapse.js"></script>
            <script src="https://themewagon.github.io/skydash/js/template.js"></script>
            <script src="https://themewagon.github.io/skydash/js/settings.js"></script>
            <script src="https://themewagon.github.io/skydash/js/todolist.js"></script>
            <script src="https://themewagon.github.io/skydash/js/dashboard.js"></script>
            <script src="https://themewagon.github.io/skydash/js/Chart.roundedBarCharts.js"></script>

        </div>
    )
};

export default Dashboard;