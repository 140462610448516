import { Chart } from "react-google-charts";
import "../../css/adminnew.css";
import people from "../../image/people.svg";
import { get_branch_student,get_event_list} from "../../apis/Dashboard";
import { useState, useEffect } from "react";
import {formatDate } from "../../Utils/Utils";
const Dashboard = () => {
  const [branchStudent, setBranchStudent] = useState({});
  const [eventList, setEventList] = useState([]);
  useEffect(() => {
    async function get_data() {
      const DashboardData = await get_branch_student();
      const EventData = await get_event_list();
      setBranchStudent(DashboardData.data);
      setEventList(EventData.data);
    }
    get_data();
  }, []);
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = today.toLocaleString("default", { month: "short" });
  let yyyy = today.getFullYear();
  today = dd + "-" + mm + "-" + yyyy;
  
  return (
    <div className="right-nav" id="dash-dashboard">
      <link
        rel="stylesheet"
        href="https://themewagon.github.io/skydash/vendors/feather/feather.css"
      />
      <link
        rel="stylesheet"
        href="https://themewagon.github.io/skydash/vendors/ti-icons/css/themify-icons.css"
      />
      <link
        rel="stylesheet"
        href="https://themewagon.github.io/skydash/vendors/css/vendor.bundle.base.css"
      />
      <link
        rel="stylesheet"
        href="https://themewagon.github.io/skydash/vendors/datatables.net-bs4/dataTables.bootstrap4.css"
      />
      <link
        rel="stylesheet"
        href="https://themewagon.github.io/skydash/vendors/ti-icons/css/themify-icons.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://themewagon.github.io/skydash/js/select.dataTables.min.css"
      />
      <link
        rel="stylesheet"
        href="https://themewagon.github.io/skydash/css/vertical-layout-light/style.css"
      />
      <div className="container-fluid">
        <div className="container-scroller">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-md-12 grid-margin">
                <div className="row">
                  <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                    <h3 className="font-weight-bold">Welcome Admin</h3>
                    <h6 className="font-weight-normal mb-0">
                      All systems are running smoothly! You have{" "}
                      <span className="text-primary">3 unread alerts!</span>
                    </h6>
                  </div>
                  <div className="col-12 col-xl-4">
                    <div className="justify-content-end d-flex">
                      <div className="dropdown flex-md-grow-1 flex-xl-grow-0">
                        <button
                          className="btn btn-sm btn-light bg-white fw-bold "
                          type="button"
                          id="dropdownMenuDate2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="true"
                        >
                          Today {`(${today})`}
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-right"
                          aria-labelledby="dropdownMenuDate2"
                        >
                          <a className="dropdown-item" href="#">
                            January - March
                          </a>
                          <a className="dropdown-item" href="#">
                            March - June
                          </a>
                          <a className="dropdown-item" href="#">
                            June - August
                          </a>
                          <a className="dropdown-item" href="#">
                            August - November
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-md-6 grid-margin stretch-card">
                <div className="">
                  <div className="card-people mt-auto">
                    <img src={people} alt="people" /> */}
                    {/* <img src="https://themewagon.github.io/skydash/images/dashboard/people.svg" alt="people" /> */}

                    {/* <div className="weather-info">
                      <div className="d-flex">
                        <div>
                          <h2 className="mb-0 font-weight-normal">
                            <i className="icon-sun mr-2"></i>31<sup>°C</sup>
                          </h2>
                        </div>
                        <div className="ml-2">
                          <h4 className="location font-weight-normal">
                            Sydney
                          </h4>
                          <h6 className="font-weight-normal">Australia</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-md-12 grid-margin transparent">
                <div className="row">
                  <div className="col-md-4 mb-4 stretch-card transparent">
                    <div className="card " style={{ background: "#4285f4" }}>
                      <div className="card-body">
                        <p className="mb-4 fw-bold text-white">Total Courses</p>
                        <p className="fs-30 mb-2 fw-bold text-white">
                          {branchStudent?.courses}
                        </p>
                        {/* <p>10.00% (30 days)</p> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 stretch-card transparent">
                    <div className="card" style={{ background: "#db4437" }}>
                      <div className="card-body">
                        <p className="mb-4 fw-bold text-white">
                          Total Assessments
                        </p>
                        <p className="fs-30 mb-2 fw-bold text-white">
                          {branchStudent?.freeAssessment}
                        </p>
                        {/* <p>50.00% (20 days)</p> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 stretch-card transparent">
                    <div className="card" style={{ background: "#f4b400" }}>
                      <div className="card-body">
                        <p className="mb-4 fw-bold text-white">
                          Total Teachers
                        </p>
                        <p className="fs-30 mb-2 fw-bold text-white">
                          {branchStudent?.teachers}
                        </p>
                        {/* <p>50.00% (20 days)</p> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 stretch-card transparent">
                    <div className="card" style={{ background: "#990099" }}>
                      <div className="card-body">
                        <p className="mb-4 fw-bold text-white">
                          Total Question
                        </p>
                        <p className="fs-30 mb-2 fw-bold text-white">
                          {branchStudent?.question}
                        </p>
                        {/* <p>10.00% (30 days)</p> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 stretch-card transparent">
                    <div className="card" style={{ background: "#109618" }}>
                      <div className="card-body">
                        <p className="mb-4 fw-bold text-white">
                          Total Branches
                        </p>
                        <p className="fs-30 mb-2 fw-bold text-white">
                          {branchStudent?.branch}
                        </p>
                        {/* <p>50.00% (20 days)</p> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 stretch-card transparent">
                    <div className="card" style={{ background: "#5b769f" }}>
                      <div className="card-body">
                        <p className="mb-4 fw-bold text-white">
                          Total Students
                        </p>
                        <p className="fs-30 mb-2 fw-bold text-white">
                          {branchStudent?.student}
                        </p>
                        {/* <p>50.00% (20 days)</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-md-4 stretch-card grid-margin">
                <div className="row">
                  <div className="col-md-12 stretch-card grid-margin grid-margin-md-0">
                    <div className="card data-icon-card-primary">
                      <div className="card-body">
                        <p className="card-title text-white">
                          Number of Assessments
                        </p>
                        <div className="row">
                          <div className="col-8 text-white">
                            <h3>34040</h3>
                            <p className="text-white font-weight-500 mb-0">
                              The total number of assesments within the month.It
                              is calculated as the sum .{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <p className="card-title">Branch-wise Review</p>
                    <div className="row">
                      <div className="col-12">
                        <div className="table-responsive">
                          <table
                            id="example"
                            className="display expandable-table"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th>Sr no</th>
                                <th>Branch Name</th>
                                <th>Branch Code</th>
                                <th>Total Students</th>
                              </tr>
                            </thead>
                            <tbody>
                              {branchStudent?.branchStudent &&
                                branchStudent?.branchStudent?.map(
                                  (ele, index) => {
                                    return (
                                      <tr className="odd">
                                        <td className="select-checkbox">
                                          #{index + 1}
                                        </td>
                                        <td className="sorting_1">
                                          {ele?.branch_name}
                                        </td>
                                        <td>{ele.branch_code}</td>
                                        <td>{ele.branch_count}</td>

                                        {/* <td>$2,500</td> */}
                                      </tr>
                                    );
                                  }
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* event list */}
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <p className="card-title">Event List</p>
                    <div className="row">
                      <div className="col-12">
                        <div className="table-responsive">
                          <table
                            id="example"
                            className="display expandable-table"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th>Sr No</th>
                                <th>Title</th>
                                <th>Type</th>
                                <th>Date</th>
                                <th>Venue</th>
                                <th>Booking</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {eventList &&
                                eventList?.map(
                                  (ele, index) => {
                                    return (
                                      <tr className="odd">
                                        <td className="select-checkbox">
                                          #{index + 1}
                                        </td>
                                        <td className="sorting_1">
                                          {ele?.title}
                                        </td>
                                        <td>{ele?.event_type}</td>
                                        <td>{formatDate(ele?.date)}</td>
                                        <td>{ele?.venue_name}</td>
                                        <td>{ele?.offline_online_event}</td>
                                        <td>{ele?.delete_status === 1 ? 'Active' : 'Inactive'}</td>
                                      </tr>
                                    );
                                  }
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <script src="https://themewagon.github.io/skydash/vendors/js/vendor.bundle.base.js"></script>
      <script src="https://themewagon.github.io/skydash/vendors/chart.js/Chart.min.js"></script>
      <script src="https://themewagon.github.io/skydash/vendors/datatables.net/jquery.dataTables.js"></script>
      <script src="https://themewagon.github.io/skydash/vendors/datatables.net-bs4/dataTables.bootstrap4.js"></script>
      <script src="https://themewagon.github.io/skydash/js/dataTables.select.min.js"></script>
      <script src="https://themewagon.github.io/skydash/js/off-canvas.js"></script>
      <script src="https://themewagon.github.io/skydash/js/hoverable-collapse.js"></script>
      <script src="https://themewagon.github.io/skydash/js/template.js"></script>
      <script src="https://themewagon.github.io/skydash/js/settings.js"></script>
      <script src="https://themewagon.github.io/skydash/js/todolist.js"></script>
      <script src="https://themewagon.github.io/skydash/js/dashboard.js"></script>
      <script src="https://themewagon.github.io/skydash/js/Chart.roundedBarCharts.js"></script>
    </div>
  );
};

export default Dashboard;
