import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {
  payment_intent,
  update_payment_intent,
} from "../../../../apis/studentManagement/StudentManagement";
import { discout_all_branch } from "../../../api/Student_Enroll";
import { useNavigate } from "react-router-dom";
import {
  Course_List_for_multiple_select,
  get_payment_detail,
} from "../../../api/Student_Enroll";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

// Modal Styles
const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    width: "400px",
    height: "auto",
    margin: "auto",
    padding: "20px",
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)",
  },
};

// Card Input Styles
const cardStyle = {
  style: {
    base: {
      fontSize: "16px",
      color: "#333",
      "::placeholder": {
        color: "#aaa",
      },
      padding: "10px",
    },
    invalid: {
      color: "#e63946",
    },
  },
};

const StripePaymentModal = ({
  isOpen,
  onClose,
  handleStudentTypeOnclose,
  setPaymentIntentID,
  userData,
  type,
  term_id,
  user_id,
  grade_id
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [paymentType, setPaymentType] = useState("card");
  const [discountList, setDiscountList] = useState([]);
  const [finalAmount, setFinalAmount] = useState(0);
  const [selectedDiscount, setSelectedDiscount] = useState("");
  const [courseList, setCourseList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [discount, setDiscount] = useState("");
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [perviousCourses, setPreviousCourses] = useState([]);
  const [validation,setValidation]=useState(false)
  const { control } = useForm();
  const navigate = useNavigate();

  // Handle form submission
  // const handleSubmit = async (e) => {
  //   if(!selectedCourse){
  //     setValidation(true)
  //     return
  //   }
  //   e.preventDefault();
  //   if (!stripe || !elements) return;
  //   setLoading(true);

  //   let paymentMethod;
  //   if (paymentType === "card") {
  //     paymentMethod = await stripe.createPaymentMethod({
  //       type: "card",
  //       card: elements.getElement(CardElement),
  //     });
  //   }
  //   const courseData = selectedCourse.map((course) => ({
  //     course_id: course.value,
  //     amount: course.amount,
  //     discounted_amount: parseFloat(
  //       (course.amount * (1 - discountPercentage / 100)).toFixed(1)
  //     ),
  //   }));
  //   const data = {
  //     paymentMethodId: paymentMethod?.paymentMethod?.id
  //       ? paymentMethod?.paymentMethod?.id
  //       : "",
  //     total_amount: finalAmount ? finalAmount : totalAmount,
  //     user_id: userData?.user_id,
  //     term_id: userData?.term_id,
  //     payment_type: paymentType,
  //     discount_id: selectedDiscount,
  //     course_id: courseData,
  //   };
  //   let result =
  //     type == "update"
  //       ? await update_payment_intent(data)
  //       : await payment_intent(data);
  //   setLoading(false);
  //   if (result.status) {
  //     setPaymentIntentID(result?.data?.id);
  //     handleStudentTypeOnclose("1");
  //     setTotalAmount('')
  //     setSelectedDiscount('')
  //     onClose(false);
  //     setSelectedCourse([]);
  //     if (type === "add") {
  //       navigate("/branch/enroll/student_enroll-list");
  //     }
  //   }
  // };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedCourse || selectedCourse.length === 0) {
      setValidation(true); 
      return; 
    }
    if (!stripe || !elements) return; 
    setLoading(true);
    let paymentMethod;
    if (paymentType === "card") {
      paymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });
    }
    const courseData = selectedCourse.map((course) => ({
      course_id: course.value,
      amount: course.amount,
      discounted_amount: parseFloat(
        (course.amount * (1 - discountPercentage / 100)).toFixed(1)
      ),
    }));
    const data = {
      paymentMethodId: paymentMethod?.paymentMethod?.id
        ? paymentMethod?.paymentMethod?.id
        : "",
      total_amount: finalAmount ? finalAmount : totalAmount,
      user_id: userData?.user_id,
      term_id: userData?.term_id,
      payment_type: paymentType,
      discount_id: selectedDiscount,
      course_id: courseData,
    };
    let result =
      type === "update"
        ? await update_payment_intent(data)
        : await payment_intent(data);
    
    setLoading(false); 
    if (result.status) {
      setPaymentIntentID(result?.data?.id); 
      handleStudentTypeOnclose("1");
      setSelectedDiscount("");
      onClose(false);
      setSelectedCourse([]); 
      setValidation(false)
      if (type === "add") {
        navigate("/branch/enroll/student_enroll-list");
      }
    }
  };
  
  // Handle payment type change
  const handlePaymentType = (e) => {
    setPaymentType(e.target.value);
  };

  // Fetch discount and course data
  const fetchData = async () => {
    const data = await discout_all_branch();
    if (term_id) {
      const courseData = await Course_List_for_multiple_select(
        grade_id,
        term_id,
       type == 'update' ? user_id : ''
      );
      setCourseList(courseData);
    }
    setDiscountList(data?.data);
  };

  useEffect(() => {
    fetchData();
  }, [term_id, type, isOpen]);

  // Apply discount
  const applyCoupon = (discount) => {
    const selectedCoupon = discountList.find((coupon) => coupon.id == discount);
    setSelectedDiscount(selectedCoupon?.id);
    setDiscountPercentage(selectedCoupon?.percentage || 0);
    setDiscount(selectedCoupon?.percentage);
    if (selectedCoupon?.percentage) {
      const discountAmount = (totalAmount * selectedCoupon?.percentage) / 100;
      setFinalAmount(totalAmount - discountAmount);
    } else {
      setFinalAmount(totalAmount);
    }
  };

  // Calculate total amount when courses are selected
  const getTotalAmount = (selectedCourses) => {
    if (!selectedCourses || selectedCourses.length === 0) {
      setTotalAmount(0);
      setFinalAmount(0);
      return;
    }
    const total = selectedCourses.reduce((sum, course) => {
      const foundCourse = courseList.find((c) => c.value == course.value);
      return sum + (foundCourse ? foundCourse.amount : 0);
    }, 0);
    setTotalAmount(total);
    // Recalculate final amount if a discount is already applied
    if (selectedDiscount) {
      const selectedCoupon = discountList.find(
        (coupon) => coupon.id == selectedDiscount
      );
      const discountAmount = (total * selectedCoupon?.percentage) / 100;
      setFinalAmount(total - discountAmount);
    } else {
      setFinalAmount(total);
    }
  };

  // Handle course selection
  const handleCourseChange = (selectedSubOptions) => {
    setSelectedCourse(selectedSubOptions);
    getTotalAmount(selectedSubOptions);
  };
  useEffect(() => {
    const fetchData = async () => {
      const data = await get_payment_detail(user_id, term_id);
      setPaymentType(data?.data?.payment_type ? data?.data?.payment_type : 'card');
      if (data?.data?.discount_id) {
        setSelectedDiscount(data?.data?.discount_id);
      }
      const selected =discountList?.find((d) => d.id == data?.data?.discount_id);
      applyCoupon(selected?.id);
      setPreviousCourses(data?.data?.preselect_courses);
    };
    if (type == "update") {
      fetchData();
    }
  }, [type, isOpen]);
  const ClosePopUp = () => {
    setSelectedCourse([]);
    setSelectedDiscount("");
    onClose(false);
    setTotalAmount('');
    setValidation(false)
  };
  console.log(!selectedCourse , validation);
  
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        ClosePopUp();
        handleStudentTypeOnclose("0");
      }}
      style={customStyles}
      ariaHideApp={false}
      className="modal-content"
      overlayClassName="modal-overlay"
      contentLabel="Payment Modal"
    >
      <div className="modal-header pt-0  mb-1">
        <h1 className="modal-title fs-5">Complete Payment</h1>
        <button
          type="button"
          onClick={() => {
            ClosePopUp();
            handleStudentTypeOnclose("0");
          }}
          className="btn-close"
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body complete_payment_popup">
        <div className="complete_payment_popup">
          <div className="container inline-flex">
            <div className="row">
              {type == "update" && (
                <div className="col-md-12 space-below">
                  <label className="form-label">Enrolled Courses</label>
                  <div className="d-flex flex-wrap gap-2">
                    {perviousCourses?.map((elem, index) => (
                      <span key={index} className="badge bg-primary p-2">
                        {elem}
                      </span>
                    ))}
                  </div>
                </div>
              )}
              <div className={`col-md-12 space-below`}>
                <label>Course List</label>
                <Controller
                  name="course"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      options={courseList}
                      placeholder="Choose Course"
                      isMulti
                      {...field}
                      value={selectedCourse}
                      onChange={(option) => {
                        handleCourseChange(option);
                        field.onChange(option);
                      }}
                    />
                  )}
                />
                {selectedCourse?.length < 1 && validation && <span className="validation-error">Course is required</span>}
              </div>
              <div className={`col-md-4`}>
                <h5>Payment type</h5>{" "}
                <input
                  type="radio"
                  id="cash"
                  name="payment_type"
                  value="cash"
                  checked={paymentType === "cash"}
                  onChange={(e) => handlePaymentType(e)}
                />
                <label htmlFor="cash">&nbsp; Cash</label>
                <br />{" "}
                <input
                  type="radio"
                  id="card"
                  name="payment_type"
                  value="card"
                  checked={paymentType === "card"}
                  onChange={(e) => handlePaymentType(e)}
                />
                <label htmlFor="card">&nbsp; Card</label>
              </div>
              <div className={`col-md-8`}>
                <div className="form-group">
                  <label>Discount List</label>
                  <select
                    className="form-select form-control"
                    name="coupon"
                    onChange={(e) => applyCoupon(e.target.value)}
                    value={selectedDiscount}
                    disabled={type == "update"}
                  >
                    <option value="">Choose Discount</option>
                    {discountList?.length > 0 ? (
                      discountList.map((elem) => (
                        <option key={elem.id} value={elem.id}>
                          {elem.name} ({elem.percentage}%)
                        </option>
                      ))
                    ) : (
                      <option>No result</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        {paymentType === "card" && (
          <div
            style={{
              border: "1px solid #ddd",
              borderRadius: "5px",
              padding: "10px",
            }}
          >
            <CardElement options={cardStyle} />
          </div>
        )}

        <h5 className="my-3 amount_text">
          <strong>Total Amount:</strong> ${totalAmount}
        </h5>
        <h5 className="my-3 amount_text">
          <strong>Discount:</strong> {discount}%
        </h5>
        <h5 className="my-3 amount_text">
          <strong>Final Amount:</strong> ${finalAmount}
        </h5>

        <button
          onClick={handleSubmit}
          style={{
            width: "100%",
            padding: "10px",
            backgroundColor: "#007bff",
            color: "white",
            fontSize: "16px",
            marginTop: "15px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          disabled={loading}
        >
          {loading ? "Processing..." : "Pay Now"}
        </button>
        <button
          onClick={() => {
            ClosePopUp();
            handleStudentTypeOnclose("0");
          }}
          style={{
            width: "100%",
            padding: "10px",
            backgroundColor: "#ccc",
            color: "#333",
            fontSize: "14px",
            marginTop: "10px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default StripePaymentModal;
