import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import {
  RequireAuthAdmin,
  AdminLogout,
  ProtectedRoute,
  AfterLogin,
} from "../Utils/Utils";
import {
  AdminLogin,
  Layout,
  Dashboard,
  Events,
  AddEvent,
  EditEvent,
  AddSubject,
  EditSubject,
  AllSubjects,
  Members,
  CseStudent,
  SttStudent,
  AddMembers,
  NoOfStudent,
  WemgTypeOne,
  WemgTypeTwo,
  LeftFloatingMenu,
  EnvironmentVariable,
  CseBrances,
  Estudy,
  FormativeAttendance,
  EassyWriting,
  UploadOmrSacn,
  AddBrance,
  EditBrance,
  AllTestType,
  AddTestType,
  EditTestType,
  EditCategory,
  AddCategory,
  AllCategory,
  GoogleClass,
  EditGoogleClass,
  AddTeacher,
  AllTeachers,
  EditTeacher,
  AddEassyWriting,
  EditEassyWriting,
  EditMember,
  GoogleClassList,
  AddGrades,
  AllGrade,
  EditGrade,
  UploadFile,
  ClassList,
  AddStudyMaterial,
  EditStudyMaterial,
  AddTerms,
  AllTerms,
  EditTerms,
  AddCourse,
  AllCourses,
  EditCourse,
  AddWebsiteEnv,
  Websit_env_view,
  AllWebsiteEnv,
  AddSubUsers,
  SubUser_List,
  Users,
  Student_enroll_view,
  EnrolledMembers,
  AllLearningDetail,
  AddDisount,
  EditDiscount,
  AllDiscount,
  ViewAttendance,
  Attendance,
  AttendanceView,
  AddState,
  EditState,
  AllStates,
  AllRolePermission,
  Add_category,
  Edit_category,
  All_category,
  AddSubCategory,
  AllSubCategory,
  EditSubCategory,
  TeacherByBranchId,
  EditSubUsers,
  AddTest_type,
  AllTestTypeData,
  EditTest_type,
  EnromentSummary,
  StudentTotalEnrollment,
  StudentYearComparison,
  ClassTotalEnrollment,
  EventBookings,
  AddType_test,
  AllTypeTest,
  EditType_test,
  StudentReportDetail,
  Student_report_view,
} from "../componrnt/pages/Index";
import { AddDays } from "../componrnt/pages/Day/Add";
import AllDays from "../componrnt/pages/Day/All";
import EditDays from "../componrnt/pages/Day/Edit";
import Home from "../componrnt/pages/Home/Home";
import EventView from "../componrnt/pages/Events/EventView";
import AllDeletedRecords from "../componrnt/pages/DeletedRecords/All";

function CreateRoute() {
   localStorage.getItem("userData");
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/admin"
          element={
            <AfterLogin>
              <AdminLogin />
            </AfterLogin>
          }
        />
        <Route element={<RequireAuthAdmin />}>
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <Layout />
              </ProtectedRoute>
            }
          >
            <Route path="dashboard" element={<Dashboard />} />
            {/* marketing and supporting */}
            <Route path="marketing-support/e-study" element={<Estudy />} />
            <Route
              path="marketing-support/formative-attendace"
              element={<FormativeAttendance />}
            />
            <Route
              path="marketing-support/easy-writing"
              element={<EassyWriting />}
            />
            <Route
              path="marketing-support/easy-writing-add"
              element={<AddEassyWriting />}
            />
            <Route
              path="marketing-support/easy-writing-edit/:id"
              element={<EditEassyWriting />}
            />
            <Route
              path="marketing-support/upload-omr-scan"
              element={<UploadOmrSacn />}
            />

            {/* events section */}
            <Route path="administrator-setting/events" element={<Events />} />
            <Route
              path="administrator-setting/events/add"
              element={<AddEvent />}
            />
            <Route
              path="administrator-setting/events/edit/:id"
              element={<EditEvent />}
            />
            <Route
              path="administrator-setting/events/EventView/:id"
              element={<EventView />}
            />
            <Route
              path="administrator-setting/events/bookings/:id"
              element={<EventBookings />}
            />
            {/* class management subject, class list, class details */}
            <Route path="general-setting/subjects" element={<AllSubjects />} />
            <Route
              path="general-setting/subject/add"
              element={<AddSubject />}
            />
            <Route
              path="general-setting/subject/edit/:id"
              element={<EditSubject />}
            />
            <Route path="class-list/google-class" element={<GoogleClass />} />
            <Route
              path="class-list/google-class-list"
              element={<GoogleClassList />}
            />
            <Route
              path="class-list/google-class/edit/:id"
              element={<EditGoogleClass />}
            />
            <Route
              path="class-list/StudyMaterial/add"
              element={<AddStudyMaterial />}
            />
            <Route
              path="class-list/StudyMaterial/edit"
              element={<EditStudyMaterial />}
            />
            {/* student management */}
            <Route path="students/members" element={<Members />} />
            <Route path="students/enrolled" element={<EnrolledMembers />} />
            <Route path="students/add-member" element={<AddMembers />} />
            <Route path="students/member-edit/:id" element={<EditMember />} />
            <Route path="students/cse-student" element={<CseStudent />} />
            <Route path="students/stt-student" element={<SttStudent />} />
            <Route
              path="students/student_enroll_view/:id"
              element={<Student_enroll_view />}
            />
            {/* audit apis */}
            <Route path="audit/no-of-student" element={<NoOfStudent />} />
            <Route path="audit/wemg-type-one" element={<WemgTypeOne />} />
            <Route path="audit/wemg-type-two" element={<WemgTypeTwo />} />
            {/* Test managment */}
            {/*test type */}
            <Route path="test-management/test-type" element={<AllTestType />} />
            <Route path="test-management/test-add" element={<AddTestType />} />
            <Route
              path="test-management/test-edit/:id"
              element={<EditTestType />}
            />
            {/* general setting */}
            <Route
              path="general-setting/left-floating-menu"
              element={<LeftFloatingMenu />}
            />
            <Route
              path="general-setting/environment-variable"
              element={<EnvironmentVariable />}
            />
            <Route
              path="administrator-setting/cse-brances"
              element={<CseBrances />}
            />
            <Route path="administrator-setting/add" element={<AddBrance />} />
            <Route
              path="administrator-setting/edit/:id"
              element={<EditBrance />}
            />

            {/* Teachers management */}
            <Route path="teachers-management/all" element={<AllTeachers />} />
            <Route path="teachers-management/add" element={<AddTeacher />} />
            <Route
              path="teachers-management/edit/:id"
              element={<EditTeacher />}
            />
            <Route path="teachers-management/add" element={<AddTeacher />} />
            <Route
              path="teachers-management/teacher-by-branch"
              element={<TeacherByBranchId />}
            />

            {/* Teachers management */}
            <Route path="deleted-records/all" element={<AllDeletedRecords />} />

            {/* Category */}
            <Route
              path="general-setting/category/add"
              element={<AddCategory />}
            />
            <Route
              path="general-setting/category/edit/:id"
              element={<EditCategory />}
            />
            <Route
              path="general-setting/category/all"
              element={<AllCategory />}
            />

            {/* grades, terms, course */}
            <Route path="general-setting/grade/add" element={<AddGrades />} />
            <Route
              path="general-setting/grade/edit/:id"
              element={<EditGrade />}
            />
            <Route path="general-setting/grades/all" element={<AllGrade />} />
            <Route path="upload/file" element={<UploadFile />} />
            <Route path="class-list/class-List" element={<ClassList />} />

            <Route path="general-setting/term/add" element={<AddTerms />} />
            <Route path="general-setting/term/all" element={<AllTerms />} />
            <Route
              path="general-setting/term/edit/:id"
              element={<EditTerms />}
            />

            <Route path="general-setting/day/add" element={<AddDays />} />
            <Route path="general-setting/day/all" element={<AllDays />} />
            <Route path="general-setting/day/edit/:id" element={<EditDays />} />

            <Route path="course-managment/course/add" element={<AddCourse />} />
            <Route
              path="course-managment/course/add/term"
              element={<AddCourse />}
            />
            <Route
              path="course-managment/course/all"
              element={<AllCourses />}
            />
            <Route
              path="course-managment/course/edits/:id"
              element={<EditCourse />}
            />
            <Route
              path="course-managment/course/edit/:course_id/:id/:course_type_id/:grade_id/:year"
              element={<EditCourse />}
            />

            {/* website Env*/}
            <Route
              path="administrator-setting/websiteEnv/add"
              element={<AddWebsiteEnv />}
            />
            <Route
              path="administrator-setting/websiteEnv/edit/:id"
              element={<AddWebsiteEnv />}
            />
            <Route
              path="administrator-setting/websiteEnv/all"
              element={<AllWebsiteEnv />}
            />
            <Route
              path="administrator-setting/websiteEnv/view/:id"
              element={<Websit_env_view />}
            />
            {/* sub-users*/}
            <Route
              path="administrator-setting/sub-users/add"
              element={<AddSubUsers />}
            />
            <Route
              path="administrator-setting/sub-users/edit/:id"
              element={<EditSubUsers />}
            />
            <Route
              path="administrator-setting/sub-users/all"
              element={<SubUser_List />}
            />
            {/* All users*/}
            <Route path="administrator-setting/users" element={<Users />} />

            <Route path="viewAttendance/:id" element={<ViewAttendance />} />

            {/* Steps of learning */}
            <Route path="steps-of-learning" element={<AllLearningDetail />} />

            {/* Discounte */}
            <Route
              path="general-setting/discount/add"
              element={<AddDisount />}
            />
            <Route
              path="general-setting/discount/edit/:id"
              element={<EditDiscount />}
            />
            <Route
              path="general-setting/discount/all"
              element={<AllDiscount />}
            />
            {/* Reports */}
            {/* student reports */}
            <Route
              path="/admin/reports/student-enrollment-summary"
              element={<EnromentSummary />}
            />
            <Route
              path="/admin/reports/student-enrollment-summary/total-student-enrollment"
              element={<StudentTotalEnrollment />}
            />
            <Route
              path="/admin/reports/student-enrollment-summary/year-to-year-comparison"
              element={<StudentYearComparison />}
            />
              <Route
              path="/admin/reports/student-detail"
              element={<StudentReportDetail />}
            />
                <Route
              path="/admin/reports/student-detail-view/:id"
              element={<Student_report_view />}
            />
            {/* class reports */}
            <Route
              path="/admin/reports/class-enrollment-summary"
              element={<EnromentSummary />}
            />
            <Route
              path="/admin/reports/student-enrollment-summary/class-student-enrollment"
              element={<ClassTotalEnrollment />}
            />
            {/* Attendance */}
            <Route path="students/attendance" element={<Attendance />} />
            <Route
              path="students/attendance/view/:id"
              element={<AttendanceView />}
            />


            {/* Category and SubCategory */}
            <Route
              path="general-setting/Categories/add"
              element={<Add_category />}
            />
            <Route
              path="general-setting/Categories/edit/:id"
              element={<Edit_category />}
            />
            <Route
              path="general-setting/Categories/all"
              element={<All_category />}
            />

            <Route
              path="general-setting/sub-category/add"
              element={<AddSubCategory />}
            />
            <Route
              path="general-setting/sub-category/edit/:id"
              element={<EditSubCategory />}
            />
            <Route
              path="general-setting/sub-category/all"
              element={<AllSubCategory />}
            />
            {/* State */}
            <Route
              path="administrator-setting/state/add"
              element={<AddState />}
            />
            <Route
              path="administrator-setting/state/edit/:id"
              element={<EditState />}
            />
            <Route
              path="administrator-setting/state/all"
              element={<AllStates />}
            />

            {/* Role permission*/}
            <Route
              path="administrator-setting/role-permission/add"
              element={<AddWebsiteEnv />}
            />
            {/* <Route path="administrator-setting/websiteEnv/edit/:id" element={<AddWebsiteEnv />} /> */}
            <Route
              path="administrator-setting/role-permission/all"
              element={<AllRolePermission />}
            />
            {/* <Route path="administrator-setting/websiteEnv/view/:id" element={<Websit_env_view />} /> */}
            {/* test type */}
            <Route
              path="test-management/test-type/add"
              element={<AddTest_type />}
            />
            <Route
              path="test-management/test-type/edit/:id"
              element={<EditTest_type />}
            />
            <Route
              path="test-management/test-type/all"
              element={<AllTestTypeData />}
            />
            {/*type test */}
            <Route
              path="general-setting/add-test-type/add"
              element={<AddType_test />}
            />
            <Route
              path="general-setting/add-test-type/all"
              element={<AllTypeTest />}
            />
            <Route
              path="general-setting/add-test-type/edit/:id"
              element={<EditType_test />}
            />
          </Route>

          <Route path="logoutAdmin" element={<AdminLogout />} />
          {/* <Route path="*" element={<NotFound404 />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default CreateRoute;
