import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataLoader from "../../../../Utils/DataLoader";
import {
  all_type_test,
  Type_test_destroy,
} from "../../../../apis/generalSetting/testType";
import CustomTable from "../../CustomTable";
import { editRestrict, getUserPermission } from "../../../../Utils/Utils";

const AllTypeTest = () => {
  const [grade, setGrade] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const permissions = getUserPermission("Grades");
  const UserID = JSON.parse(localStorage.getItem("userData"))?.data?.id;
  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: false,
    },
    {
      name: "Name",
      selector: "name",
      sortable: false,
    },
    {
      name: "Status",
      selector: "status",
      sortable: false,
    },
    permissions?.edit === 1 || permissions?.delete === 1
      ? {
        name: "Action",
        selector: "action",
        sortable: false,
      }
      : "",
  ];
  useEffect(() => {
    async function get_data() {
      const result = await all_type_test();
      const arrayData = result?.data?.map((elem, index) => {
        const { id, name, delete_status, status } = elem;
        return {
          no: `#${index + 1}`,
          name: name ? name : "-",
          status_data : status,
          status: (
            <select
              className="form-select"
              onChange={(e) => destroy("status", id, e.target.value)}
              value={status}
            >
              <option value={1}>Active</option>
              <option value={0}>Inactive</option>
            </select>
          ),
          action: (
            <div data-tag="allowRowEvents">
              {permissions?.edit === 1 ? (
                <Link
                  className="edit-btn"
                  to={`${`/admin/general-setting/add-test-type/edit/${id}`
                    }`}
                >
                  {" "}
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </Link>
              ) : (
                ""
              )}
            </div>
          ),
        };
      });
      setGrade(arrayData, setShowResults(true));
    }
    const destroy = async (type, id, status, name) => {
      const status_payload = { status: status }
      const delete_status_payload = { delete_status: status, title: name, deleted_by: UserID };
      const result = window.confirm(
        `${status == 1
          ? `Are you sure you want to ${type == 'status' ? 'active' : 'delete'} this test type?`
          : `Are you sure you want to ${type == 'status' ? 'inactive' : 'restore'} this test type?`
        }`
      );
      if (result) {
        await Type_test_destroy(id, type == 'status' ? status_payload : delete_status_payload);
      }
      get_data();
    };
    get_data();
  }, []);
  return (
    <>
      <div className="modal-content">
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="right-nav" id="dash-event">
              {showResults ? (
                <>
                  <div className="row">
                    <div className="col-md-4">
                      <h4 className="modal-title">Test Type List</h4>
                    </div>
                    <div className="col-md-8">
                      {permissions?.add === 1 ? (
                        <Link
                          className="ui button float-end"
                          to="/admin/general-setting/add-test-type/add"
                        >
                          Add Test Type
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <CustomTable columns={columns} data={grade} />
                </>
              ) : (
                <DataLoader />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AllTypeTest;
