import { toast } from "react-toastify";
import { HTTPURL } from '../../constants/Matcher';
export async function get_student_list_attendance(id) {
    try {
        const response = await fetch(HTTPURL + `api/teacher/get-student-class-attendance/?id=${id}`);
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function get_student_attendance_date(date) {
    try {
        const response = await fetch(HTTPURL + `api/teacher/get-attendance/?date=${date}`);
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}



// add 
export async function submit_attendance(data)
{
    try {
        const response = await fetch(HTTPURL + 'api/teacher/add-attendance', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}



export async function get_student_attendance(branch_id,date,class_id,course_id,class_timing) {
    try {
        const response = await fetch(HTTPURL + `api/branches/student-attendance/?branch_id=${branch_id}&date=${date}&class_id=${class_id}&course_id=${course_id}&class_timing=${class_timing}`);
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// get course by branch id
export async function get_classList_By_branchId(id) {
    try {
        const response = await fetch(HTTPURL + `api/branches/branch-classes-list?branch_id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// mark student attendance
export async function mark_student_attendance(date,data)
{
    try {
        const response = await fetch(HTTPURL + `api/branches/mark-attendance?date=${date}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}