import React, { useState, useEffect } from "react";
import { Form, Button } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  subject_add,
  subject_edit,
  subject_update,
} from "../../../../apis/ClassManagement/Subjects.jsx";
import { get_Category } from "../../../../apis/Catergory.jsx";
import { course_all } from "../../../../apis/Course.jsx";
import { handleNavigation } from "../../../../Utils/Utils.js";
import { get_SubCategory_by_id } from "../../../../apis/Catergory.jsx";
const AddSubject = () => {
  const [courseList, setCourseList] = useState([]);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm();
  const category_id = watch("category_id");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetch_data() {
      const course = await course_all();
      const category = await get_Category();
      setCourseList(course?.data);
      setCategoryList(category?.data);
    }
    fetch_data();
  }, []);
  async function fetch_by_id(id) {
    const subCategory = await get_SubCategory_by_id(id);
    setSubCategoryList(subCategory?.data);
  }
  useEffect(() => {
    if (category_id) {
      fetch_by_id(category_id);
    }
  }, [category_id]);

  const onSubmit = async (data) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    let result = await subject_add(data);
    if (result.status) {
      return navigate("/admin/general-setting/subjects");
    }
    setIsSubmitting(false);
  };
  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Add Subject</h4>
          <Link
            className="edit-btn"
            to={`/admin/general-setting/subjects`}
            onClick={(e) => {
              handleNavigation(e, isDirty);
            }}
          >
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
          </Link>
        </div>
        <div className="modal-body">
          <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Subject *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Subject"
                    {...register("subject", { required: true })}
                  />
                  {errors.subject && errors.subject.type === "required" && (
                    <span className="validation-error">This is required</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Category</label>
                  <select
                    className="form-select form-control"
                    name="category"
                    {...register("category_id", { required: true })}
                  >
                    <option value="">Choose Category</option>
                    {categoryList?.length > 0 ? (
                      categoryList &&
                      categoryList?.map((elem) => {
                        return <option value={elem?.id}>{elem?.name}</option>;
                      })
                    ) : (
                      <option>No result</option>
                    )}
                  </select>
                  {errors.category_id &&
                    errors.category_id.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Sub Category</label>
                  <select
                    className="form-select form-control"
                    name="category"
                    {...register("subcategory_id", { required: true })}
                  >
                    <option value="">Choose Category</option>
                    {subCategoryList?.length > 0 ? (
                      subCategoryList &&
                      subCategoryList?.map((elem) => {
                        return <option value={elem?.id}>{elem?.name}</option>;
                      })
                    ) : (
                      <option>No result</option>
                    )}
                  </select>
                  {errors.subCategory_id &&
                    errors.subCategory_id.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Status</label>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      {...register("status", { required: true })}
                      id="inlineRadio1"
                      value="1"
                      defaultChecked
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      Active
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      {...register("status", { required: true })}
                      id="inlineRadio2"
                      value="0"
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      Inactive
                    </label>
                  </div>
                </div>
                {errors.status && errors.status.type === "required" && (
                  <span className="validation-error">This is required</span>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <Button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

const EditSubject = () => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm();
  const navigate = useNavigate();
  const id = useParams();
  const category_id = watch("category_id");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  useEffect(() => {
    async function fetch_data() {
      const category = await get_Category();
      setCategoryList(category?.data);
    }
    fetch_data();
  }, []);
  async function fetch_by_id(id) {
    const subCategory = await get_SubCategory_by_id(id);
    setSubCategoryList(subCategory?.data);
  }
  useEffect(() => {
    if (category_id) {
      fetch_by_id(category_id);
    }
  }, [category_id]);
  const onSubmit = async (data) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    let result = await subject_update(id, data);
    if (result.status) {
      return navigate("/admin/general-setting/subjects");
    }
    setIsSubmitting(false);
  };
  const fetchData = async () => {
    let res = await subject_edit(id);
    setValue("subject", res?.data[0]?.subject);
    setValue("status", res?.data[0]?.status.toString());
    setValue("course_id", res?.data[0]?.course_id?.toString());
    setValue("category_id", res?.data[0]?.category_id?.toString());
    const subCategory = await get_SubCategory_by_id(
      res?.data[0]?.category_id?.toString()
    );
    setSubCategoryList(subCategory?.data);
    setTimeout(()=>{
      setValue("subcategory_id", res?.data[0]?.subcategory_id?.toString());
    },600)
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Modify Subject</h4>
          <Link
            className="edit-btn"
            to={`/admin/general-setting/subjects`}
            onClick={(e) => handleNavigation(e, isDirty)}
          >
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
          </Link>
        </div>
        <div className="modal-body">
          <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Subject *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Subject"
                    {...register("subject", { required: true })}
                  />
                </div>
                {errors.subject && errors.subject.type === "required" && (
                  <span className="validation-error">This is required</span>
                )}
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Category</label>
                  <select
                    className="form-select form-control"
                    name="category"
                    {...register("category_id", { required: true })}
                  >
                    <option value="">Choose Category</option>
                    {categoryList?.length > 0 ? (
                      categoryList &&
                      categoryList?.map((elem) => {
                        return <option value={elem?.id}>{elem?.name}</option>;
                      })
                    ) : (
                      <option>No result</option>
                    )}
                  </select>
                  {errors.category_id &&
                    errors.category_id.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Sub Category</label>
                  <select
                    className="form-select form-control"
                    name="category"
                    {...register("subcategory_id", { required: true })}
                  >
                    <option value="">Choose Category</option>
                    {subCategoryList?.length > 0 ? (
                      subCategoryList &&
                      subCategoryList?.map((elem) => {
                        return <option value={elem?.id}>{elem?.name}</option>;
                      })
                    ) : (
                      <option>No result</option>
                    )}
                  </select>
                  {errors.subCategory_id &&
                    errors.subCategory_id.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Status</label>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      {...register("status", { required: true })}
                      id="inlineRadio1"
                      value="1"
                      defaultChecked
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      Active
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      {...register("status", { required: true })}
                      id="inlineRadio2"
                      value="0"
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      Inactive
                    </label>
                  </div>
                </div>
                {errors.status && errors.status.type === "required" && (
                  <span className="validation-error">This is required</span>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <Button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export { AddSubject, EditSubject };
