import { toast } from "react-toastify";
import { HTTPURL } from "../constants/Matcher";


// add discout
export async function add_State(data) {
    try {
        const response = await fetch(HTTPURL + 'api/state-add  ', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status) 
                {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all discout
export async function all_States() {
    try {
        const response = await fetch(HTTPURL + 'api/state-alll');
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;  
    }
}

// discout edit 
export async function edit_state(id)
{
    try {
        const response = await fetch(HTTPURL + `api/state-edit?id=${id?.id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// discout update 
export async function update_state(id, data)
{
    try {
        const response = await fetch(HTTPURL + `api/state-update/?id=${id?.id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// discout delete
export async function destroy_state(id,data) 
{
    try {
        const response = await fetch(HTTPURL + `api/state-distroy?id=${id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
