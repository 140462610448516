import React, { useState, useEffect } from "react";
import { get_states, get_terms } from "../../../../apis/Common";
import { state_branch_all } from "../../../../apis/Branches";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import { course_all } from "../../../../apis/Course";
import { get_all_year_data } from "../../../../branches/api/common";
import { student_report_detail } from "../../../../apis/reports/Reports";
import CustomTable from "../../CustomTable";
import DataLoader from "../../../../Utils/DataLoader";

const StudentReportDetail = () => {
  const location = useLocation();
  const path = location?.pathname === "/admin/reports/class-enrollment-summary";

  const { register, setValue, handleSubmit, watch } = useForm();

  const [statelist, setStateList] = useState([]);
  const [branchlist, setBranchList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [termList, setTermList] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);
      const [stateList, courseData, yearData, termData, defaultData] =
        await Promise.all([
          get_states(),
          course_all(),
          get_all_year_data(),
          get_terms(),
          student_report_detail(),
        ]);

      setStateList(stateList?.data);
      setCourseList(courseData?.data);
      setYearList(yearData?.data);
      setTermList(termData?.data);

      // Load default data into the table
      formatAndSetData(defaultData?.data);
      setLoading(false);
    };

    fetchInitialData();
  }, []);

  const formatAndSetData = (rawData) => {
    const formattedData = rawData?.map((elem, index) => ({
      no: `#${index + 1}`,
      name: elem.full_name || '-',
      year: elem.year || '-',
      state: elem.state_name || '-',
      branch: elem.branch_name || '-',
      course: elem.course_name || '-',
      action: (
        <div data-tag="allowRowEvents">
          <Link
            className="edit-btn"
            to={`/admin/reports/student-detail-view/${elem.user_id}`}
          >
            <i className="fa fa-eye" aria-hidden="true"></i>
          </Link>
        </div>
      ),
    }));

    setData(formattedData);
  };

  const handleStateChange = async (event) => {
    const selectedState = event.target.value;
    setValue("state", selectedState);

    if (selectedState) {
      const data = await state_branch_all(selectedState);
      setBranchList(data?.data);
    } else {
      setBranchList([]);
    }
  };

  const onSubmit = async (formData) => {
    setLoading(true);

    const { year, branch, course_id, state, term_id } = formData;
    const filteredData = await student_report_detail(
      year,
      branch,
      course_id,
      state,
      term_id
    );

    formatAndSetData(filteredData?.data);
    setLoading(false);
  };

  const columns = [
    { name: "No.", selector: "no", sortable: false },
    { name: "Name", selector: "name", sortable: false },
    { name: "Year", selector: "year", sortable: false },
    { name: "State", selector: "state", sortable: false },
    { name: "Branch", selector: "branch", sortable: false },
    { name: "Course", selector: "course", sortable: false },
    { name: "Attendance", selector: "action", sortable: false },
  ];

  return (
    <div className="row">
      <div className="modal-content">
        <div className="tab-content" id="pills-tabContent">
          <div className="tab-pane fade show active">
            <div className="right-nav" id="dash-event">
              <div className="col-md-4">
                <h4 className="modal-title">
                   Student Detail
                </h4>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      {/* Year */}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Year</label>
                          <select
                            className="form-select form-control"
                            {...register("year")}
                          >
                            <option value="">Choose Year</option>
                            {yearList?.map((elem) => (
                              <option key={elem.id} value={elem.id}>
                                {elem.current_year}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {/* State */}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>State</label>
                          <select
                            className="form-select form-control"
                            {...register("state")}
                            onChange={handleStateChange}
                          >
                            <option value="">Choose State</option>
                            {statelist?.map((elem) => (
                              <option key={elem.id} value={elem.id}>
                                {elem.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {/* Branch */}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Branch</label>
                          <select
                            className="form-select form-control"
                            {...register("branch")}
                          >
                            <option value="">Choose Branch</option>
                            {branchlist?.map((elem) => (
                              <option key={elem.id} value={elem.id}>
                                {elem.branch_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {/* Course */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Course</label>
                          <select
                            className="form-select form-control"
                            {...register("course_id")}
                          >
                            <option value="">Choose Course</option>
                            {courseList?.map((elem) => (
                              <option key={elem.id} value={elem.id}>
                                {elem.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {/* Term */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Term</label>
                          <select
                            className="form-select form-control"
                            {...register("term_id")}
                          >
                            <option value="">Choose Term</option>
                            {termList?.map((elem) => (
                              <option key={elem.id} value={elem.id}>
                                {elem.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary"  disabled={loading}>
                    {loading ? "Loading..." : "Search"}
                    </button>
                  </div>
                </div>
              </form>
              {/* Show Results */}
              {loading ? (
                <DataLoader />
              ) : (
                <CustomTable columns={columns} data={data} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentReportDetail;
