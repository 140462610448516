import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import DataLoader from "../../../Utils/DataLoader";
import { event_bookings } from "../../../apis/Events";
import CustomTable from "../CustomTable";

const EventBookings = () => {
  const [grade, setGrade] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const id = useParams("");
  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: false,
    },
    // {
    //   name: "Event",
    //   selector: "event",
    //   sortable: false,
    // },
    {
      name: "Name",
      selector: "name",
      sortable: false,
    },
    {
      name: "Mobile",
      selector: "mobile",
      sortable: false,
    },
    {
      name: "Email",
      selector: "email",
      sortable: false,
    },
    {
      name: "Child Name",
      selector: "child_name",
      sortable: false,
    },
    {
      name: "Event type",
      selector: "event_type",
      sortable: false,
    },
  ];
  useEffect(() => {
    async function get_data() {
      const result = await event_bookings(id?.id);
      const arrayData = result?.data?.map((elem, index) => {
        const {
          id,
          first_name,
          last_name,
          mobile,
          email,
          child_name,
          event_type,
          event_name,
          event_id,
        } = elem;
        return {
          no: `#${index + 1}`,
          name: `${first_name} ${last_name}`,
          mobile: mobile,
          email: email,
          child_name: child_name,
          event_type: event_type,
          event: (
            <Link
              className="text-black"
              to={`/admin/administrator-setting/events/EventView/${event_id}`}
            >
              {event_name}
            </Link>
          ),
        };
      });
      setGrade(arrayData, setShowResults(true));
    }
    get_data();
  }, []);
  return (
    <>
      <div className="modal-content">
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="right-nav" id="dash-event">
              {showResults ? (
                <>
                  <div className="row">
                    {/* <div className="col-md-4">
                      <h4 className="modal-title">Event booking list</h4>
                      <div className="modify-right">
                        <Link
                          className="edit-btn"
                          to={`/admin/administrator-setting/events`}
                        >
                          <i class="fa fa-arrow-left" aria-hidden="true"></i>
                        </Link>
                      </div>
                    </div> */}
                    <div className="col-md-12">
                      <div className="sub-users">
                        <h4 className="modal-title">Event booking list</h4>
                        <Link
                          className="edit-btn"
                          to={`/admin/administrator-setting/events`}
                        >
                          <i class="fa fa-arrow-left" aria-hidden="true"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <CustomTable columns={columns} data={grade} />
                </>
              ) : (
                <DataLoader />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EventBookings;
