import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataLoader from "../../../../Utils/DataLoader.jsx";
import {
  subject_all,
  subject_destroy,
} from "../../../../apis/ClassManagement/Subjects.jsx";
import CustomTable from "../../CustomTable.jsx";
import { editRestrict, getUserPermission } from "../../../../Utils/Utils.js";

const AllSubjects = () => {
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const permissions = getUserPermission("Subjects");
  const UserID = JSON.parse(localStorage.getItem("userData"))?.data?.id;
  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: true,
    },
    {
      name: "Subject",
      selector: "subject",
      sortable: true,
    },
    {
      name: "Category",
      selector: "category",
      sortable: true,
    },
    {
      name: "SubCategory",
      selector: "subcategory",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: false,
    },
    permissions?.edit === 1 || permissions?.delete === 1
      ? {
          name: "Action",
          selector: "action",
        }
      : "",
  ];
  useEffect(() => {
    async function get_data() {
      const result = await subject_all();
      const arrayData = result?.data?.map((elem, index) => {
        const { id, subject, category, subcategory, status } = elem;
        return {
          no: `#${index + 1}`,
          subject: subject,
          status_data: status,
          category: category || '-',
          subcategory: subcategory || '-',
          status: (
            <select
              className="form-select"
              onChange={(e) => destroy("status", id, e.target.value)}
              value={status}
            >
              <option value={1}>Active</option>
              <option value={0}>Inactive</option>
            </select>
          ),
          action: (
            <div data-tag="allowRowEvents">
              {permissions?.edit === 1 ? (
                <Link
                  class="edit-btn"
                  to={`/admin/general-setting/subject/edit/${id}`}
                >
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </Link>
              ) : (
                ""
              )}
            </div>
          ),
        };
      });
      setData(arrayData, setShowResults(true));
    }
    const destroy = async (type, id, status, name) => {
      const status_payload = { status: status };
      const result = window.confirm(
        `${
          status == 1
            ? `Are you sure you want to ${
                type == "status" ? "active" : "delete"
              } this subject ?`
            : `Are you sure you want to ${
                type == "status" ? "inactive" : "restore"
              } this subject ?`
        }`
      );
      if (result) {
        await subject_destroy(id, status_payload);
        get_data();
      }
    };
    get_data();
  }, []);

  return (
    <div className="right-nav" id="dash-event">
      {showResults ? (
        <>
          <div className="row">
            <div className="col-md-4">
              <h4 className="modal-title">Subject List</h4>
            </div>
            <div className="col-md-8">
              {permissions?.add === 1 ? (
                <Link
                  className="ui button float-end"
                  to="/admin/general-setting/subject/add"
                >
                  Add Subject
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
          <CustomTable columns={columns} data={data} />
        </>
      ) : (
        <DataLoader />
      )}
    </div>
  );
};

export default AllSubjects;
