import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { RequireAuthTeacher, TeacherLogout, ProtectedRoute, AfterLogin } from '../../Utils/Utils';
import { TeacherLogin, TeacherLayout, Dashboard, Attendance } from '../components/pages/Index';
import { Addclass, AllClassList, EditClassList } from '../components/pages/Index';
import { AddSubject, AllSubjects, EditSubject } from '../components/pages/Index';
import { AddHomeWork, AllHomeWork, EditHomeWork } from '../components/pages/Index';
import {ClassView } from '../components/pages/Index';
import {GoogleClassAdd} from '../components/pages/Index';
import {Forgotpassword} from '../components/pages/Index';
import {ResetPassword}from '../components/pages/Index';

function TeacherRoute() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/teacher" element={<AfterLogin><TeacherLogin /></AfterLogin>} />
                <Route path="/teacher/forgot-password" element={<AfterLogin><Forgotpassword /></AfterLogin>} />
                <Route path="/teacher/reset-password/:token" element={<AfterLogin><ResetPassword /></AfterLogin>} />
                <Route element={<RequireAuthTeacher />}>
                    <Route path="/teacher" element={<ProtectedRoute><TeacherLayout /></ProtectedRoute>} >
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="/teacher/class/add" element={<Addclass />} />
                        <Route path="/teacher/class-list" element={<AllClassList />} />
                        <Route path="/teacher/class/edit/:id" element={<EditClassList />} />
                        <Route path="/teacher/class/View/:id" element={<ClassView />} />
                        <Route path="/teacher/subject/add" element={<AddSubject />} />
                        <Route path="/teacher/subject-list" element={<AllSubjects />} />
                        <Route path="/teacher/subject/edit/:id" element={<EditSubject />} />
                         <Route path="/teacher/class/Addgoogle-class" element={<GoogleClassAdd />} />
                        <Route path="/teacher/home-work/add" element={<AddHomeWork />} />
                        <Route path="/teacher/home-work-list" element={<AllHomeWork />} />
                        <Route path="/teacher/home-work/edit/:id" element={<EditHomeWork />} />
                        <Route path="/teacher/student-attendance"  element={<Attendance />}/>
                        {/* forgot Password */}
                       
                    </Route>
                    <Route path="/logout" element={<TeacherLogout />} />
                </Route>    
              
            </Routes>
        </BrowserRouter>
    )
    }

export default TeacherRoute;