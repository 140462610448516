import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataLoader from "../../../Utils/DataLoader";
import { get_days, day_destroy } from "../../../apis/Common";
import CustomTable from "../CustomTable";
import EditDays from "./Edit";
import { editRestrict, getUserPermission } from "../../../Utils/Utils";
const AllDays = () => {
  const [showResults, setShowResults] = useState(false);
  const [day, setDay] = useState([]);
  const [status, setStatus] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [duplicateItemId, setDuplicateItemId] = useState(null);
  const permissions = getUserPermission("Days");
  const UserID = JSON.parse(localStorage.getItem("userData"))?.data?.id;
  const columns1 = [
    {
      name: "No.",
      selector: "no",
      sortable: false,
    },
    {
      name: "Day",
      selector: "name",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    permissions?.edit === 1 || permissions?.delete === 1
      ? {
          name: "Action",
          selector: "action",
        }
      : "",
  ];
  useEffect(() => {
    async function get_data() {
      const result = await get_days();
      const arrayData = result?.data?.map((elem, index) => {
        const { id, name, delete_status,status } = elem;
        return {
          no: `#${index + 1}`,
          name: name,
          status_data : status,
          status: (
            <select
              className="form-select"
              onChange={(e) => destroy('status',id, e.target.value)}
              value={status}
            >
              <option value={1}>Active</option>
              <option value={0}>Inactive</option>
            </select>
          ),
          action: (
            <div data-tag="allowRowEvents">
              {permissions?.edit === 1 ? (
                <button
                onClick={() => {
                    setDuplicateItemId(id);
                    setIsModalOpen(true);
                    setStatus("day");
                }}                
                  className="dlt-btn"
                  data-bs-toggle='modal'
                  data-bs-target="#staticBackdrop"
                >
                  {" "}
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </button>
              ) : (
                ""
              )}
            </div>
          ),
        };
      });
      setDay(arrayData, setShowResults(true));
    }
    const destroy = async (type,id, status, name) => {      
      const status_payload={status:status}
      const delete_status_payload = { delete_status: status, title: name, deleted_by: UserID };
      const result = window.confirm(
        `${
          status == 1
            ? `Are you sure you want to ${type == 'status' ? 'active' : 'delete'} this day?`
            : `Are you sure you want to ${type == 'status' ? 'inactive' : 'restore'} this day? `
        }`
      );
      if (result) {
        await day_destroy(id, type == 'status' ? status_payload : delete_status_payload);
      }
      get_data();
    };
    get_data();
  }, []);
  return (
    <div className="modal-content">
      <div className="modal-body">
        <div className="right-nav" id="dash-event">
          {showResults ? (
            <>
              <div className="row">
                <div className="col-md-4">
                  <h4 className="modal-title">Days List</h4>
                </div>
                <div className="col-md-8">
                  {permissions?.add === 1 ? (
                    <Link
                      className="ui button float-end"
                      to="/admin/general-setting/day/add"
                    >
                      Add Day
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <CustomTable columns={columns1} data={day} />
            </>
          ) : (
            <DataLoader />
          )}
        </div>
      </div>
      <EditDays
        duplicateItemId={duplicateItemId}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        status={status}
        setStatus={setStatus}
      />
    </div>
  );
};
export default AllDays;
