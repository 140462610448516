import React from "react";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { enroll_student_payment } from "../../../api/Student_Enroll";
import { useNavigate } from "react-router-dom";
import { student_enroll_list } from "../../../../apis/studentManagement/StudentManagement";
import EnrolmentStatus from "../../../../componrnt/pages/studentManagement/EnrolmentStatus";
import { formatDate, handleNavigation } from "../../../../Utils/Utils";
const Student_enroll_view = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();
  const [enrollView, setEnrollView] = useState([]);
  const [subject, setSubject] = useState([]);
  const [term, setTerm] = useState([]);
  const { id } = useParams();
  console.log(id);

  const navigate = useNavigate();
  const localData = localStorage.getItem("userData");
  const BranchData = JSON.parse(localData);
  useEffect(() => {
    async function get_dataa() {
      const data = await student_enroll_list(id);
      console.log("finaldata", data?.data);
      setEnrollView(data?.data);
      if (data?.data?.termWeekData) {
        setSubject(JSON.parse(data?.data?.termWeekData?.subjectJson));
        setTerm(data?.data?.termWeekData?.term_id.split(","));
      }
    }
    get_dataa();
  }, []);

  const submitData = async (data) => {
    data.grade_id = enrollView?.grade_id;
    data.user_id = enrollView?.user_id;
    data.total_price = 10;
    data.subject_id = enrollView?.subject_id;
    data.branch_id = BranchData?.data?.b_id;
    const res = await enroll_student_payment(data);
    if (res?.status) {
      return navigate("/branch/free_student_list");
    }
  };
  console.log("enrollView", enrollView);
  const updataData = (newData) => {
    setEnrollView(newData);
  };
  return (
    <div>
      {/* <h4 className="modal-title">Student Enrolled</h4> */}
      <div className="right-nav" id="dash-event">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Student Detail</h4>
            {enrollView?.student_type === "1" ? (
              <Link
                className="edit-btn"
                to={"/branch/enroll/student_enroll-list"}
                onClick={(e) => {
                  handleNavigation(e, isDirty);
                }}
              >
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
              </Link>
            ) : (
              <Link
                className="edit-btn"
                to={"/branch/enroll/student_enroll-list"}
              >
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
              </Link>
            )}
          </div>
          <div className="modal-body">
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="form-horizontal">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>First Name</label>
                        <p>
                          {enrollView?.first_name
                            ? enrollView?.first_name
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Last Name</label>
                        <p>
                          {enrollView?.last_name ? enrollView?.last_name : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Email</label>
                        <p className="text-lowercase">{enrollView?.email ? enrollView?.email : "-"}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Date of birth</label>
                        <p>
                          {enrollView?.date_of_birth
                            ? formatDate(enrollView?.date_of_birth)
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Branch</label>
                        <p>
                          {enrollView?.branch_name
                            ? enrollView?.branch_name
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Grade</label>
                        <p>Year {enrollView?.grade ? enrollView?.grade : "-"}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Course</label>
                        <p>{enrollView?.course ? enrollView?.course : "-"}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Term</label>
                        <p>
                          {" "}
                          {enrollView?.term
                            ?.split(",")
                            .map((term, index, array) => (
                              <span
                                key={index}
                                style={
                                  index === array.length - 1
                                    ? { fontWeight: "bold", color: "red" }
                                    : {}
                                }
                              >
                                {term}
                                {index < array.length - 1 ? ", " : ""}
                              </span>
                            ))}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Course Type</label>
                        <p>{enrollView?.course_type ? enrollView?.course_type : "-"}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>School</label>
                        <p>{enrollView?.school ? enrollView?.school : "-"}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Student Type</label>
                        <p>
                          {enrollView?.student_type == 1
                            ? "Enroll"
                            : "Free"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Payment Details</h4>
              </div>
              <div className="modal-body">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="form-horizontal">
                      {
                        enrollView?.payment_info?.map((elem) => (
                          <div className="row">
                            <div className="col-md-2">
                              <div className="form-group">
                                <label>Course</label>
                                <p>
                                  {elem?.course_name ? elem?.course_name : "-"}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-group">
                                <label>Term</label>
                                <p>
                                  {elem?.term ? elem?.term : "-"}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-group">
                                <label>Payment Type</label>
                                <p>{elem?.payment_type ? elem?.payment_type : "-"}</p>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-group">
                                <label>Discount</label>
                                <p>{elem?.coupon_discount ? elem?.coupon_discount : "-"}%</p>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-group">
                                <label>Amount</label>
                                <p>{elem?.amount ? elem?.amount : "-"}</p>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-group">
                                <label>Payment Id</label>
                                <p className="payment-id">{elem?.payment_id ? elem?.payment_id : "-"}</p>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Contact Details</h4>
              </div>
              <div className="modal-body">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="form-horizontal">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Address</label>
                            <p>
                              {enrollView?.address ? enrollView?.address : "-"}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>City</label>
                            <p>{enrollView?.city ? enrollView?.city : "-"}</p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>State</label>
                            <p>
                              {enrollView?.state_name
                                ? enrollView?.state_name
                                : "-"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Parent's Personal Details</h4>
              </div>
              <div className="modal-body">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="form-horizontal">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Parent's First Name</label>
                            <p>
                              {enrollView?.p_first_name
                                ? enrollView?.p_first_name
                                : "-"}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Parent's Last Name</label>
                            <p>
                              {enrollView?.p_last_name
                                ? enrollView?.p_last_name
                                : "-"}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Parent's Email</label>
                            <p className="text-lowercase">
                              {enrollView?.p_email ? enrollView?.p_email : "-"}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Parent's Mobile</label>
                            <p>
                              {enrollView?.mother_mobilenumber &&
                                enrollView?.mother_mobilenumber !== "null"
                                ? enrollView?.mother_mobilenumber
                                : "-"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <hr /> */}
            {/* {enrollView?.student_type == "0" ? (
              <form onSubmit={handleSubmit(submitData)}>
                <div className="row enrolled">
                  <hr />
                  <div className="modal-header">
                    <h4 className="modal-title">Shift to Enrolled</h4>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group ">
                      <label>Branch</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="branch"
                        value={enrollView?.branch_name}
                        // {...register("branch_id")}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="enrolled">Enrolled Status: </label>
                      <div className="highlight_inner">
                        <div className=" checkbox">
                          <label>
                            <input
                              type="checkbox"
                              name="student_type"
                              {...register("student_type", { required: true })}
                              id="enrolled"
                              value="1"
                              required
                            />{" "}
                            Yes{" "}
                          </label>
                        </div>
                      </div>
                      {errors.student_type &&
                        errors.student_type.type === "required" && (
                          <span className="validation-error">
                            This is required
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="enrolled">Payment Method </label>
                      <div className="highlight_inner">
                        <div className=" checkbox">
                          <label>
                            <input
                              type="radio"
                              name="payment_method"
                              id="enrolled"
                              {...register("payment_method", {
                                required: true,
                              })}
                              value="cash"
                            />{" "}
                            Cash{" "}
                          </label>
                        </div>
                        <div className=" checkbox">
                          <label>
                            <input
                              type="radio"
                              name="payment_method"
                              id="enrolled"
                              value="online"
                              {...register("payment_method", {
                                required: true,
                              })}
                            />{" "}
                            Online{" "}
                          </label>
                        </div>
                        <div className=" checkbox">
                          <label>
                            <input
                              type="radio"
                              name="payment_method"
                              id="enrolled"
                              value="payLater"
                              {...register("payment_method", {
                                required: true,
                              })}
                            />{" "}
                            Pay Later{" "}
                          </label>
                        </div>
                      </div>
                      {errors.payment_method &&
                        errors.payment_method.type === "required" && (
                          <span className="validation-error">
                            This is required
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <button type="submit" className="btn button">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            ) : (
              ""
            )} */}
            {/* <EnrolmentStatus subject={subject} term={term}  onUpdate={updataData} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Student_enroll_view;
