// import React, { useState, useEffect, useRef } from "react";
// import { Link } from "react-router-dom";
// import DataLoader from "../../../../Utils/DataLoader";
// import CustomTable from "../CustomTable";
// import { getUserPermission } from "../../../../Utils/Utils";
// import {
//   get_classList_By_branchId,
//   get_student_attendance,
//   mark_student_attendance,
// } from "../../../api/Attendance";
// import { useForm } from "react-hook-form";
// import { course_all_by_branchID } from "../../../api/Course/Course";

// const Attendance = () => {
//   const { register, watch } = useForm();

//   const Branch_id = localStorage.getItem("b_id");
//   const [data, setData] = useState([]);
//   const [checkedIds, setCheckedIds] = useState([]);
//   const [showResults, setShowResults] = useState(false);
//   const [classList, setClassList] = useState([]);
//   const [courseList, setCourseList] = useState([]);

//   const [currentDate, setCurrentDate] = useState(
//     new Date().toISOString().slice(0, 10)
//   );
//   const course_id = watch("course_id");
//   const class_id = watch("class_id");
//   const class_timing = watch("class_timing");
//   const [selectedStudents, setSelectedStudents] = useState([]);

//   const handleCheckboxChange = (id, course_id) => {
//     const uniqueId = `${course_id}_${id}`;
//     setSelectedStudents((prev) => ({
//       ...prev,
//       [uniqueId]: !prev[uniqueId],
//     }));
//   };

//   const handleCheckAll = () => {
//     const allStudentIds = data.map((row) => `${row.course_id}_${row.id}`);
//     const allSelected = allStudentIds.every((id) => selectedStudents[id]);
//     const newSelectedStudents = allSelected
//       ? {}
//       : Object.fromEntries(allStudentIds.map((id) => [id, true]));
//     setSelectedStudents(newSelectedStudents);
//   };

//   const isAllChecked = () => {
//     return data.every((row) => selectedStudents[`${row.course_id}_${row.id}`]);
//   };

//   const columns = [
//     { name: "No.", selector: "no", sortable: true },
//     { name: "Student Name", selector: "name", sortable: true },
//     { name: "Course", selector: "course", sortable: false },
//     { name: "Term", selector: "term", sortable: false },
//     { name: "Class", selector: "class", sortable: false },
//     {
//       name: (
//         <>
//         <input
//           type="checkbox"
//           onChange={handleCheckAll}
//           checked={isAllChecked()}
//           disabled={
//             new Date(currentDate).setHours(0, 0, 0, 0) <
//             new Date().setHours(0, 0, 0, 0)
//           }
//         />
//         <span className="ms-2">Mark Attendance</span>
//         </>
//       ),
//       selector: "attendance",
//       cell: (row) => (
//         <input
//           key={`${row.course_id}_${row.id}`} 
//           type="checkbox"
//           checked={!!selectedStudents[`${row.course_id}_${row.id}`]}
//           onChange={() => handleCheckboxChange(row.id, row.course_id)}
//           disabled={
//             new Date(currentDate).setHours(0, 0, 0, 0) <
//             new Date().setHours(0, 0, 0, 0)
//           }
//         />
//       ),
//     },
//   ];

//   async function getData() {
//     const result = await get_student_attendance(
//       Branch_id || "",
//       currentDate || "",
//       class_id || "",
//       course_id || "",
//       class_timing || ""
//     );
//     const arrayData = result?.data?.map((elem, index) => ({
//       id: elem.id,
//       no: `#${index + 1}`,
//       name: elem.full_name,
//       course: elem.course_name,
//       term: elem.term_name || "-",
//       class: elem.class_name || "-",
//       status: elem.status,
//       course_id: elem.course_id,
//     }));
//     setData(arrayData);
//     const selectedMap = {};
//     arrayData.forEach((student) => {
//       if (student.status === 1) {
//         selectedMap[`${student.course_id}_${student.id}`] = true;
//       }
//     });
//     setSelectedStudents(selectedMap);
//     setShowResults(true);
//   }
  

//   async function fetchLists() {
//     const ClassData = await get_classList_By_branchId(Branch_id);
//     const CourseData = await course_all_by_branchID(Branch_id);
//     setClassList(ClassData?.data);
//     setCourseList(CourseData?.data);
//   }
//   useEffect(() => {
//     fetchLists();
//     getData();
//   }, [currentDate, class_id, course_id, class_timing]);

//   const inputRef = useRef();
//   useEffect(() => {
//     const dtToday = new Date();
//     const maxDate = dtToday.toISOString().slice(0, 10);
//     if (inputRef.current) inputRef.current.max = maxDate;
//   }, []);
//   const getSelectedIds = () => {
//     return Object.keys(selectedStudents)
//       .filter((key) => selectedStudents[key] && key.includes("_"))
//       .map((key) => {
//         const parts = key.split("_");
//         return parts.length === 2 ? parseInt(parts[1]) : null; 
//       })
//       .filter((id) => id !== null && !isNaN(id));
//   };
//   const onSubmit = async () => {
//     const payload = {
//       sids: getSelectedIds(),
//     };
//     const reponse = await mark_student_attendance(currentDate, payload);
//     if (reponse.status) {
//       getData();
//     }
//   };
//   return (
//     <div className="right-nav" id="dash-event">
//       {showResults ? (
//         <>
//           <div className="row">
//             <div className="col-md-4">
//               <h4 className="modal-title">Attendance</h4>
//             </div>
//           </div>
//           <div className="row">
//             <div className="col-md-6">
//               <div className="form-group">
//                 <label>Choose Date</label>
//                 <input
//                   type="date"
//                   className="form-control"
//                   value={currentDate}
//                   onChange={(e) => setCurrentDate(e.target.value)}
//                   ref={inputRef}
//                   max={new Date().toISOString().slice(0, 10)}
//                 />
//               </div>
//             </div>
//             <div className="col-md-6">
//               <div className="form-group">
//                 <label>Class</label>
//                 <select className="form-select" {...register("class_id")}>
//                   <option value="">Choose Class</option>
//                   {classList?.map((elem) => (
//                     <option key={elem.id} value={elem.id}>
//                       {elem.class_name}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             </div>
//           </div>
//           <div className="row">
//             <div className="col-md-6">
//               <div className="form-group">
//                 <label>Course</label>
//                 <select className="form-select" {...register("course_id")}>
//                   <option value="">Choose Course</option>
//                   {courseList?.map((elem) => (
//                     <option key={elem.id} value={elem.id}>
//                       {elem.course_name}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             </div>
//             <div className="col-md-6">
//               <div className="form-group">
//                 <label>Class Timing</label>
//                 <select className="form-select" {...register("class_timing")}>
//                   <option value="morning">Morning</option>
//                   <option value="afternoon">Afternoon</option>
//                 </select>
//               </div>
//             </div>
//           </div>
//           <CustomTable columns={columns} data={data} />
//           {new Date(currentDate).setHours(0, 0, 0, 0) <
//             new Date().setHours(0, 0, 0, 0) ===
//             false && (
//             <div className="modal-footer">
//               <button type="button" className="btn" onClick={() => onSubmit()}>
//                 Submit Attendance
//               </button>
//             </div>
//           )}
//         </>
//       ) : (
//         <>
//           <DataLoader />
//         </>
//       )}
//     </div>
//   );
// };

// export default Attendance;
import React, { useState, useEffect, useRef } from "react";
import DataLoader from "../../../../Utils/DataLoader";
import CustomTable from "../CustomTable";
import {
  get_classList_By_branchId,
  get_student_attendance,
  mark_student_attendance,
} from "../../../api/Attendance";
import { useForm } from "react-hook-form";
import { course_all_by_branchID } from "../../../api/Course/Course";

const Attendance = () => {
  const { register, watch } = useForm();
  const Branch_id = localStorage.getItem("b_id");
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [classList, setClassList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date().toISOString().slice(0, 10));
  const course_id = watch("course_id");
  const class_id = watch("class_id");
  const class_timing = watch("class_timing");
  const [selectedStudents, setSelectedStudents] = useState({});

  const handleCheckboxChange = (id, course_id, class_id) => {
    setSelectedStudents(prev => ({
      ...prev,
      [`${id}_${class_id}`]: !prev[`${id}_${class_id}`]
    }));
  };

  const handleCheckAll = () => {
    const allSelected = data.every(student => 
      selectedStudents[`${student.id}_${student.class_id}`]
    );
    
    const newSelected = {};
    data.forEach(student => {
      newSelected[`${student.id}_${student.class_id}`] = !allSelected;
    });
    setSelectedStudents(newSelected);
  };

  const isAllChecked = () => {
    return data.length > 0 && data.every(student => 
      selectedStudents[`${student.id}_${student.class_id}`]
    );
  };

  const columns = [
    { name: "No.", selector: "no", sortable: true },
    { name: "Student Name", selector: "name", sortable: true },
    { name: "Course", selector: "course", sortable: false },
    { name: "Term", selector: "term", sortable: false },
    { name: "Class", selector: "class", sortable: false },
    {
      name: (
        <>
          <input
            type="checkbox"
            onChange={handleCheckAll}
            checked={isAllChecked()}
            disabled={new Date(currentDate) < new Date().setHours(0, 0, 0, 0)}
          />
          <span className="ms-2">Mark Attendance</span>
        </>
      ),
      selector: "attendance",
      cell: (row) => (
        <input
          type="checkbox"
          checked={!!selectedStudents[`${row.id}_${row.class_id}`]}
          onChange={() => handleCheckboxChange(row.id, row.course_id, row.class_id)}
          disabled={new Date(currentDate) < new Date().setHours(0, 0, 0, 0)}
        />
      ),
    },
  ];

  async function getData() {
    const result = await get_student_attendance(
      Branch_id || "",
      currentDate || "",
      class_id || "",
      course_id || "",
      class_timing || ""
    );
    
    const arrayData = result?.data?.map((elem, index) => ({
      id: elem.id,
      no: `#${index + 1}`,
      name: elem.full_name,
      course: elem.course_name,
      term: elem.term_name || "-",
      class: elem.class_name || "-",
      status: elem.status,
      course_id: elem.course_id,
      class_id: elem.class_id,
    }));
    
    setData(arrayData);
    
    // Initialize selected students based on status
    const initialSelected = {};
    arrayData.forEach(student => {
      initialSelected[`${student.id}_${student.class_id}`] = student.status === 1;
    });
    
    setSelectedStudents(initialSelected);
    setShowResults(true);
  }

  async function fetchLists() {
    const ClassData = await get_classList_By_branchId(Branch_id);
    const CourseData = await course_all_by_branchID(Branch_id);
    setClassList(ClassData?.data);
    setCourseList(CourseData?.data);
  }

  useEffect(() => {
    fetchLists();
    getData();
  }, [currentDate, class_id, course_id, class_timing]);

  const inputRef = useRef();
  useEffect(() => {
    const dtToday = new Date();
    const maxDate = dtToday.toISOString().slice(0, 10);
    if (inputRef.current) inputRef.current.max = maxDate;
  }, []);

  const prepareAttendancePayload = () => {
    return data.map(student => ({
      class_id: student.class_id,
      sid: student.id,
      status: selectedStudents[`${student.id}_${student.class_id}`] ? 1 : 0
    }));
  };

  const onSubmit = async () => {
    const payload = prepareAttendancePayload();
    const response = await mark_student_attendance(
      currentDate, // Date as query parameter
      payload      // Array of objects as request body
    );
    
    if (response.status) {
      getData(); // Refresh data after submission
    }
  };

  return (
    <div className="right-nav" id="dash-event">
      {showResults ? (
        <>
          <div className="row">
            <div className="col-md-4">
              <h4 className="modal-title">Attendance</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Choose Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={currentDate}
                  onChange={(e) => setCurrentDate(e.target.value)}
                  ref={inputRef}
                  max={new Date().toISOString().slice(0, 10)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Class</label>
                <select className="form-select" {...register("class_id")}>
                  <option value="">Choose Class</option>
                  {classList?.map((elem) => (
                    <option key={elem.id} value={elem.id}>
                      {elem.class_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Course</label>
                <select className="form-select" {...register("course_id")}>
                  <option value="">Choose Course</option>
                  {courseList?.map((elem) => (
                    <option key={elem.id} value={elem.id}>
                      {elem.course_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Class Timing</label>
                <select className="form-select" {...register("class_timing")}>
                  <option value="morning">Morning</option>
                  <option value="afternoon">Afternoon</option>
                </select>
              </div>
            </div>
          </div>
          <CustomTable columns={columns} data={data} />
          {new Date(currentDate) < new Date().setHours(0, 0, 0, 0) === false && (
            <div className="modal-footer">
              <button type="button" className="btn" onClick={onSubmit}>
                Submit Attendance
              </button>
            </div>
          )}
        </>
      ) : (
        <DataLoader />
      )}
    </div>
  );
};

export default Attendance;