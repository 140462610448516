import { toast } from "react-toastify";
import { HTTPURL } from "../constants/Matcher";



export async function get_student_list_attendance(id) {
    try {
        const response = await fetch(HTTPURL + `api/get-student-class-attendance-branch/?id=${id}`);
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function get_student_attendance_date(date) {
    try {
        const response = await fetch(HTTPURL + `api/teacher/get-attendance/?date=${date}`);
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}



// google class add 
export async function submit_attendance(data)
{
    try {
        const response = await fetch(HTTPURL + 'api/teacher/add-attendance', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function get_classList_By_branchId(id) {
    try {
        const response = await fetch(HTTPURL + `api/branches/branch-classes-list?branch_id=${id}`);
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}