import { toast } from "react-toastify";
import { HTTPURL } from "../../constants/Matcher";
export async function get_active_class_grades() {
  try {
    const response = await fetch(HTTPURL + "api/branches/get-class-grades");
    const responseData = await response.json();
    if (responseData.status) {
      // toast.success(responseData?.message);
    } else {
      toast.error(responseData?.message);
    }
    return responseData;
  } catch (error) {
    console.log(error.message);
    return error.message;
  }
}

export async function get_active_class_terms(id,state) {
  try {
    const response = await fetch(
      HTTPURL + `api/branches/get-class-term?year=${id}&state=${state}`
    );
    const responseData = await response.json();
    if (responseData.status) {
      // toast.success(responseData?.message);
    } else {
      toast.error(responseData?.message);
    }
    return responseData;
  } catch (error) {
    console.log(error.message);
    return error.message;
  }
}

export async function get_class_courses(grade_id, term_id, year_id) {
  try {
    const response = await fetch(
      HTTPURL +
      `api/branches/get-class-courses?grade_id=${grade_id}&term_id=${term_id}&year=${year_id}`
    );
    const responseData = await response.json();
    if (responseData.status) {
      // toast.success(responseData?.message);
    } else {
      toast.error(responseData?.message);
    }
    return responseData;
  } catch (error) {
    console.log(error.message);
    return error.message;
  }
}

//add  class
export async function class_add(data) {
  try {
    const response = await fetch(HTTPURL + "api/branches/class-add", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (responseData.status) {
      toast.success(responseData?.message);
    } else {
      toast.error(responseData?.message);
    }
    return responseData;
  } catch (error) {
    console.log(error.message);
    return error.message;
  }
}

// get all subjects
export async function class_all(status,id) {
  try {
    const response = await fetch(
      HTTPURL + `api/branches/class-all?delete_status=${status}&branch_id=${id}`
    );
    const responseData = await response.json();
    if (responseData.status) {
      // toast.success(responseData?.message);
    } else {
      toast.error(responseData?.message);
    }
    return responseData;
  } catch (error) {
    console.log(error.message);
    return error.message;
  }
}

export async function class_destroy(data, id) {
  try {
    const response = await fetch(
      HTTPURL + `api/branches/class-distroy?id=${id}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }
    );
    const responseData = await response.json();
    if (responseData.status) {
      toast.success(responseData?.message);
    } else {
      toast.error(responseData?.message);
    }
    return responseData;
  } catch (error) {
    console.log(error.message);
    return error.message;
  }
}

// class edit
export async function class_edit(id) {
  try {
    const response = await fetch(HTTPURL + `api/branches/class-edit/?id=${id}`);
    const responseData = await response.json();
    if (responseData.status) {
      // toast.success(responseData?.message);
    } else {
      toast.error(responseData?.message);
    }
    return responseData;
  } catch (error) {
    console.log(error.message);
    return error.message;
  }
}

// subject update
export async function class_update(data, id) {
  try {
    const response = await fetch(
      HTTPURL + `api/branches/class-update/?id=${id}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }
    );
    const responseData = await response.json();
    if (responseData.status) {
      toast.success(responseData?.message);
    } else {
      toast.error(responseData?.message);
    }
    return responseData;
  } catch (error) {
    console.log(error.message);
    return error.message;
  }
}

export async function student_all(id,course_id) {
  try {
    const response = await fetch(
      HTTPURL + `api/branches/get-student-list?id=${id}&course_id=${course_id}`
    );
    const responseData = await response.json();
    if (responseData.status) {
      // toast.success(responseData?.message);
    } else {
      toast.error(responseData?.message);
    }
    return responseData;
  } catch (error) {
    console.log(error.message);
    return error.message;
  }
}





export const studentList = async (id,term_id,course_id) => {
  try {
    const result = await student_all(id,course_id);
    // Assuming result is an array of objects with properties value and label
    const transformedOptions = result?.data?.map((item) => ({
      value: item?.id,
      label: item.first_name,
    }));
    console.log("transformedOptions", transformedOptions);

    return transformedOptions;
  } catch (error) {
    console.error("Error fetching data:", error);
    return error;
  }
};

export async function active_couse_evaluation(id, status) {
  try {
    const response = await fetch(
      HTTPURL +
      `api/branches/test-activation?id=${id}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(status),
      }
    );
    const responseData = await response.json();
    if (responseData.status) {
      toast.success(responseData?.message);
    } else {
      toast.error(responseData?.message);
    }
    return responseData;
  } catch (error) {
    console.log(error.message);
    return error.message;
  }
}


export async function couse_evaluation_list(class_id, branch_id) {
  try {
    const response = await fetch(
      HTTPURL + `api/branches/summative-test-list?branch_id=${branch_id}&class_id=${class_id}`
    );
    const responseData = await response.json();
    if (responseData.status) {
      // toast.success(responseData?.message);
    } else {
      toast.error(responseData?.message);
    }
    return responseData;
  } catch (error) {
    console.log(error.message);
    return error.message;
  }
}


export async function student_destroy(id) {
  try {
    const response = await fetch(
      HTTPURL + `api/branches/class-delete-student?user_id=${id}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      }
    );
    const responseData = await response.json();
    if (responseData.status) {
      toast.success(responseData?.message);
    } else {
      toast.error(responseData?.message);
    }
    return responseData;
  } catch (error) {
    console.log(error.message);
    return error.message;
  }
}