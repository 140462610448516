import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataLoader from "../../../../Utils/DataLoader";
import { class_all, class_destroy, student_destroy } from "../../../api/Class";
import CustomTableWithChild from "../../../../componrnt/pages/CustomTableWithChild";
import { getUserPermission } from "../../../../Utils/Utils";
import { ActiveEvaluation } from "./ActiveEvaluation";

const AllClasses = () => {
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [status, setStatus] = useState("");
  const [getData, setGetData] = useState(false);
  const [id, setId] = useState({
    class_id: "",
    branch_id: "",
  });
  const branchid = localStorage.getItem("b_id");
  const permission = getUserPermission("ClassManagement");
  useEffect(() => {
    async function get_data() {
      const result = await class_all(status,branchid);
      const arrayData = result?.data?.map((elem, index) => {
        const {
          id,
          class_name,
          year,
          branch_name,
          grade_name,
          delete_status,
          grade_id,
          term_id,
          students,
          branch_id,
          course_id
        } = elem;
        return {
          no: `#${index + 1}`,
          name: class_name,
          year: year,
          branch_name: branch_name,
          grade_name: `year ${grade_name}`,
          terms: students,
          status_data : status,
          status: delete_status == 0 ? "Inactive" : "Active",
          action: (
            <div data-tag="allowRowEvents">
              {permission?.add === 1 ? (
                <Link
                  class="edit-btn"
                  to={`/branch/class-management/assigne-student/${grade_id}/${term_id}/${id}/${course_id}`}
                >
                  <i class="fa fa-user-plus" title="Assign the student"></i>
                </Link>
              ) : (
                ""
              )}
              {permission?.edit === 1 ? (
                <Link
                  class="edit-btn"
                  to={`/branch/class-management/edit/${id}`}
                >
                  <i class="fa fa-pencil" aria-hidden="true" style={{cursor: "pointer" }}
                    title="Edit"></i>
                </Link>
              ) : (
                ""
              )}
              {permission?.delete === 1 ? (
                <button
                  className="dlt-btn"
                  onClick={() =>
                    destroy(id, delete_status === 1 ? 0 : 1, class_name)
                  }
                >
                  {delete_status === 0 ? (
                    <i class="fa-solid fa-xmark"></i>
                  ) : (
                    <i class="fa fa-trash" aria-hidden="true"  style={{cursor: "pointer" }}
                    title="Active/Inactive Class"></i>
                  )}
                </button>
              ) : (
                ""
              )}
              <button
                className="dlt-btn"
                onClick={() => {
                  setIsModalOpen(true);
                  setId({ class_id: id, branch_id: branch_id });
                }}
              >
                <i
                  class="fa-solid fa-file"
                  style={{ color: "blue", cursor: "pointer" }}
                  title="Evaluation"
                ></i>
              </button>
            </div>
          ),
        };
      });
      setGetData(false)
      setData(arrayData, setShowResults(true));
    }
    const destroy = async (id, status, name) => {
      const data = {
        delete_status: status,
        title: name,
        deleted_by: branchid,
      };
      const result = window.confirm(
        `${status === 1
          ? "Are you sure you want to active this class ?"
          : "Are you sure you want to inactive  this class ? "
        }`
      );
      if (result) {
        await class_destroy(data, id);
      }
      get_data();
    };

    get_data();
  }, [status, getData]);
  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: true,
    },
    {
      name: "Class Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Year",
      selector: "year",
      sortable: true,
    },
    {
      name: "Branch Name",
      selector: "branch_name",
      sortable: true,
    },
    {
      name: "Grade Name",
      selector: "grade_name",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    permission?.edit === 1 || permission?.delete === 1 || permission?.view === 1
      ? {
        name: "Action",
        selector: "action",
        sortable: true,
      }
      : "",
  ];
  let childColumns = [
    {
      name: "S.No",
      selector: (_, index) => index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => `${row.first_name} ${row.last_name}`,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.mobile,
      sortable: true,
    },
    {
      name: "Grade",
      selector: (row) => `Year ${row.grade_name}`,
      sortable: true,
    },

    permission?.edit === 1
      ? {
        name: "Action",
        name: "Action",
        cell: (row) => {
          return (
            <div data-tag="allowRowEvents">
              <Link
                class="edit-btn"
                to={`/branch/class-management/assigne-student-edit/${row?.id}/${row.class_id}`}
              >
                <i class="fa fa-pencil" aria-hidden="true"></i>
              </Link>
              <button
                className="dlt-btn"
                onClick={() =>
                  destroy_student(row?.user_id)
                }
              >
                <i class="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          );
        },

        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      }
      : "",
  ];
  const destroy_student = async (id) => {
    const result = window.confirm(
      "Are you sure you want to delete this student ?"
    );
    if (result) {
      await student_destroy(id);
      setGetData(true)
    }
  };
  return (
    <div className="right-nav" id="dash-event">
      {showResults ? (
        <>
          <div className="row">
            <div className="col-md-4">
              <h4 className="modal-title">Class List</h4>
            </div>
            <div className="col-md-8">
              {permission?.add === 1 ? (
                <Link
                  className="ui button float-end"
                  to="/branch/class-management/add"
                >
                  Add Class
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label>Status</label>
              <select
                className="form-select form-control "
                name="branch"
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">Select status</option>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
          </div>
          {/* <CustomTable columns={columns} data={data} /> */}
          <CustomTableWithChild
            columns={columns}
            data={data}
            childColumns={childColumns}
            type="class"
          />
        </>
      ) : (
        <DataLoader />
      )}
      <ActiveEvaluation
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        id={id}
      />
    </div>
  );
};
export default AllClasses;
