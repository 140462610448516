import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

const StudentAttendanceCalendar = ({ attendance }) => {
  const [events, setEvents] = useState([]);
  const localizer = momentLocalizer(moment);

  useEffect(() => {
    if (attendance?.length) {
      const formattedEvents = attendance.map((entry) => ({
        id: entry.date,
        title: "Present",
        start: new Date(entry.date),
        end: new Date(entry.date),
        allDay: true,
      }));
      setEvents(formattedEvents);
    }
  }, [attendance]);

  const eventStyleGetter = (event) => {
    const style = {
      backgroundColor: "green",
      color: "white",
      borderRadius: "5px",
      border: "none",
      padding: "5px",
      height: "51px",
    };
    return { style };
  };

  return (
    <div className="calendar-view">
      <h2>Attendance Calendar</h2>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "75vh", margin: "20px" }}
        eventPropGetter={eventStyleGetter}
      />
    </div>
  );
};

export default StudentAttendanceCalendar;
