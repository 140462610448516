import { toast } from "react-toastify";
import { HTTPURL } from '../../constants/Matcher';





// student_enroll_list
export async function student_enroll_list(branch_id) {
    try {
        const response = await fetch(HTTPURL + `api/branches/branch-enrolled-student?id=${branch_id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// student_enroll_list
export async function free_student_list(branch_id) {
    try {
        const response = await fetch(HTTPURL + `api/branches/branch-free-student?id=${branch_id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// student_enroll_list
export async function student_enroll_view(id) {
    try {
        const response = await fetch(HTTPURL + `api/branches/endrolled-student-details?id=${id?.id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// add teacher
export async function enroll_student_payment(data) {
    try {
        const response = await fetch(HTTPURL + 'api/branches/branch-update-to-enrolled', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


export async function get_course_by_term_id(grade_id,id,user_id) {
    try {
        const response = await fetch(HTTPURL + `api/branches/available-courses?term_id=${id}&grade_id=${grade_id}&user_id=${user_id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            // toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export const Course_List_for_multiple_select = async (grade_id,term_id,user_id) => {
    try {
        const result = await get_course_by_term_id(grade_id,term_id,user_id);
        // Assuming result is an array of objects with properties value and label
        const transformedOptions = result?.data?.map(item => ({ value: item?.course_id, label: item.course_name, amount: item.price }));
        return transformedOptions;
    } catch (error) {
        console.error('Error fetching data:', error);
        return error;
    }
}

export async function get_student_enroll_courses(user_id) {
    try {
        const response = await fetch(HTTPURL + `api/branches/course-lists?user_id=${user_id}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


export const student_enroll_courses_data = async (user_id) => {
    try {
        const result = await get_student_enroll_courses(user_id);
        // Assuming result is an array of objects with properties value and label
        const transformedOptions = result?.data?.map(item => ({ value: item?.course_id, label: item.course_name }));
        return transformedOptions;
    } catch (error) {
        console.error('Error fetching data:', error);
        return error;
    }
}


// add teacher
export async function update_student_enroll_course(data, user_id) {
    try {
        const response = await fetch(HTTPURL + `api/branches/remove-student-course?user_id=${user_id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
         toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function get_payment_detail(user_id,term_id) {
    try {
        const response = await fetch(HTTPURL + `api/branches/preselected-details?user_id=${user_id}&term_id=${term_id}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            // toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all discout
export async function discout_all_branch() {
    try {
        const response = await fetch(HTTPURL + 'api/discount-all');
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;  
    }
}