import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataLoader from "../../../Utils/DataLoader";
import { grade_destroy, grades_all_data } from "../../../apis/Grade";
import EditGrade from "./Edit";
import CustomTable from "../CustomTable";
import { getUserPermission } from "../../../Utils/Utils";

const AllGrade = () => {
  const [grade, setGrade] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [status, setStatus] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [duplicateItemId, setDuplicateItemId] = useState(null);
  const permissions = getUserPermission("Grades");
  const UserID = JSON.parse(localStorage.getItem("userData"))?.data?.id;
  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: false,
    },
    {
      name: "Grade",
      selector: "name",
      sortable: false,
    },
    {
      name: "Status",
      selector: "status",
      sortable: false,
    },
    permissions?.edit === 1 || permissions?.delete === 1
      ? {
        name: "Action",
        selector: "action",
        sortable: false,
      }
      : "",
  ];
  useEffect(() => {
    async function get_data() {
      const result = await grades_all_data();
      const arrayData = result?.data?.map((elem, index) => {
        const { id, name, status } = elem;
        return {
          no: `#${index + 1}`,
          name: name == 0 ? `Year ${name}(kindy)` : `Year ${name}`,
          //name:name,
          status_data : status,
          status: (
            <select
              className="form-select"
              onChange={(e) => destroy(id, e.target.value, name)}
              value={status}
            >
              <option value={1}>Active</option>
              <option value={0}>Inactive</option>
            </select>
          ),
          action: (
            <div data-tag="allowRowEvents">
              {permissions?.edit === 1 ? (
                <button
                  onClick={() => {
                    setDuplicateItemId(id);
                    setIsModalOpen(true);
                    setStatus("grade");
                  }}
                  className="dlt-btn"
                >
                  {" "}
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </button>
              ) : (
                ""
              )}
            </div>
          ),
        };
      });
      setGrade(arrayData, setShowResults(true));
    }
    const destroy = async (id, status, name) => {
      const data = { status: status };
      const result = window.confirm(
        `${status == 1
          ? "Are you sure you want to active  this grade ?"
          : "Are you sure you want to  inactive  this grade ? "
        }`
      );
      if (result) {
        await grade_destroy(id, data);
      }
      get_data();
    };
    get_data();
  }, [isModalOpen]);
  return (
    <>
      <div className="modal-content">
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="right-nav" id="dash-event">
              {showResults ? (
                <>
                  <div className="row">
                    <div className="col-md-4">
                      <h4 className="modal-title">Grade List</h4>
                    </div>
                    <div className="col-md-8">
                      {permissions?.add === 1 ? (
                        <Link
                          className="ui button float-end"
                          to="/admin/general-setting/grade/add"
                        >
                          Add Grades
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <CustomTable columns={columns} data={grade} />
                </>
              ) : (
                <DataLoader />
              )}
            </div>
          </div>
        </div>
      </div>
      <EditGrade
        duplicateItemId={duplicateItemId}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        status={status}
        setStatus={setStatus}
      />
    </>
  );
};
export default AllGrade;
