import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataLoader from "../../../../Utils/DataLoader";
import { sub_users_list, destroy_user } from "../../../api/user";
import { getUserPermission } from "../../../../Utils/Utils";
import CustomTable from "../../../../componrnt/pages/CustomTable.jsx";

const UserList = () => {
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const branchId = localStorage.getItem("b_id");
  const permission = getUserPermission("Course");
  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: true,
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
    },
    {
      name: "Mobile No",
      selector: "number",
      sortable: true,
    },
    {
      name: "Branch Name",
      selector: "Branch",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    permission?.edit === 1 || permission?.delete === 1
      ? {
          name: "Action",
          selector: "action",
        }
      : "",
  ];
  useEffect(() => {
    async function get_data() {
      const result = await sub_users_list(branchId);
      const arrayData = result?.data?.map((elem, index) => {
        const {
          id,
          first_name,
          last_name,
          email,
          mobile,
          payment,
          branch,
          c_name,
          status,
          branch_name,
        } = elem;
        if (result?.data.length > 0 && selectedStatus === null) {
          setSelectedStatus(status);
        }
        return {
          no: `#${index + 1}`,
          name: `${first_name} ${last_name}`,
          email: email,
          number: mobile ? mobile : "-",
          Branch: branch_name ? branch_name : "-",
          status_data : status,
          status: ` ${status == 1 ? "Active" : "Inactive"}`,
          permission: `payemnt:${payment == 1 ? "Yes" : "NO"},
                                 branch:${branch == 1 ? "Yes" : "NO"},
                                 Class:${c_name == 1 ? "Yes" : "NO"}`,
          action: (
            <div data-tag="allowRowEvents">
              {permission?.edit === 1 ? (
                <Link class="edit-btn" to={`/branch/settings/users/edit/${id}`}>
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </Link>
              ) : (
                ""
              )}
              {permission?.delete === 1 ? (
                <button
                  className="dlt-btn"
                  onClick={() => destroy(id, status == "1" ? 0 : 1, first_name)}
                >
                  {" "}
                  {status == 0 ? (
                    <i class="fa-solid fa-xmark"></i>
                  ) : (
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  )}
                </button>
              ) : (
                ""
              )}
            </div>
          ),
        };
      });
      setData(arrayData, setShowResults(true));
    }
    const destroy = async (id, status, name) => {
      const data = { delete_status: status, title: name, deleted_by: branchId };
      const result = window.confirm(
        `${
          status === 1
            ? "Are you sure you want to active  this user ?"
            : "Are you sure you want to  inactive  this user ? "
        }`
      );
      if (result) {
        await destroy_user(id, data);
      }
      get_data();
    };
    get_data();
  }, []);
  return (
    <div className="right-nav" id="dash-event">
      {showResults ? (
        <>
      <div className="row">
        <div className="col-md-4">
          <h4 className="modal-title">Users List</h4>
        </div>
        <div className="col-md-8">
          {permission?.add === 1 ? (
            <Link className="ui button float-end" to="/branch/settings/users/add">
              Add User
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
      <CustomTable  columns={columns} data={data}  search={true}/>     
        </>
      ) : (
        <DataLoader />
      )}
    </div>
  );
};
export default UserList;
