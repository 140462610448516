import { toast } from "react-toastify";
import { HTTPURL } from "../constants/Matcher";


// get branch student
export async function get_branch_student() {
    try {
        const response = await fetch(HTTPURL + 'api/get-branch-student');
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


//top 5 event list
export async function get_event_list() {
    try {
        const response = await fetch(HTTPURL + 'api/top-five-event');
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}